import React from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import BasicCard from '../../../components/BasicCard/BasicCard';

function CancelledSum({ purchaseStore }) {
  const { cancelledOrdersSum } = purchaseStore;

  return (
    <div
      style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 30 }}
    >
      <BasicCard
        label="Anulowane zamówienia w wybranym zakresie dat"
        value={cancelledOrdersSum}
        useFormatter={false}
      />
    </div>
  );
}

export default inject('purchaseStore')(observer(CancelledSum));

CancelledSum.propTypes = {
  purchaseStore: PropTypes.shape({
    cancelledOrdersSum: PropTypes.number,
  }),
};
