import React from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import TableWithJson from '../../../components/TableWithJson/TableWithJson';
import { currencyFormatter } from '../../../components/helpers/utils';
import {
  showLoaderOrPlaceHolder,
  Subtitle,
} from '../../../components/helpers/chartHelpers';

const columns = [
  { id: 'position', label: 'Pozycja', minWidth: 100 },
  { id: 'category', label: 'Kategoria', minWidth: 100 },
  {
    id: 'value',
    label: 'Wartość',
    minWidth: 100,
    format: currencyFormatter.format,
  },
];

function TopCategoriesByMaskTable({ productsStore }) {
  const {
    loadingTopCategoriesByMask,
    topCategoriesByMask,
    chosenCategoriesMasks,
  } = productsStore;

  const categories = topCategoriesByMask.filter(
    (mask) => chosenCategoriesMasks.indexOf(mask._id) >= 0,
  );

  return categories.map(({ _id, categories }, index) => (
    <div key={index}>
      <Subtitle>{_id.toUpperCase()}</Subtitle>
      {categories && categories.length === 0 ? (
        showLoaderOrPlaceHolder(loadingTopCategoriesByMask, false)
      ) : (
        <TableWithJson
          columns={columns}
          data={categories}
          loadingData={loadingTopCategoriesByMask}
          withPagination={false}
          showJson={false}
        />
      )}
    </div>
  ));
}

export default inject('productsStore')(observer(TopCategoriesByMaskTable));

TopCategoriesByMaskTable.propTypes = {
  productsStore: PropTypes.shape({
    topCategories: PropTypes.array,
    loadingTopCategories: PropTypes.bool,
  }),
};
