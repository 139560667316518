import { action, observable } from 'mobx';
import qs from 'qs';
import config from '../config';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import { stringify } from 'query-string';
import moment from 'moment';
import 'moment/locale/pl';
import { funnelColors } from '../components/helpers/chartHelpers';

export class AggregationsStore {
  @observable activePushPercent = 0;
  @observable vouchersPercent = 0;

  @observable purchaseBarChartData = [];
  @observable loadingPurchaseBarChartData = true;

  @observable bannerBarChartData = [];
  @observable loadingBannerBarChartData = true;

  @observable funnelChartData = [];
  @observable loadingFunnelChartData = true;

  @observable bannersEvents = [];
  @observable bannersEventsCount = 0;
  @observable loadingBannersEventsData = true;

  @observable stores = [];
  @observable countries = [];
  @observable loadingStores = true;
  @observable loadingCountries = true;
  @observable storesCount = 0;

  constructor() {}

  @action
  fetchBannerBarChartData = async (timeFrom, timeTo, step) => {
    this.loadingBannerBarChartData = true;
    const { data } = await this.fetchStatistics({
      timeFrom,
      timeTo,
      step,
      statId: config.statIds.bannerCount,
    });
    this.bannerBarChartData = data.map((stat) => {
      const date = moment(stat.fromTs);
      stat.fromTs = date.locale('pl').format('lll').toString();
      return stat;
    });
    this.loadingBannerBarChartData = false;
  };

  fetchFunnelChartData = async (timeFrom, timeTo) => {
    this.loadingFunnelChartData = true;
    const step = '1D';

    const {
      data: { sum: usersCount },
    } = await this.fetchStatisticsSum(
      timeFrom,
      timeTo,
      step,
      config.statIds.usersCount,
    );
    // const { data: { sum: activePushCount } } = await this.fetchStatisticsSum(timeFrom, timeTo, step, config.statIds.activePushCount);
    const {
      data: { sum: wishListsCount },
    } = await this.fetchStatisticsSum(
      timeFrom,
      timeTo,
      step,
      config.statIds.wishlistSum,
    );
    const {
      data: { sum: purchasesCount },
    } = await this.fetchStatisticsSum(
      timeFrom,
      timeTo,
      step,
      config.statIds.purchasesCount,
    );

    if (usersCount === 0 && wishListsCount === 0 && purchasesCount === 0) {
      this.funnelChartData = [];
    } else {
      this.funnelChartData = [
        {
          label: 'aktywni użytkownicy',
          value: usersCount || 0,
          backgroundColor: funnelColors[0],
        },
        // { label: 'aktywne aplikacje', value: activePushCount || 0, backgroundColor: funnelColors[1] },
        {
          label: 'dodanie do ulubionych',
          value: wishListsCount || 0,
          backgroundColor: funnelColors[2],
        },
        {
          label: 'zakupy',
          value: purchasesCount || 0,
          backgroundColor: funnelColors[3],
        },
      ];
    }

    this.loadingFunnelChartData = false;
  };

  @action fetchStatistics = async ({
    timeFrom,
    timeTo,
    step,
    statId,
    medium,
    store,
    paymentType,
    paymentProvider,
    banner,
    recommendationOrigin,
    productBundleType,
  }) => {
    const query = {
      statId,
      timeFrom,
      timeTo,
      step,
      banner,
    };

    if (medium) {
      query.medium = medium;
    }

    if (store) {
      query.store = store;
    }

    if (paymentType) {
      query.payment_type = paymentType;
    }

    if (paymentProvider) {
      query.payment_provider = paymentProvider;
    }

    if (recommendationOrigin) {
      query.recommendation_origin = recommendationOrigin;
    }

    if (productBundleType) {
      query.product_bundles_product_bundle_type = productBundleType;
    }

    return await API.get(`${API_ROUTES.STATISTICS}?${qs.stringify(query)}`);
  };

  @action fetchStatisticsSum = async (
    timeFrom,
    timeTo,
    step = '1D',
    statId,
    key = null,
    params = null,
    sumKey = null,
  ) => {
    const query = {
      statId,
      timeFrom,
      timeTo,
      step,
      key,
      params,
      sumKey,
    };

    return await API.get(`${API_ROUTES.STATISTICS_SUM}?${qs.stringify(query)}`);
  };

  @action fetchStatisticsAvg = async ({
    timeFrom,
    timeTo,
    step = '1D',
    statId,
    key = null,
  }) => {
    const query = {
      statId,
      timeFrom,
      timeTo,
      step,
      key,
    };

    return await API.get(`${API_ROUTES.STATISTICS_AVG}?${qs.stringify(query)}`);
  };

  @action fetchBannersEvents = async (query) => {
    this.bannersEvents = [];
    this.loadingBannersEventsData = true;
    query.action = 'pageview';
    query.onlyBanner = true;
    const { events, eventsCount } = await this.fetchEvents(query);
    this.bannersEvents = events;
    this.bannersEventsCount = eventsCount;
    this.loadingBannersEventsData = false;
  };

  @action fetchEvents = async ({
    limit,
    page,
    from,
    to,
    email,
    medium,
    action,
    voucherCode,
    onlyBanner,
    totalPricePlnGte,
    totalPricePlnLte,
    sort,
    couponPool,
    codeSource,
    cashbackCode,
  }) => {
    const query = {
      limit: parseInt(limit ? limit.toString() : 10, 10),
      page: parseInt(page ? page.toString() : 0, 10),
      from: from.toISOString(),
      to: to.toISOString(),
      email,
      medium,
      action,
      voucherCode,
      onlyBanner,
      totalPricePlnGte,
      totalPricePlnLte,
      sort,
      couponPool,
      codeSource,
      cashbackCode,
    };
    Object.keys(query).forEach(
      (key) => query[key] === undefined && delete query[key],
    );

    try {
      const response = await API.get(
        `${API_ROUTES.EVENTS}?${stringify(query)}`,
      );
      return {
        events: response.data.events,
        eventsCount: response.data.eventsCount,
      };
    } catch (e) {
      console.log(e);
    }
  };

  @action fetchStores = async ({ country, isActive, limit, page }) => {
    this.stores = [];
    this.loadingStores = true;

    const query = {
      limit: parseInt(limit ? limit.toString() : 10, 10),
      page: parseInt(page ? page.toString() : 0, 10),
      country,
      isActive,
    };

    Object.keys(query).forEach(
      (key) => query[key] === undefined && delete query[key],
    );

    try {
      const { data } = await API.get(
        `${API_ROUTES.STORES}?${stringify(query)}`,
      );
      this.stores = data.stores;
      this.storesCount = data.storesCount;
    } catch (e) {
      console.log(e);
    }
    this.loadingStores = false;
  };

  @action fetchCountries = async () => {
    this.countries = [];
    this.loadingCountries = true;

    try {
      const { data } = await API.get(API_ROUTES.COUNTRIES);
      this.countries = data.map((country) => ({
        label: country,
        value: country,
      }));
    } catch (e) {
      console.log(e);
    }
    this.loadingCountries = false;
  };
}

export default new AggregationsStore();
