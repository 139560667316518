import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import moment from 'moment';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import TableWithJson from 'components/TableWithJson/TableWithJson';
import { Filters, FlexRow } from '../styles';

const inboxOpenedColumns = [
  { id: '_id', label: 'Sklep', minWidth: 40 },
  { id: 'sum', label: 'Ilość', minWidth: 40 },
];

function InboxOpenedTable({
  notificationsStore: {
    fetchStatsPerStore,
    inboxOpenedData,
    isLoadingInboxOpened,
  },
}) {
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());

  useEffect(() => {
    fetchStatsPerStore({ from: startDate, to: endDate, type: 'inbox_opened' });
  }, [startDate, endDate]);

  return (
    <>
      <Filters className="no-horizontal-margin">
        <FlexRow width="unset">
          <DateRangePicker
            startDate={startDate}
            onStartDateChange={(date) => setStartDate(date)}
            endDate={endDate}
            onEndDateChange={(date) => setEndDate(date)}
            containerClassName="small"
          />
        </FlexRow>
      </Filters>
      <TableWithJson
        columns={inboxOpenedColumns}
        data={inboxOpenedData}
        loadingData={isLoadingInboxOpened}
        withPagination={false}
        showJson={false}
      />
    </>
  );
}

export default inject('notificationsStore')(observer(InboxOpenedTable));

InboxOpenedTable.propTypes = {
  notificationsStore: PropTypes.shape({
    fetchStatsPerStore: PropTypes.func,
    inboxOpenedData: PropTypes.array,
    isLoadingInboxOpened: PropTypes.bool,
  }),
};
