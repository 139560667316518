import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import CustomLineChart from '../../../components/CustomLineChart/CustomLineChart';
import moment from 'moment';
import MaskSelectDateRange from '../../SalesPage/Components/MaskSelectDateRange';

const colors = ['#272727', '#868686'];
const keys = ['displayed', 'allSelected'];
const keysTranslation = [
  'ilość wyświetleń ekranu zgód',
  'ilość użytkowników którzy zaznaczyli wszystkie zgody',
];
const xAxis = 'fromTs';

const ConsentsChart = ({ usersStore }) => {
  const {
    isLoadingConsentsData,
    consentsData,
    fetchConsentsChartData,
    fetchMasksForConsents,
    isLoadingMasksForConsents,
    availableMasksForConsents,
  } = usersStore;
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [chosenMask, setChosenMask] = useState('Wszystkie');
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (startDate && endDate && isMounted) {
      fetchConsentsChartData(startDate, endDate, chosenMask);
      fetchMasksForConsents({ from: startDate, to: endDate });
    }
  }, [startDate, endDate, chosenMask, isMounted]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <CustomLineChart
        loadingData={isLoadingConsentsData}
        data={consentsData}
        xAxisKey={xAxis}
        dataKeys={keys}
        keysTranslation={keysTranslation}
        keysColors={colors}
      />
      <div style={{ width: '50%', margin: 'auto' }}>
        <MaskSelectDateRange
          chosenMask={chosenMask}
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          masks={availableMasksForConsents}
          loadingMasks={isLoadingMasksForConsents}
          setMask={setChosenMask}
        />
      </div>
    </>
  );
};
export default inject('usersStore')(observer(ConsentsChart));

ConsentsChart.propTypes = {
  usersStore: PropTypes.shape({
    isLoadingConsentsData: PropTypes.bool,
    consentsData: PropTypes.array,
    fetchConsentsChartData: PropTypes.func,
    fetchMasksForConsents: PropTypes.func,
    isLoadingMasksForConsents: PropTypes.bool,
    availableMasksForConsents: PropTypes.array,
  }),
};
