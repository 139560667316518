import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import TableWithJson from '../../components/TableWithJson/TableWithJson';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import { FlexRowWithMargin, DatePickerContainer } from '../BannersPage/styles';
import Select from 'react-select';
import moment from 'moment';
import { currencyFormatter } from '../../components/helpers/utils';
import styled from 'styled-components';

export const SelectContainerWithLabel = styled.div`
  margin-left: 28px;
  display: flex;
  flex-direction: row;
`;

export const SelectLabel = styled.div`
  padding-top: 6px;
`;

export const SelectContainer = styled.div`
  width: 300px;
  margin-left: 10px;
`;

const columns = [
  { id: 'position', label: 'Pozycja' },
  { id: 'orig_id', label: 'ID' },
  { id: 'name', label: 'Nazwa' },
  { id: 'type', label: 'Typ' },
  { id: 'store', label: 'Maska', format: (value) => value.join(', ') },
  {
    id: 'skus',
    label: 'SKUs',
    format: (value) => value.join(', '),
  },
  {
    id: 'created_at',
    label: 'Data utworzenia',
    format: (value) => moment(value).format('llll'),
  },
  { id: 'amount', label: 'Ilość kliknięć' },
  {
    id: 'value',
    label: 'Wartość',
    format: currencyFormatter.format,
  },
];

const sortOptions = [
  { value: 'amount', label: 'Ilość kliknięć' },
  { value: 'value', label: 'Wartość' },
];

function TopProductBundles({
  recommendationsStore: {
    fetchProductBundlesTop,
    productBundlesTop,
    isLoadingProductBundlesTop,
  },
}) {
  const [startDate, setStartDate] = useState(
    moment().subtract(30, 'days').startOf('day').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());
  const [sortBy, setSortBy] = useState(sortOptions[0]);

  useEffect(() => {
    fetchProductBundlesTop({
      timeFrom: startDate,
      timeTo: endDate,
      sortBy: sortBy.value,
    });
  }, [startDate, endDate, sortBy]);

  return (
    <>
      <FlexRowWithMargin>
        <DatePickerContainer
          style={{ justifyContent: 'space-between', margin: 1 }}
        >
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            onStartDateChange={setStartDate}
            showTime={false}
          />
        </DatePickerContainer>
        <SelectContainerWithLabel>
          <SelectLabel>Sortuj po:</SelectLabel>
          <SelectContainer>
            <Select
              id="recommendations-sales-select"
              isClearable
              placeholder={`Sortuj po`}
              onChange={(option) => setSortBy(option)}
              menuPlacement="top"
              value={sortBy}
              options={sortOptions}
            />
          </SelectContainer>
        </SelectContainerWithLabel>
      </FlexRowWithMargin>
      <TableWithJson
        columns={columns}
        data={productBundlesTop}
        loadingData={isLoadingProductBundlesTop}
        withPagination={false}
        showJson={false}
      />
    </>
  );
}

export default inject('recommendationsStore')(observer(TopProductBundles));

TopProductBundles.propTypes = {
  recommendationsStore: PropTypes.shape({
    fetchProductBundlesTop: PropTypes.func,
    productBundlesTop: PropTypes.array,
    isLoadingProductBundlesTop: PropTypes.bool,
  }),
};
