import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import * as PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText';

export const InfoDialog = ({ open, onClose, content }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        style={{ color: 'white', backgroundColor: '#212121' }}
        id="alert-dialog-title"
      >
        Dodatkowe informacje
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

InfoDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  content: PropTypes.any,
};
