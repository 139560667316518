import { action, observable } from 'mobx';
import config from '../config';
import moment from 'moment';
import 'moment/locale/pl';
import aggregationsStore from './aggregationsStore';

export class DiscountStore {
  @observable discountsHourly = [];
  @observable loadingDiscountsHourlyData = true;

  @observable discountsPerMask = [];
  @observable loadingDiscountsPerMask = true;

  @observable discountsVsPurchasePerMask = [];
  @observable loadingDiscountsVsPurchasePerMask = true;

  constructor() {}

  @action fetchDiscountsHourly = async () => {
    this.discountsHourly = [];
    this.loadingDiscountsHourlyData = true;

    const todayQuery = {
      statId: config.statIds.discountsSum,
      step: '1H',
      timeFrom: moment().startOf('day').toISOString(),
      timeTo: moment().toISOString(),
    };

    const yesterdayQuery = {
      statId: config.statIds.discountsSum,
      step: '1H',
      timeFrom: moment().subtract(1, 'days').startOf('day').toISOString(),
      timeTo: moment().startOf('day').toISOString(),
    };

    const weekQuery = {
      statId: config.statIds.discountsSum,
      step: '1H',
      timeFrom: moment().subtract(7, 'days').startOf('day').toISOString(),
      timeTo: moment().subtract(6, 'days').startOf('day').toISOString(),
    };

    let { data: today } = await aggregationsStore.fetchStatistics(todayQuery);
    let { data: yesterday } = await aggregationsStore.fetchStatistics(
      yesterdayQuery,
    );
    let { data: week } = await aggregationsStore.fetchStatistics(weekQuery);

    let todayIndex = 0;
    let yesterdayIndex = 0;
    let weekIndex = 0;
    const result = [];
    for (let hour = 0; hour < 24; hour++) {
      const hourObject = { hour };
      if (week[weekIndex] && hour === moment(week[weekIndex].fromTs).hour()) {
        hourObject.week = week[weekIndex].value
          ? Math.round(Math.abs(parseFloat(week[weekIndex].value)) * 100) / 100
          : 0;
        weekIndex += 1;
      } else {
        hourObject.week = 0;
      }
      if (
        today[todayIndex] &&
        hour === moment(today[todayIndex].fromTs).hour()
      ) {
        hourObject.today = today[todayIndex].value
          ? Math.round(Math.abs(parseFloat(today[todayIndex].value)) * 100) /
            100
          : 0;
        todayIndex += 1;
      } else {
        hourObject.today = 0;
      }
      if (
        yesterday[yesterdayIndex] &&
        hour === moment(yesterday[yesterdayIndex].fromTs).hour()
      ) {
        hourObject.yesterday = yesterday[yesterdayIndex].value
          ? Math.round(
              Math.abs(parseFloat(yesterday[yesterdayIndex].value)) * 100,
            ) / 100
          : 0;
        yesterdayIndex += 1;
      } else {
        hourObject.yesterday = 0;
      }
      result.push(hourObject);
    }

    this.discountsHourly = result;
    this.loadingDiscountsHourlyData = false;
  };

  @action fetchDiscountsPerMask = async () => {
    this.discountsPerMask = [];
    this.loadingDiscountsPerMask = true;

    const todayQuery = {
      statId: config.statIds.discountsSumPerMask,
      step: '1H',
      timeFrom: moment().startOf('day').toISOString(),
      timeTo: moment().toISOString(),
      key: '$store',
    };

    const monthQuery = {
      statId: config.statIds.discountsSumPerMask,
      step: '1D',
      timeFrom: moment().startOf('month').toISOString(),
      timeTo: moment().toISOString(),
      key: '$store',
    };

    let {
      data: { sum: day },
    } = await aggregationsStore.fetchStatisticsSum(
      todayQuery.timeFrom,
      todayQuery.timeTo,
      todayQuery.step,
      todayQuery.statId,
      todayQuery.key,
    );
    const {
      data: { sum: month },
    } = await aggregationsStore.fetchStatisticsSum(
      monthQuery.timeFrom,
      monthQuery.timeTo,
      monthQuery.step,
      monthQuery.statId,
      monthQuery.key,
    );

    const dayMasks = day.map(({ _id }) => _id);
    const monthMasks = month.map(({ _id }) => _id);
    const masks = [...new Set([...dayMasks, ...monthMasks])];

    const masksSorted = masks.sort();
    let dayAll = 0;
    let monthAll = 0;

    day.forEach((stat) => (dayAll += stat.sum));
    month.forEach((stat) => (monthAll += stat.sum));

    dayAll = Math.abs(dayAll);
    monthAll = Math.abs(monthAll);

    let dayIndex = 0;
    let monthIndex = 0;
    const result = [];

    for (let i = 0; i < masksSorted.length; i++) {
      const row = { mask: masksSorted[i] };

      if (day[dayIndex] && masksSorted[i] === day[dayIndex]._id) {
        row.today = day[dayIndex].sum ? Math.abs(day[dayIndex].sum) : 0;
        row.todayShare = dayAll > 0 ? row.today / dayAll : 0;
        dayIndex += 1;
      } else {
        row.today = 0;
        row.todayShare = 0;
      }
      if (month[monthIndex] && masksSorted[i] === month[monthIndex]._id) {
        row.month = month[monthIndex].sum ? Math.abs(month[monthIndex].sum) : 0;
        row.monthShare = monthAll > 0 ? row.month / monthAll : 0;
        monthIndex += 1;
      } else {
        row.month = 0;
        row.monthShare = 0;
      }
      result.push(row);
    }

    this.discountsPerMask = result;
    this.loadingDiscountsPerMask = false;
  };

  @action fetchDiscountsVsPurchasesPerMask = async (fromDate, toDate) => {
    this.loadingDiscountsVsPurchasePerMask = true;
    this.discountsVsPurchasePerMask = [];

    const monthQueryPurchaseStat = config.statIds.purchasesTotalPriceSumPerMask;
    const monthQueryPurchaseCountStat = config.statIds.purchasesCountPerMask;
    const monthQueryDiscountsStat = config.statIds.discountsSumPerMask;

    const monthQuery = {
      step: '1D',
      timeFrom: moment().startOf('month').toISOString(),
      timeTo: moment().toISOString(),
      key: '$store',
    };

    const previousMonthQuery = {
      step: '1D',
      timeFrom: moment().startOf('month').subtract(1, 'month').toISOString(),
      timeTo: moment().subtract(1, 'month').toISOString(),
      key: '$store',
    };

    if (fromDate && toDate) {
      monthQuery.timeFrom = moment(fromDate).toString();
      monthQuery.timeTo = moment(toDate).toString();
      previousMonthQuery.timeFrom = moment(fromDate)
        .subtract(1, 'month')
        .toString();
      previousMonthQuery.timeTo = moment(toDate)
        .subtract(1, 'month')
        .toString();
    }

    const {
      data: { sum: monthPurchase },
    } = await aggregationsStore.fetchStatisticsSum(
      monthQuery.timeFrom,
      monthQuery.timeTo,
      monthQuery.step,
      monthQueryPurchaseStat,
      monthQuery.key,
    );
    const {
      data: { sum: monthDiscounts },
    } = await aggregationsStore.fetchStatisticsSum(
      monthQuery.timeFrom,
      monthQuery.timeTo,
      monthQuery.step,
      monthQueryDiscountsStat,
      monthQuery.key,
    );
    const {
      data: { sum: previousMonthDiscounts },
    } = await aggregationsStore.fetchStatisticsSum(
      previousMonthQuery.timeFrom,
      previousMonthQuery.timeTo,
      previousMonthQuery.step,
      monthQueryDiscountsStat,
      previousMonthQuery.key,
    );
    const {
      data: { sum: monthPurchaseCount },
    } = await aggregationsStore.fetchStatisticsSum(
      monthQuery.timeFrom,
      monthQuery.timeTo,
      monthQuery.step,
      monthQueryPurchaseCountStat,
      monthQuery.key,
    );

    const masks = monthPurchase.map(({ _id }) => _id);

    const monthPurchaseObject = this.arrayToObject(monthPurchase, '_id', 'sum');
    const monthDiscountsObject = this.arrayToObject(
      monthDiscounts,
      '_id',
      'sum',
    );
    const previousMonthDiscountsObject = this.arrayToObject(
      previousMonthDiscounts,
      '_id',
      'sum',
    );
    const monthPurchaseCountObject = this.arrayToObject(
      monthPurchaseCount,
      '_id',
      'sum',
    );

    const result = [];
    for (let i = 0; i < masks.length; i++) {
      const monthPurchaseValue = monthPurchaseObject[masks[i]]
        ? monthPurchaseObject[masks[i]]
        : 0;
      const monthDiscountsValue = monthDiscountsObject[masks[i]]
        ? monthDiscountsObject[masks[i]]
        : 0;
      const previousMonthDiscountsValue = previousMonthDiscountsObject[masks[i]]
        ? previousMonthDiscountsObject[masks[i]]
        : 0;
      const monthPurchaseCountValue = monthPurchaseCountObject[masks[i]]
        ? monthPurchaseCountObject[masks[i]]
        : 0;
      const discountsPercent =
        monthPurchaseValue > 0 && Math.abs(monthDiscountsValue) > 0
          ? Math.abs(monthDiscountsValue) / monthPurchaseValue
          : 0;
      result.push({
        mask: masks[i],
        monthPurchaseValue,
        monthDiscountsValue,
        previousMonthDiscountsValue,
        monthPurchaseCountValue,
        discountsPercent,
      });
    }

    this.loadingDiscountsVsPurchasePerMask = false;
    this.discountsVsPurchasePerMask = result;
  };

  arrayToObject(array, keyName, valueName) {
    const object = {};
    array.forEach((item) => {
      object[item[keyName]] = item[valueName];
    });
    return object;
  }
}

export default new DiscountStore();
