import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import TableWithJson from '../../../components/TableWithJson/TableWithJson';
import { percentFormatter } from '../../../components/helpers/utils';
import { showLoaderOrPlaceHolder } from '../../../components/helpers/chartHelpers';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import moment from 'moment';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import Select from 'react-select';
import { BannerButton } from '../../BannersPage/styles';

const HalfContainer = styled(Flex)`
  width: 50%;
  justify-content: flex-start;
  line-height: 36px;
`;

const DatePickerContainer = styled(Flex)`
  margin-bottom: 20px;
  flex-direction: row;
`;

function TopSearchPhrasesTable({
  productsStore: {
    loadingTopSearchPhrases,
    topSearchPhrases,
    fetchTopSearchPhrases,
    loadingAvailableSearchPhrasesMasks,
    availableMasksSearchPhrases,
    fetchMasksForSearchPhrases,
    setPhraseForNextStepData,
    setStartDateNextStep,
    setEndDateNextStep,
  },
}) {
  const [startDate, setStartDate] = useState(moment().startOf('week').toDate());
  const [endDate, setEndDate] = useState(
    moment().endOf('week').add(1, 'day').startOf('day').toDate(),
  );
  const [mask, setMask] = useState(null);

  const [previousStartDate, setPreviousStartDate] = useState(null);
  const [previousEndDate, setPreviousEndDate] = useState(null);

  useEffect(() => {
    fetchMasksForSearchPhrases({
      previousTimeFrom: previousStartDate,
      timeTo: endDate,
    });
  }, [previousStartDate, endDate]);

  useEffect(() => {
    const currentDateDiff = moment(endDate).diff(startDate, 'days');
    const newPreviousStartDate = moment(startDate)
      .startOf('day')
      .subtract(currentDateDiff, 'days');
    const newPreviousEndDate = moment(endDate).subtract(
      currentDateDiff,
      'days',
    );

    setPreviousStartDate(newPreviousStartDate);
    setPreviousEndDate(newPreviousEndDate);

    fetchTopSearchPhrases({
      timeFrom: startDate,
      timeTo: endDate,
      previousTimeFrom: newPreviousStartDate,
      previousTimeTo: newPreviousEndDate,
      mask,
    });
  }, [startDate, endDate]);

  useEffect(() => {
    fetchTopSearchPhrases({
      timeFrom: startDate,
      timeTo: endDate,
      previousTimeFrom: previousStartDate,
      previousTimeTo: previousEndDate,
      mask,
    });
  }, [mask]);

  if (loadingTopSearchPhrases) {
    return showLoaderOrPlaceHolder(loadingTopSearchPhrases, false);
  }

  const columns = columnsFunc({
    setPhraseForNextStepData,
    startDate,
    endDate,
    setStartDateNextStep,
    setEndDateNextStep,
  });

  return (
    <div>
      <DatePickerContainer>
        <HalfContainer>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            onStartDateChange={setStartDate}
            showTime={false}
          />
        </HalfContainer>
        {previousStartDate && previousEndDate ? (
          <HalfContainer>
            ( Poprzedni okres: {previousStartDate.format('DD/MM/YYYY')}
            {' - '}
            {previousEndDate.format('DD/MM/YYYY')} )
          </HalfContainer>
        ) : null}
        <div style={{ width: '30%' }}>
          <Select
            isLoading={loadingAvailableSearchPhrasesMasks}
            placeholder="Wybierz maski..."
            getOptionLabel={(option) => option}
            getOptionValue={(option) => option}
            value={availableMasksSearchPhrases.filter(
              (maskVal) => maskVal === mask,
            )}
            options={availableMasksSearchPhrases}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            onChange={(option) => {
              setMask(option);
            }}
          />
        </div>
      </DatePickerContainer>
      <TableWithJson
        columns={columns}
        data={topSearchPhrases}
        loadingData={loadingTopSearchPhrases}
        withPagination={false}
        showJson={false}
      />
    </div>
  );
}

const columnsFunc = ({
  setPhraseForNextStepData,
  startDate,
  endDate,
  setStartDateNextStep,
  setEndDateNextStep,
}) => [
  {
    id: '_id',
    label: 'Fraza',
    minWidth: 100,
    format: (value) => (
      <BannerButton
        onClick={() => {
          setPhraseForNextStepData(value);
          setStartDateNextStep(startDate);
          setEndDateNextStep(endDate);
          document
            .getElementById('next-step-table')
            .scrollIntoView({ behavior: 'smooth' });
        }}
      >
        {value}
      </BannerButton>
    ),
  },
  { id: 'value', label: 'Wyszukania', minWidth: 100 },
  { id: 'shadowVal', label: 'W poprzednim okresie', minWidth: 100 },
  { id: 'absoluteDelta', label: 'Zmiana absolutna', minWidth: 100 },
  {
    id: 'percentDelta',
    label: 'Zmiana procentowa',
    minWidth: 100,
    format: (value) => (value !== '-' ? percentFormatter.format(value) : value),
  },
];

export default inject('productsStore')(observer(TopSearchPhrasesTable));

TopSearchPhrasesTable.propTypes = {
  productsStore: PropTypes.shape({
    fetchTopSearchPhrases: PropTypes.func,
    topSearchPhrases: PropTypes.array,
    loadingTopSearchPhrases: PropTypes.bool,
    loadingAvailableSearchPhrasesMasks: PropTypes.bool,
    availableMasksSearchPhrases: PropTypes.array,
    fetchMasksForSearchPhrases: PropTypes.func,
    setPhraseForNextStepData: PropTypes.func,
    setStartDateNextStep: PropTypes.func,
    setEndDateNextStep: PropTypes.func,
  }),
};
