import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import CustomLineChart from '../../../components/CustomLineChart/CustomLineChart';
import moment from 'moment';
import MaskSelectDateRange from '../../SalesPage/Components/MaskSelectDateRange';
import { percentFormatter } from '../../../components/helpers/utils';

const colors = ['#272727', '#868686'];
const keys = ['percent', 'percentRegistered'];
const keysTranslation = ['konwersja', 'konwersja zalogowanych'];
const xAxis = 'fromTs';

const tooltipString = (value, purchases, users) =>
  `${percentFormatter.format(
    value,
  )} (${purchases} transakcji dla ${users} użytkowników)`;

const valueFormatter = (value, name, props) =>
  props.name === 'konwersja'
    ? tooltipString(value, props.payload.purchases, props.payload.users)
    : tooltipString(
        value,
        props.payload.purchasesRegistered,
        props.payload.usersRegistered,
      );

const ConversionLineChart = ({ purchaseStore }) => {
  const {
    isLoadingConversion,
    conversion,
    fetchConversionPerMask,
    fetchMasksForConversion,
    isLoadingMasksForConversion,
    availableMasksForConversion,
  } = purchaseStore;
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [chosenMask, setChosenMask] = useState('four_fstore_pl');
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (startDate && endDate && isMounted) {
      fetchConversionPerMask(startDate, endDate, chosenMask);
      fetchMasksForConversion(startDate, endDate);
    }
  }, [startDate, endDate, chosenMask, isMounted]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <CustomLineChart
        loadingData={isLoadingConversion}
        data={conversion}
        xAxisKey={xAxis}
        dataKeys={keys}
        keysTranslation={keysTranslation}
        keysColors={colors}
        valueFormatter={valueFormatter}
      />
      <div style={{ width: '50%', margin: 'auto' }}>
        <MaskSelectDateRange
          chosenMask={chosenMask}
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          masks={availableMasksForConversion}
          loadingMasks={isLoadingMasksForConversion}
          setMask={setChosenMask}
        />
      </div>
    </>
  );
};
export default inject('purchaseStore')(observer(ConversionLineChart));

ConversionLineChart.propTypes = {
  purchaseStore: PropTypes.shape({
    isLoadingConversion: PropTypes.bool,
    conversion: PropTypes.array,
    fetchConversionPerMask: PropTypes.func,
    fetchMasksForConversion: PropTypes.func,
    isLoadingMasksForConversion: PropTypes.bool,
    availableMasksForConversion: PropTypes.array,
  }),
  payload: PropTypes.any,
};
