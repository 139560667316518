import React from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import TableWithJson from '../../../components/TableWithJson/TableWithJson';
import { Filters } from '../../../components/helpers/chartHelpers';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';

function TopWishlistProductsPerMask({ wishlistStore }) {
  const {
    topProductsPerMask,
    loadingTopProductsPerMask,
    columnsPerMask,
    startDate,
    endDate,
    setEndDate,
    setStartDate,
  } = wishlistStore;

  return (
    <>
      <div style={{ width: '50%', margin: 'auto' }}>
        <Filters>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            onStartDateChange={setStartDate}
            showTime={false}
          />
        </Filters>
      </div>
      <TableWithJson
        columns={columnsPerMask}
        data={topProductsPerMask}
        loadingData={loadingTopProductsPerMask}
        withPagination={false}
        showJson={false}
      />
    </>
  );
}

export default inject('wishlistStore')(observer(TopWishlistProductsPerMask));

TopWishlistProductsPerMask.propTypes = {
  wishlistStore: PropTypes.shape({
    topProductsPerMask: PropTypes.array,
    columnsPerMask: PropTypes.array,
    loadingTopProductsPerMask: PropTypes.bool,
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    setStartDate: PropTypes.func,
    setEndDate: PropTypes.func,
  }),
};
