import React from 'react';
import PropTypes from 'prop-types';
import { percentFormatter } from '../../../components/helpers/utils';

const PercentTooltip = (props) => {
  let payload = null;
  let payloadSum = 0;
  if (props.payload) {
    payload = props.payload.filter((record) => record.value !== 0);
    if (payload && payload.length > 0) {
      payloadSum = payload[0].payload.total;
    }
  }
  return (
    <div
      style={{
        margin: '0px',
        padding: '10px',
        backgroundColor: 'white',
        border: '1px solid rgb(204, 204, 204)',
        whiteSpace: 'nowrap',
      }}
    >
      <ul style={{ margin: 0, padding: 0 }}>
        <li style={{ listStyleType: 'none', marginBottom: '2px' }}>
          {props.label}
        </li>
        {payload &&
          payload.map((record, index) => (
            <li
              key={index}
              style={{
                color: record.color ? record.color : record.fill,
                textDecoration: 'none',
                listStyleType: 'none',
                marginBottom: '2px',
              }}
            >
              {record.name}
              {props.separator}
              {record.value}
              {payloadSum > 0
                ? ` (${percentFormatter.format(record.value / payloadSum)})`
                : '-'}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default PercentTooltip;

PercentTooltip.propTypes = {
  payload: PropTypes.array,
  label: PropTypes.string,
  separator: PropTypes.string,
};
