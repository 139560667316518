import { ResponsiveContainer } from 'recharts';
import {
  BaseOption,
  steps,
  customDropdownStyles,
  FilterBox,
  Filters,
  showBarChart,
  showLoaderOrPlaceHolder,
  StyledInput,
} from '../../../components/helpers/chartHelpers';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Select from 'react-select';
import { inject, observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import PolishDatePicker from '../../../components/PolishDatePicker/PolishDatePicker';
import { Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import TooltipUI from '@material-ui/core/Tooltip';

const UsersBarChart = inject('usersStore')(
  observer(({ usersStore, medium, infoTip }) => {
    const [startDate, setStartDate] = useState(
      moment().subtract(1, 'day').startOf('day').toDate(),
    );
    const [endDate, setEndDate] = useState(moment().endOf('day').toDate());
    const [step, setStep] = useState('5m');
    const {
      usersBarChartData,
      fetchUsersBarChartData,
      loadingUsersBarChartData,
      fetchUsersByMediumBarChartData,
      usersByMediumBarChartDataMobile,
      loadingUsersByMediumBarChartDataMobile,
    } = usersStore;

    useEffect(() => {
      if (medium) {
        fetchUsersByMediumBarChartData(
          startDate.toISOString(),
          endDate.toISOString(),
          step,
          medium.value,
          true,
        );
      } else {
        fetchUsersBarChartData(
          startDate.toISOString(),
          endDate.toISOString(),
          step,
        );
      }
    }, [startDate, endDate, step, medium]);

    const chart = (chartData, loading) => {
      return loading
        ? showLoaderOrPlaceHolder(loading)
        : showBarChart(chartData, 'value', 'fromTs', true, null, 'użytkownicy');
    };

    return (
      <>
        {medium && (
          <Typography color="textSecondary" align="center" gutterBottom>
            {medium.label}
          </Typography>
        )}
        <ResponsiveContainer width="100%" height={300}>
          {medium
            ? chart(
                usersByMediumBarChartDataMobile[medium.value],
                loadingUsersByMediumBarChartDataMobile[medium.value],
              )
            : chart(usersBarChartData, loadingUsersBarChartData)}
        </ResponsiveContainer>
        <Filters>
          <FilterBox>
            Od:
            <PolishDatePicker
              showTimeSelect
              dateFormat="dd/MM/yyyy hh:mm"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<StyledInput />}
              maxDate={endDate}
            />
          </FilterBox>
          <FilterBox>
            Do:
            <PolishDatePicker
              showTimeSelect
              dateFormat="dd/MM/yyyy h:mm aa"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              customInput={<StyledInput />}
              minDate={startDate}
            />
          </FilterBox>
          <FilterBox>
            Przedział:
            <Select
              options={steps}
              value={steps.filter((option) => option.value === step)}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              styles={customDropdownStyles}
              components={{ Option: BaseOption }}
              onChange={(option) => setStep(option.value)}
            />
            <TooltipUI
              style={{ paddingTop: 3, marginLeft: 20 }}
              title={infoTip}
            >
              <InfoIcon htmlColor="#212121" />
            </TooltipUI>
          </FilterBox>
        </Filters>
      </>
    );
  }),
);

export default UsersBarChart;

UsersBarChart.propTypes = {
  medium: PropTypes.any,
  from: PropTypes.any,
  to: PropTypes.any,
};
