import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import TableWithJson from '../../components/TableWithJson/TableWithJson';
import { SelectContainer } from './styles';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import { FlexRowWithMargin, DatePickerContainer } from '../BannersPage/styles';
import Select from 'react-select';
import moment from 'moment';

const columns = [
  { id: 'position', label: 'Pozycja', minWidth: 100 },
  { id: '_id', label: 'SKU', minWidth: 100 },
  { id: 'productName', label: 'Nazwa', minWidth: 100 },
  { id: 'value', label: 'Ilość kliknięć', minWidth: 100 },
];

function RecommendationsTopProducts({
  recommendationsStore: {
    fetchProductsTop,
    recommendationsTopProducts,
    isLoadingRecommendationsTopProducts,
    recommendationOrigins,
  },
}) {
  const [startDate, setStartDate] = useState(
    moment().subtract(30, 'days').startOf('day').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());
  const [chosenRecommendationOrigin, setChosenRecommendationOrigin] =
    useState(null);

  useEffect(() => {
    fetchProductsTop({
      timeFrom: startDate,
      timeTo: endDate,
      recommendationOrigin: chosenRecommendationOrigin,
    });
  }, [startDate, endDate, chosenRecommendationOrigin]);

  return (
    <>
      <FlexRowWithMargin>
        <DatePickerContainer
          style={{ justifyContent: 'space-between', margin: 1 }}
        >
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            onStartDateChange={setStartDate}
            showTime={false}
          />
        </DatePickerContainer>
        <SelectContainer>
          <Select
            id="recommendations-sales-select"
            isClearable
            placeholder={`Pochodzenie rekomendacji`}
            onChange={(option) => setChosenRecommendationOrigin(option)}
            menuPlacement="top"
            value={chosenRecommendationOrigin}
            options={recommendationOrigins}
          />
        </SelectContainer>
      </FlexRowWithMargin>
      <TableWithJson
        columns={columns}
        data={recommendationsTopProducts}
        loadingData={isLoadingRecommendationsTopProducts}
        withPagination={false}
        showJson={false}
      />
    </>
  );
}

export default inject('recommendationsStore')(
  observer(RecommendationsTopProducts),
);

RecommendationsTopProducts.propTypes = {
  recommendationsStore: PropTypes.shape({
    fetchProductsTop: PropTypes.func,
    recommendationsTopProducts: PropTypes.array,
    isLoadingRecommendationsTopProducts: PropTypes.bool,
    recommendationOrigins: PropTypes.array,
  }),
};
