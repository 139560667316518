import React, { useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import TableWithJson from '../../../components/TableWithJson/TableWithJson';
import { Filters } from '../../../components/helpers/chartHelpers';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import Select from 'react-select';
import styled from 'styled-components';

const columns = [
  { id: 'position', label: 'Pozycja', minWidth: 100 },
  { id: 'value', label: 'Ilość', minWidth: 100 },
  { id: '_id', label: 'SKU', minWidth: 100 },
  { id: 'name', label: 'Nazwa produktu', minWidth: 100 },
  { id: 'regularPrice', label: 'Cena regularna', minWidth: 100 },
  { id: 'currency', label: 'Waluta', minWidth: 100 },
];

const SelectContainer = styled.div`
  width: 300px;

  &.left-margin {
    margin-left: 20px;
  }
`;

function TopWishlistProductsGlobalTable({ wishlistStore }) {
  const {
    globalTopWishlist,
    loadingGlobalTopWishlist,
    startDate,
    endDate,
    setEndDate,
    setStartDate,
    fetchAll,
    availableMasks,
    fetchTopProductsForMask,
    topProductsForMask,
    loadingTopProductsForMask,
  } = wishlistStore;

  const [mask, setMask] = React.useState(null);

  useEffect(async () => {
    await fetchAll(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    if (mask) {
      fetchTopProductsForMask(startDate, endDate, mask.value);
    }
  }, [startDate, endDate, mask]);

  const masks = useMemo(() => {
    return availableMasks.map((mask) => ({
      value: mask,
      label: mask,
    }));
  }, [availableMasks]);

  return (
    <>
      <div style={{ width: '50%', margin: 'auto' }}>
        <Filters>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            onStartDateChange={setStartDate}
            showTime={false}
          />
          <SelectContainer className="left-margin">
            <Select
              isClearable
              isLoading={loadingGlobalTopWishlist}
              placeholder="Wybierz maskę..."
              value={mask}
              options={masks}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              onChange={setMask}
            />
          </SelectContainer>
        </Filters>
      </div>
      <TableWithJson
        columns={columns}
        data={mask ? topProductsForMask : globalTopWishlist}
        loadingData={
          mask ? loadingTopProductsForMask : loadingGlobalTopWishlist
        }
        withPagination={false}
        showJson={false}
      />
    </>
  );
}

export default inject('wishlistStore')(
  observer(TopWishlistProductsGlobalTable),
);

TopWishlistProductsGlobalTable.propTypes = {
  wishlistStore: PropTypes.shape({
    globalTopWishlist: PropTypes.array,
    loadingGlobalTopWishlist: PropTypes.bool,
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    setStartDate: PropTypes.func,
    setEndDate: PropTypes.func,
    fetchAll: PropTypes.func,
    availableMasks: PropTypes.array,
    fetchTopProductsForMask: PropTypes.func,
    topProductsForMask: PropTypes.array,
    loadingTopProductsForMask: PropTypes.bool,
  }),
};
