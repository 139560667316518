import moment from 'moment';
import Select from 'react-select';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import PieChart from './PieChart';
import BarChart from './BarChart';
import {
  Filters,
  showLoaderOrPlaceHolder,
} from '../../../../components/helpers/chartHelpers';
import { FilterBox } from '../../../../components/helpers/chartHelpers';
import DateRangePicker from '../../../../components/DateRangePicker/DateRangePicker';

const charts = [
  { label: 'Sposób płatności', collectionName: 'paymentType' },
  { label: 'Dostawca płatności', collectionName: 'paymentProvider' },
];

export const COLORS = ['#00425A', '#1F8A70', '#BFDB38', '#FC7300'];

const CHART_TYPES = [
  {
    label: 'Kołowy',
    value: 'pie',
  },
  {
    label: 'Słupkowy',
    value: 'bar',
  },
];

const PaymentCharts = ({
  personaStore: {
    paymentsChartData,
    fetchPaymentsChartData,
    loadingPaymentsChartData,
  },
  shortcutFrom,
  shortcutTo,
}) => {
  const [chartType, setChartType] = useState(CHART_TYPES[0].value);
  const [startDate, setStartDate] = useState(
    moment().subtract(30, 'day').startOf('day').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());

  useEffect(() => {
    fetchPaymentsChartData(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    if (shortcutFrom) {
      setStartDate(shortcutFrom);
    }
    if (shortcutTo) {
      setEndDate(shortcutTo);
    }
  }, [shortcutFrom, shortcutTo]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: chartType === CHART_TYPES[0].value ? 'row' : 'column',
          justifyContent: 'space-around',
        }}
      >
        {charts.map((chart, index) => {
          if (
            loadingPaymentsChartData ||
            paymentsChartData[chart.collectionName].length === 0
          ) {
            return (
              <div
                key={`payment-chart-${index}-loader`}
                style={{ minWidth: '28vw', textAlign: 'center' }}
              >
                {showLoaderOrPlaceHolder(loadingPaymentsChartData)}
              </div>
            );
          }
          return chartType === CHART_TYPES[0].value ? (
            <PieChart
              key={`pie-chart-${index}`}
              index={index}
              chart={chart}
              paymentsChartData={paymentsChartData}
            />
          ) : (
            <BarChart
              key={`bar-chart-${index}`}
              index={index}
              chart={chart}
              paymentsChartData={paymentsChartData}
              hideXLabels={'paymentType' === chart.collectionName}
            />
          );
        })}
      </div>
      <div style={{ width: '50%', margin: 'auto' }}>
        <Filters>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            onStartDateChange={setStartDate}
            showTime={false}
          />

          <FilterBox>
            Wykres:
            <div style={{ width: '120px', marginLeft: '10px' }}>
              <Select
                options={CHART_TYPES}
                defaultValue={CHART_TYPES[0]}
                onChange={(option) => setChartType(option.value)}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option}
              />
            </div>
          </FilterBox>
        </Filters>
      </div>
    </div>
  );
};

export default inject('personaStore')(observer(PaymentCharts));

PaymentCharts.propTypes = {
  personaStore: PropTypes.shape({
    paymentsChartData: PropTypes.any,
    fetchPaymentsChartData: PropTypes.any,
    loadingPaymentsChartData: PropTypes.bool,
  }),
  shortcutFrom: PropTypes.any,
  shortcutTo: PropTypes.any,
};
