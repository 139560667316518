import styled from 'styled-components';
import { Flex } from '@rebass/grid';

export const FlexRowWithMargin = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  margin-bottom: 20px;
`;

export const DatePickerContainer = styled(Flex)`
  margin: 1px;
  justify-content: space-between;
`;

export const BannerButton = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;

export const SelectContainer = styled.div`
  width: ${(props) => props.width || '200'}px;
  margin-bottom: ${(props) => props.margin || '20'}px;
`;

export const Filters = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
  width: max-content;
`;

export const ShadowDatesContainer = styled.div`
  padding-top: 5px;
  width: 400px;
`;

export const DatePickerAdditionalLabel = styled.div`
  padding-top: 7px;
`;
