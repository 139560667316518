import React from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    marginLeft: 10,
    maxHeight: 180,
    maxWidth: 500,
    backgroundColor: '#212121',
  },
  title: {
    fontSize: 14,
    color: 'white',
    fontWeight: 500,
  },
  secondTitle: {
    marginTop: 10,
    fontSize: 14,
    color: 'white',
    fontWeight: 500,
  },
  typo: {
    textAlign: 'right',
    marginLeft: 10,
    fontSize: 14,
    color: 'white',
    fontWeight: 500,
  },
});

function UsersLineChartSumCard({ usersStore }) {
  const { usersLineChartSumRegistered, usersLineChartSumAll } = usersStore;

  const classes = useStyles();
  return (
    <div
      style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 30 }}
    >
      <Card className={classes.root}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Suma użytkowników (unikalnych/dzień)
          </Typography>
          <Typography className={classes.typo} color="textSecondary">
            {usersLineChartSumAll} (wszyscy)
            <br />
            {usersLineChartSumRegistered} (zalogowani)
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

export default inject('usersStore')(observer(UsersLineChartSumCard));

UsersLineChartSumCard.propTypes = {
  usersStore: PropTypes.shape({
    usersLineChartSumRegistered: PropTypes.number,
    usersLineChartSumAll: PropTypes.number,
  }),
};
