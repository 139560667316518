import { action, observable, computed } from 'mobx';
import { firebaseAuth, session } from '../_app/firebase';
import history from '../_app/history';
import moment from 'moment';
import { APP_ROUTES } from '_app/routes';
import Cookies from 'js-cookie';

const TOKEN_KEY = '4f_api_token';
const EMAIL_KEY = '4f_api_email';
const REFRESH_TOKEN_INTERVAL_MINUTES = 45;

const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;

export class AuthStore {
  @observable user = null;
  @observable authError = '';
  @observable isLoggingOut = false;
  @observable isLoggingIn = false;
  @observable lastRefreshTokenTime = moment();

  constructor() {
    firebaseAuth.auth().onAuthStateChanged((user) => {
      this.user = user;
      if (user === null) {
        Cookies.remove(TOKEN_KEY, {
          domain: cookieDomain,
        });
        Cookies.remove(EMAIL_KEY, {
          domain: cookieDomain,
        });
        history.push(APP_ROUTES.LOGIN);
      }
    });
  }

  @action.bound login = async (email, password) => {
    this.isLoggingIn = true;
    firebaseAuth
      .auth()
      .setPersistence(session)
      .then(function () {
        return firebaseAuth.auth().signInWithEmailAndPassword(email, password);
      })
      .catch((error) => (this.authError = error.message));
    firebaseAuth.auth().onAuthStateChanged((user) => {
      this.user = user;
      if (this.user) {
        this.authError = '';
        Cookies.set(EMAIL_KEY, this.user.email, {
          domain: cookieDomain,
          expires: 1,
        });
        this.user.getIdToken(true).then((token) => {
          this.lastRefreshTokenTime = moment();
          Cookies.set(TOKEN_KEY, token, {
            domain: cookieDomain,
            expires: 1,
          });
          this.isLoggingIn = false;
          history.push('/');
        });
      } else {
        this.isLoggingIn = false;
        this.user = null;
      }
    });
  };

  @action.bound
  async logout() {
    this.isLoggingOut = true;
    await firebaseAuth.auth().signOut();

    firebaseAuth.auth().onAuthStateChanged(async (user) => {
      if (user === null) {
        Cookies.remove(TOKEN_KEY, {
          domain: cookieDomain,
        });
        Cookies.remove(EMAIL_KEY, {
          domain: cookieDomain,
        });
        this.user = user;
        this.isLoggingOut = false;
        history.push(APP_ROUTES.LOGIN);
      }
    });
  }

  @action.bound
  async refreshToken() {
    if (
      moment().diff(this.lastRefreshTokenTime, 'minutes') >=
      REFRESH_TOKEN_INTERVAL_MINUTES
    ) {
      let isRefreshed = false;
      await this.user
        .getIdToken(true)
        .then(function (idToken) {
          if (idToken) {
            isRefreshed = true;
            Cookies.set(TOKEN_KEY, idToken, {
              domain: cookieDomain,
              expires: 1,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      if (isRefreshed) {
        this.lastRefreshTokenTime = moment();
      }
    }
  }

  @computed get isAuthenticated() {
    return (
      (this.user || Cookies.get(TOKEN_KEY)) &&
      !this.isLoggingOut &&
      !this.isLoggingIn
    );
  }

  getToken = async () => {
    await this.refreshToken();
    return Cookies.get(TOKEN_KEY);
  };
}

export default new AuthStore();
