import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Bar,
  BarChart as BarChrt,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { COLORS } from '.';

function BarChart({ chart, index, paymentsChartData, hideXLabels }) {
  const data = paymentsChartData[chart.collectionName].map((entry) => {
    entry.name = entry.name.replace('_', ' ');
    return entry;
  });

  return (
    <div
      key={`payment-chart-${index}`}
      style={{
        minWidth: '28vw',
        textAlign: 'center',
      }}
    >
      <div>{chart.label}</div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChrt
          data={data}
          margin={{
            top: 5,
            right: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="4 3" stroke="rgba(0,0,0,.1)" />
          <XAxis dataKey="name" stroke="#aaaaaa" tick={!hideXLabels} />
          <YAxis stroke="#aaaaaa" />
          <Tooltip />
          <Bar dataKey="value" fill="#212121">
            {data.map((entry, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Bar>
        </BarChrt>
      </ResponsiveContainer>
    </div>
  );
}

BarChart.propTypes = {
  chart: PropTypes.any,
  index: PropTypes.number,
  paymentsChartData: PropTypes.object,
  hideXLabels: PropTypes.bool,
};

export default BarChart;
