import React from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import { currencyFormatter } from '../../../components/helpers/utils';

const useStyles = makeStyles({
  root: {
    marginLeft: 10,
    maxHeight: 180,
    maxWidth: 500,
    backgroundColor: '#212121',
  },
  title: {
    fontSize: 14,
    color: 'white',
    fontWeight: 500,
  },
  typo: {
    textAlign: 'right',
    marginLeft: 10,
    fontSize: 14,
    color: 'white',
    fontWeight: 500,
  },
});

const titleMap = {
  value: 'Suma wartości porzuconych koszyków:',
  count: 'Suma porzuconych koszyków:',
};

const keysMap = {
  value: 'valueSum',
  count: 'countSum',
};

function AbandonedCartsSum({ purchaseStore, type }) {
  const { currentDatesKeyForAbandonedCarts, abandonedCarts } = purchaseStore;

  const classes = useStyles();

  const key = currentDatesKeyForAbandonedCarts[type].key;
  const mask = currentDatesKeyForAbandonedCarts[type].mask;

  const value =
    abandonedCarts[key] &&
    abandonedCarts[key][mask] &&
    abandonedCarts[key][mask][keysMap[type]];

  const formattedValue =
    type === 'value' ? currencyFormatter.format(value) : value;

  return (
    <div
      style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 30 }}
    >
      <Card className={classes.root}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {titleMap[type]}
          </Typography>
          <Typography className={classes.typo} color="textSecondary">
            {value ? formattedValue : 'Brak danych'}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

export default inject('purchaseStore')(observer(AbandonedCartsSum));

AbandonedCartsSum.propTypes = {
  purchaseStore: PropTypes.shape({
    avgUsersWithPurchases: PropTypes.number,
    abandonedCarts: PropTypes.object,
    currentDatesKeyForAbandonedCarts: PropTypes.shape({
      key: PropTypes.string,
      mask: PropTypes.string,
    }),
  }),
  type: PropTypes.string,
};
