import React from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import TableWithJson from 'components/TableWithJson/TableWithJson';
import { percentFormatter } from '../../../components/helpers/utils';
import { Subtitle } from '../../../components/helpers/chartHelpers';

const columns = [
  { id: 'medium', label: '', minWidth: 40 },
  { id: 'registered', label: 'Zalogowani  (po user_id)', minWidth: 40 },
  {
    id: 'registeredPercent',
    label: 'Procent zalogowanych',
    minWidth: 100,
    format: percentFormatter.format,
  },
  {
    id: 'unregistered',
    label: 'Niezalogowani (po device_id)',
    minWidth: 40,
  },
  {
    id: 'unregisteredPercent',
    label: 'Procent niezalogowanych',
    minWidth: 100,
    format: percentFormatter.format,
  },
  {
    id: 'total',
    label: 'Razem',
    minWidth: 40,
  },
  {
    id: 'totalPercent',
    label: 'Procent razem',
    minWidth: 100,
    format: percentFormatter.format,
  },
];

function RegisteredUsersTable({
  usersStore: { registeredUsersTableData, loadingUsersPieChartData },
}) {
  if (registeredUsersTableData.length < 2) {
    return null;
  }
  return (
    <>
      <Subtitle color="#212121">
        Użytkownicy - szczegóły (wykres kołowy)
      </Subtitle>
      <TableWithJson
        columns={columns}
        data={registeredUsersTableData}
        loadingData={loadingUsersPieChartData}
        withPagination={false}
        showJson={false}
      />
    </>
  );
}

export default inject('usersStore')(observer(RegisteredUsersTable));

RegisteredUsersTable.propTypes = {
  usersStore: PropTypes.shape({
    registeredUsersTableData: PropTypes.array,
    loadingUsersPieChartData: PropTypes.bool,
  }),
};
