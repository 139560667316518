import React from 'react';
import moment from 'moment';
import { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

function TimeShortcuts({ setTimeTo, setTimeFrom }) {
  const SHORTCUTS = useMemo(
    () => [
      {
        label: 'Ostatnie 7 dni',
        from: moment().subtract(7, 'days').startOf('day').toDate(),
        to: moment().toDate(),
      },
      {
        label: 'Ostatnie 30 dni',
        from: moment().subtract(30, 'days').startOf('day').toDate(),
        to: moment().toDate(),
      },
      {
        label: 'Ten miesiąc',
        from: moment().startOf('month').toDate(),
        to: moment().toDate(),
      },
      {
        label: 'Poprzedni miesiąc',
        from: moment().subtract(1, 'month').startOf('month').toDate(),
        to: moment().subtract(1, 'month').endOf('month').toDate(),
      },
      {
        label: 'Ten kwartał',
        from: moment().startOf('Q').toDate(),
        to: moment().toDate(),
      },
      {
        label: 'Poprzedni kwartał',
        from: moment().subtract(1, 'Q').startOf('Q').toDate(),
        to: moment().subtract(1, 'Q').endOf('Q').toDate(),
      },
    ],
    [],
  );

  return (
    <div>
      <h3 style={{ margin: '35px 0px 30px 10px' }}>Skróty</h3>
      {SHORTCUTS.map((shortcut) => {
        return (
          <Button
            onClick={() => {
              setTimeFrom(shortcut.from);
              setTimeTo(shortcut.to);
            }}
            key={shortcut.label}
          >
            {shortcut.label}
          </Button>
        );
      })}
    </div>
  );
}

TimeShortcuts.propTypes = {
  setTimeTo: PropTypes.func,
  setTimeFrom: PropTypes.func,
};

export default TimeShortcuts;
