import React, { Component } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { Title } from '../../components/helpers/chartHelpers';

class ErrorPageContainer extends Component {
  render() {
    return (
      <div>
        <GridContainer spacing={3} style={{ padding: 20, paddingBottom: 100 }}>
          <GridItem xs={12}>
            <Title color="#212121">Coś poszło nie tak!</Title>
          </GridItem>
          <GridItem xs={12} style={{ height: '51vh' }} />
        </GridContainer>
      </div>
    );
  }
}

export default ErrorPageContainer;
