import React from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import BasicCard from '../../../components/BasicCard/BasicCard';

function DailySum({ purchaseStore }) {
  const { purchaseDaily } = purchaseStore;

  let sum = 0;
  purchaseDaily.forEach((item) => {
    sum += item.value;
  });

  return (
    <div
      style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 30 }}
    >
      <BasicCard label="Przychody w wybranym zakresie dat" value={sum} />
    </div>
  );
}

export default inject('purchaseStore')(observer(DailySum));

DailySum.propTypes = {
  purchaseStore: PropTypes.shape({
    purchaseDaily: PropTypes.array,
  }),
};
