import { action, observable } from 'mobx';
import config from '../config';
import aggregationsStore from './aggregationsStore';
import 'moment/locale/pl';
import moment from 'moment';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import qs from 'qs';
import { percentFormatter } from '../components/helpers/utils';

export class PersonaStore {
  GENDERS = {
    female: 'Kobieta',
    male: 'Mężczyzna',
    boys: 'Chłopak',
    girls: 'Dziewczyna',
  };

  @observable genderPieChartData = [];
  @observable loadingGenderPieChartData = true;

  @observable loadingPaymentsChartData = false;
  @observable paymentsChartData = {
    deliveryType: [],
    paymentType: [],
    paymentProvider: [],
  };

  @observable paymentsLineChartData = [];
  @observable loadingPaymentsLineChartData = false;

  @observable availableMasks = [];
  @observable loadingAvailableMasks = false;

  @observable paymentTypes = [];
  @observable loadingPaymentTypes = false;

  @observable paymentProviders = [];
  @observable loadingPaymentProviders = false;

  constructor() {}

  @action
  clearPaymentsLineChartData = () => {
    this.paymentsLineChartData = [];
  };

  @action fetchGenderPieChartData = async (timeFrom, timeTo) => {
    this.loadingGenderPieChartData = true;

    const query = {
      statId: config.statIds.usersByGenderByPageViewCount,
      timeFrom,
      timeTo,
      step: '1D',
      key: '$gender',
    };
    const {
      data: { sum: data },
    } = await aggregationsStore.fetchStatisticsSum(
      query.timeFrom,
      query.timeTo,
      query.step,
      query.statId,
      query.key,
    );

    const pieData = data.filter(({ _id }) => _id !== null);
    let sum = 0;
    pieData.forEach(({ sum: value }) => {
      sum += value;
    });
    this.genderPieChartData = pieData.map(({ _id: label, sum: value }) => ({
      label: this.GENDERS[label],
      value,
      percent: value / sum,
    }));
    this.loadingGenderPieChartData = false;
  };

  @action fetchPaymentsChartData = async (timeFrom, timeTo) => {
    const statIds = {
      // deliveryType: config.statIds.purchasesPerDeliveryType,
      paymentType: config.statIds.purchasesPerPaymentType,
      paymentProvider: config.statIds.purchasesPerPaymentProvider,
    };

    const keys = {
      // deliveryType: 'delivery_type',
      paymentType: 'payment_type',
      paymentProvider: 'payment_provider',
    };

    this.loadingPaymentsChartData = true;

    let collection = null;
    for (collection of Object.keys(statIds)) {
      const query = {
        statId: statIds[collection],
        timeFrom,
        timeTo,
        step: '1D',
        key: `$${keys[collection]}`,
      };

      const {
        data: { sum: data },
      } = await aggregationsStore.fetchStatisticsSum(
        query.timeFrom,
        query.timeTo,
        query.step,
        query.statId,
        query.key,
      );

      this.paymentsChartData[collection] = data
        .filter(({ _id }) => _id !== null && _id !== '')
        .map(({ _id, sum }) => ({ name: _id, value: sum }));
    }

    this.loadingPaymentsChartData = false;
  };

  @action fetchPaymentsLineChartData = async (
    timeFrom,
    timeTo,
    store,
    paymentType,
    paymentProvider,
  ) => {
    const query = {
      timeFrom,
      timeTo,
      store,
      paymentProvider,
      paymentType,
      statId: config.statIds.purchasesPerMaskPaymentTypePaymentProvider,
      step: '1D',
    };

    this.loadingPaymentsLineChartData = true;

    const { data } = await aggregationsStore.fetchStatistics(query);

    query.statId =
      config.statIds.purchasesValuePerMaskPaymentTypePaymentProvider;

    const { data: moneyData } = await aggregationsStore.fetchStatistics(query);

    const allQuery = {
      timeFrom,
      timeTo,
      store,
      statId: config.statIds.purchasesPerMaskPaymentTypePaymentProvider,
      step: '1D',
    };

    const { data: dataAll } = await aggregationsStore.fetchStatistics(allQuery);

    const dataObj = {};

    data.forEach((item) => {
      if (dataObj[item.fromTs]) {
        dataObj[item.fromTs] += item.value;
      } else {
        dataObj[item.fromTs] = item.value;
      }
    });

    const dataAllObj = {};

    dataAll.forEach((item) => {
      if (dataAllObj[item.fromTs]) {
        dataAllObj[item.fromTs] += item.value;
      } else {
        dataAllObj[item.fromTs] = item.value;
      }
    });

    const moneyDataObj = {};
    moneyData.forEach((item) => {
      if (moneyDataObj[item.fromTs]) {
        moneyDataObj[item.fromTs] += item.value;
      } else {
        moneyDataObj[item.fromTs] = item.value;
      }
    });

    const sumData = Object.keys(dataObj).map((key) => {
      const date = moment(key);
      return {
        fromTs: date.locale('pl').format('ll').toString(),
        value: dataObj[key],
        all: dataAllObj[key],
        percent:
          dataObj[key] && dataAllObj[key]
            ? percentFormatter.format(dataObj[key] / dataAllObj[key])
            : 0,
        percentRaw:
          dataObj[key] && dataAllObj[key]
            ? ((dataObj[key] / dataAllObj[key]) * 100).toFixed(2)
            : 0,
        moneyValue: moneyDataObj[key]
          ? parseInt(moneyDataObj[key].toFixed(2), 10)
          : 0,
      };
    });

    this.paymentsLineChartData = sumData;

    this.loadingPaymentsLineChartData = false;
  };

  @action fetchPaymentTypes = async (
    timeFrom,
    timeTo,
    store,
    paymentProvider,
  ) => {
    this.loadingPaymentTypes = true;

    const currentMonthQuery = {
      statId: config.statIds.purchasesPerMaskPaymentTypePaymentProvider,
      step: '1D',
      timeFrom,
      timeTo,
      store,
      key: 'payment_type',
    };

    if (paymentProvider) {
      currentMonthQuery.payment_provider = paymentProvider;
    }

    const { data } = await API.get(
      `${API_ROUTES.STATISTIC_DISTINCT_KEY}?${qs.stringify(currentMonthQuery)}`,
    );

    this.paymentTypes = data.filter((type) => type);
    this.loadingPaymentTypes = false;
  };

  @action fetchPaymentProviders = async (
    timeFrom,
    timeTo,
    store,
    paymentType,
  ) => {
    this.loadingPaymentProviders = true;

    const currentMonthQuery = {
      statId: config.statIds.purchasesPerMaskPaymentTypePaymentProvider,
      step: '1D',
      timeFrom,
      timeTo,
      store,
      key: 'payment_provider',
    };

    if (paymentType) {
      currentMonthQuery.payment_type = paymentType;
    }

    const { data } = await API.get(
      `${API_ROUTES.STATISTIC_DISTINCT_KEY}?${qs.stringify(currentMonthQuery)}`,
    );

    this.paymentProviders = data.filter((provider) => provider);
    this.loadingPaymentProviders = false;
  };

  @action fetchMasks = async (timeFrom, timeTo) => {
    this.loadingAvailableMasks = true;
    const currentMonthQuery = {
      statId: config.statIds.purchasesPerMaskPaymentTypePaymentProvider,
      step: '1D',
      timeFrom,
      timeTo,
      key: 'store',
    };

    const { data } = await API.get(
      `${API_ROUTES.STATISTIC_DISTINCT_KEY}?${qs.stringify(currentMonthQuery)}`,
    );

    this.availableMasks = data;
    this.loadingAvailableMasks = false;
  };
}

export default new PersonaStore();
