import React from 'react';
import * as PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  PieChart as PieChrt,
  Pie,
  Tooltip,
  Cell,
} from 'recharts';
import { COLORS } from './index';

function PieChart({ chart, index, paymentsChartData }) {
  const data = paymentsChartData[chart.collectionName].map((entry) => {
    entry.name = entry.name.replace('_', ' ');
    return entry;
  });

  return (
    <div
      key={`payment-chart-${index}`}
      style={{ minWidth: '28vw', textAlign: 'center' }}
    >
      <div>{chart.label}</div>
      <ResponsiveContainer width="100%" height={300}>
        <PieChrt>
          <Pie fill="#003f5c" data={data}>
            {data.map((entry, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChrt>
      </ResponsiveContainer>
    </div>
  );
}

PieChart.propTypes = {
  chart: PropTypes.any,
  index: PropTypes.number,
  paymentsChartData: PropTypes.object,
};

export default PieChart;
