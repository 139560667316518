import { action, observable } from 'mobx';
import config from '../config';
import moment from 'moment';
import 'moment/locale/pl';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import qs from 'qs';

const LP_TYPES = {
  landing_page: 'Landing page',
  landing_page_element: 'Landing page element',
};

export class LandingPagesStore {
  @observable tableData = [];
  @observable isLoadingTableData = false;
  @observable landingPagesOptions = [];
  @observable isLoadingLandingPagesOptions = false;

  limit = 100;

  @action
  fetchDistinct = async ({ timeFrom, timeTo, distinctKey }) => {
    this.isLoadingLandingPagesOptions = true;
    this.landingPagesOptions = [];

    const query = {
      statId: config.statIds.landingPagesClicks,
      step: '1D',
      timeFrom: moment(timeFrom).startOf('day').toISOString(),
      timeTo: moment(timeTo).endOf('day').toISOString(),
      key: distinctKey,
    };

    try {
      const { data } = await API.get(
        `${API_ROUTES.STATISTIC_DISTINCT_KEY}?${qs.stringify(query)}`,
      );

      this.landingPagesOptions = data;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingLandingPagesOptions = false;
    }
  };

  @action
  fetchTopByKey = async ({ timeFrom, timeTo, landingPageId }) => {
    this.isLoadingTableData = true;

    const query = {
      statId: config.statIds.landingPagesClicks,
      step: '1D',
      timeFrom: moment(timeFrom).startOf('day').toISOString(),
      timeTo: moment(timeTo).endOf('day').toISOString(),
      groupingKey: 'object_name',
      aggregateKey: '$value',
      limit: this.limit,
      params: {},
    };

    if (landingPageId) {
      query.params.object_name = landingPageId;
    }

    try {
      let { data: data } = await API.get(
        `${API_ROUTES.STATISTICS_TOP}?${qs.stringify(query)}`,
      );

      const landingPagesIds = data.map(
        ({ _id: landingPageId }) => landingPageId,
      );

      const { data: landingPages } = await API.get(
        `${API_ROUTES.LANDING_PAGES}?${qs.stringify({ landingPagesIds })}`,
      );

      const landingPagesObj = {};
      landingPages.forEach(({ title, analyticsId, image, type }) => {
        landingPagesObj[analyticsId] = { title, image, type };
      });

      this.tableData = data.map(({ _id: landingPageId, value }, index) => {
        let item = {
          landingPageId,
          value,
          position: index + 1,
        };

        if (landingPagesObj[landingPageId]) {
          item.type = LP_TYPES[landingPagesObj[landingPageId].type];
          item.title = landingPagesObj[landingPageId].title || '-';
          item.subtitle = landingPagesObj[landingPageId].subtitle || '-';
          item.image = landingPagesObj[landingPageId].image || '-';
        }
        return item;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingTableData = false;
    }
  };
}

export default new LandingPagesStore();
