import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Error } from './FormError';
import * as PropTypes from 'prop-types';
import { StyledTextField } from '../../../components/StyledTextField';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    backgroundColor: '#212121',
    padding: '30px',
    borderRadius: '10px',
    color: '#808080',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: 'black',
    color: '#808080',
  },
}));

export default function LoginPage({ login, authError }) {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  async function submit(event, login) {
    event.preventDefault();
    await login(email, password);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={(e) => submit(e, login)}
        >
          <StyledTextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            onInput={(e) => setEmail(e.target.value)}
            autoFocus
            inputProps={{
              style: {
                color: '#808080',
                WebkitBoxShadow: '0 0 0 1000px #212121 inset',
                WebkitTextFillColor: '#808080',
              },
            }}
          />
          <StyledTextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onInput={(e) => setPassword(e.target.value)}
            inputProps={{
              style: {
                color: '#808080',
                WebkitBoxShadow: '0 0 0 1000px #212121 inset',
                WebkitTextFillColor: '#808080',
              },
            }}
          />
          {authError !== '' && <Error>{authError}</Error>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Login
          </Button>
        </form>
      </div>
    </Container>
  );
}

LoginPage.propTypes = {
  login: PropTypes.func,
  authError: PropTypes.string,
};
