import React, { useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import {
  customDropdownStyles,
  FilterBox,
} from 'components/helpers/chartHelpers';
import Select from 'react-select';
import moment from 'moment';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import TableWithJson from 'components/TableWithJson/TableWithJson';
import { Filters, FlexRow } from '../styles';

const weatherHintsColumns = [
  { id: 'store', label: 'Maska', minWidth: 40 },
  { id: 'surveyName', label: 'Ankieta', minWidth: 40 },
  { id: 'questionTitle', label: 'Pytanie', minWidth: 40 },
  { id: 'answer', label: 'Odpowiedź', minWidth: 100 },
  { id: 'value', label: 'Ilość', minWidth: 100 },
];

function SurveyAnswers({
  usersStore: {
    fetchMasksForSurveys,
    surveyAnswersMasks,
    fetchSurveyStatistics,
    surveyStatistics,
    isLoadingSurveyStatistics,
    surveys,
    isLoadingSurveys,
  },
}) {
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());
  const [mask, setMask] = useState('four_fstore_pl');
  const [survey, setSurvey] = useState();

  const fetchData = (survey) => {
    fetchSurveyStatistics({
      from: startDate,
      to: endDate,
      mask,
      surveyId: survey ? survey.value : null,
    });
  };

  useEffect(() => {
    fetchData(survey);
    fetchMasksForSurveys({ from: startDate, to: endDate, pageviews: false });
  }, [startDate, endDate, mask, survey]);

  const masks = useMemo(() => {
    return surveyAnswersMasks
      ? surveyAnswersMasks.filter((mask) => mask !== 'Wszystkie')
      : [];
  }, [surveyAnswersMasks]);

  return (
    <>
      <Filters className="no-horizontal-margin">
        <FlexRow>
          <div
            style={{
              marginRight: 'auto',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <DateRangePicker
              startDate={startDate}
              onStartDateChange={(date) => setStartDate(date)}
              endDate={endDate}
              onEndDateChange={(date) => setEndDate(date)}
            />
          </div>
          <FilterBox style={{ maxWidth: 250 }}>
            Maska:
            <Select
              placeholder="Maska..."
              options={masks}
              value={[mask]}
              getOptionLabel={(option) => option}
              getOptionValue={(option) => option}
              styles={customDropdownStyles}
              onChange={setMask}
            />
          </FilterBox>
          <FilterBox style={{ maxWidth: 400 }}>
            Ankieta:
            <Select
              isClearable
              isLoading={isLoadingSurveys}
              value={[survey]}
              placeholder="Wybierz ankietę..."
              name="surveys"
              options={surveys}
              styles={customDropdownStyles}
              onChange={(option) => {
                setSurvey(option);
              }}
            />
          </FilterBox>
        </FlexRow>
      </Filters>
      <TableWithJson
        columns={weatherHintsColumns}
        data={surveyStatistics}
        loadingData={isLoadingSurveyStatistics}
        withPagination={false}
        showJson={false}
      />
    </>
  );
}

export default inject('usersStore')(observer(SurveyAnswers));

SurveyAnswers.propTypes = {
  usersStore: PropTypes.shape({
    fetchSurveyStatistics: PropTypes.func,
    fetchMasksForSurveys: PropTypes.func,
    surveyAnswersMasks: PropTypes.array,
    isLoadingMasksForSurveysAnswers: PropTypes.bool,
    surveyStatistics: PropTypes.array,
    isLoadingSurveyStatistics: PropTypes.bool,
    surveys: PropTypes.array,
    isLoadingSurveys: PropTypes.bool,
  }),
};
