import React from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import TableWithJson from '../../../components/TableWithJson/TableWithJson';
import { currencyFormatter } from '../../../components/helpers/utils';
import { hourFormatter } from 'components/utils/hourFormatter';

const columns = [
  { id: 'hour', label: 'Godzina', minWidth: 100, format: hourFormatter },
  {
    id: 'today',
    label: 'dziś',
    minWidth: 100,
    format: currencyFormatter.format,
  },
  {
    id: 'yesterday',
    label: 'wczoraj',
    minWidth: 100,
    format: currencyFormatter.format,
  },
  { id: 'week', label: 'WoW', minWidth: 100, format: currencyFormatter.format },
];

function DiscountTable({ discountStore }) {
  const { discountsHourly, loadingDiscountsHourlyData } = discountStore;

  return (
    <TableWithJson
      columns={columns}
      data={discountsHourly}
      loadingData={loadingDiscountsHourlyData}
      withPagination={false}
      showJson={false}
    />
  );
}

export default inject('discountStore')(observer(DiscountTable));

DiscountTable.propTypes = {
  discountStore: PropTypes.shape({
    discountsHourly: PropTypes.array,
    loadingDiscountsHourlyData: PropTypes.bool,
  }),
};
