import { action, observable } from 'mobx';
import config from '../config';
import moment from 'moment';
import 'moment/locale/pl';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import qs from 'qs';
import aggregationsStore from './aggregationsStore';
import { orderBy } from 'lodash';

export class NotificationsStore {
  @observable notifications = [];
  @observable isLoadingNotifications = false;
  @observable availableMasks = [];
  @observable isLoadingAvailableMasks = false;
  @observable inboxOpenedData = [];
  @observable isLoadingInboxOpened = false;
  @observable notificationReadData = [];
  @observable isLoadingNotificationRead = false;

  limit = 100;

  @action
  fetchNotifications = async ({ from, to, mask, type }) => {
    this.isLoadingNotifications = true;

    const query = {
      statId: config.statIds.inboxClicks,
      step: '1D',
      timeFrom: moment(from).startOf('day').toISOString(),
      timeTo: moment(to).endOf('day').toISOString(),
      groupingKey: 'object_id',
      aggregateKey: '$value',
      limit: this.limit,
      store: mask,
      type: type,
    };

    try {
      let { data: data } = await API.get(
        `${API_ROUTES.TOP_NOTIFICATIONS}?${qs.stringify(query)}`,
      );

      this.notifications = data.map(
        ({ _id: analyticsId, value, title, content }, index) => ({
          analyticsId,
          title,
          value,
          content,
          position: index + 1,
        }),
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingNotifications = false;
    }
  };

  fetchStatsPerStore = async ({ from, to, type }) => {
    if (type === 'inbox_opened') {
      this.isLoadingInboxOpened = true;
    } else {
      this.isLoadingNotificationRead = true;
    }

    const params = { type };
    const {
      data: { sum: sum },
    } = await aggregationsStore.fetchStatisticsSum(
      from,
      to,
      '1D',
      config.statIds.inboxClicks,
      '$store',
      { params },
    );

    const data = orderBy(sum, 'sum', 'desc');
    if (type === 'inbox_opened') {
      this.inboxOpenedData = data;
      this.isLoadingInboxOpened = false;
    } else {
      this.isLoadingNotificationRead = false;
      this.notificationReadData = data;
    }
  };

  @action fetchMasks = async ({ from, to }) => {
    this.loadingAvailableMasks = true;
    this.availableMasks = [];

    const currentMonthQuery = {
      statId: config.statIds.inboxClicks,
      step: '1D',
      timeFrom: moment(from).startOf('day').toISOString(),
      timeTo: moment(to).endOf('day').toISOString(),
      key: 'store',
    };

    const { data } = await API.get(
      `${API_ROUTES.STATISTIC_DISTINCT_KEY}?${qs.stringify(currentMonthQuery)}`,
    );
    this.availableMasks = data.map((mask) => ({ label: mask, value: mask }));
    this.loadingAvailableMasks = false;
  };
}

export default new NotificationsStore();
