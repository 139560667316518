import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import TableWithJson from '../../../components/TableWithJson/TableWithJson';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import moment from 'moment';
import BasicCard from '../../../components/BasicCard/BasicCard';

const HalfContainer = styled(Flex)`
  width: 50%;
  justify-content: flex-start;
  line-height: 36px;
`;

const DatePickerContainer = styled(Flex)`
  margin-bottom: 20px;
  flex-direction: row;
`;

function ProductsCountTable({
  productsStore: {
    isLoadingProductsCount,
    productsCount,
    fetchProductsCount,
    productsCountSum,
  },
}) {
  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'months').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().toDate());

  useEffect(() => {
    fetchProductsCount({
      fromDate: startDate,
      toDate: endDate,
    });
  }, [startDate, endDate]);

  return (
    <>
      <div>
        <DatePickerContainer>
          <HalfContainer style={{ marginRight: 'auto' }}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onEndDateChange={setEndDate}
              onStartDateChange={setStartDate}
              showTime={false}
            />
          </HalfContainer>
        </DatePickerContainer>
        <TableWithJson
          columns={columns}
          data={productsCount}
          loadingData={isLoadingProductsCount}
          withPagination={false}
          showJson={false}
        />
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 24 }}
      >
        <BasicCard
          label="Ilość sprzedanych produktów"
          value={productsCountSum}
          useFormatter={false}
        />
      </div>
    </>
  );
}

const columns = [
  { id: '_id', label: 'Maska', minWidth: 100 },
  { id: 'sum', label: 'Ilość produktów', minWidth: 100 },
];

export default inject('productsStore')(observer(ProductsCountTable));

ProductsCountTable.propTypes = {
  productsStore: PropTypes.shape({
    isLoadingProductsCount: PropTypes.bool,
    productsCount: PropTypes.array,
    fetchProductsCount: PropTypes.func,
    productsCountSum: PropTypes.number,
  }),
};
