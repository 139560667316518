import withStyles from '@material-ui/core/styles/withStyles';
import Checkbox from '@material-ui/core/Checkbox';
import React from 'react';

const GreenCheckbox = withStyles({
  root: {
    color: '#9CA2AB',
    '&$checked': {
      color: '#212121',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
export default GreenCheckbox;
