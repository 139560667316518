import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import CustomLineChart from '../../../components/CustomLineChart/CustomLineChart';
import moment from 'moment';
import MaskSelectDateRange from '../../SalesPage/Components/MaskSelectDateRange';
import Select from 'react-select';

const colors = ['#272727', '#868686'];
const keys = ['sum'];
const keysTranslation = ['Ilość wyświetleń ankiet'];
const xAxis = '_id';

const SurveysViewsChart = ({ usersStore }) => {
  const {
    isLoadingSurveysData,
    surveysData,
    isLoadingMasksForSurveysPageviews,
    surveyPageviewsMasks,
    fetchMasksForSurveys,
    fetchSurveysChartData,
    fetchSurveys,
    isLoadingSurveys,
    surveys,
  } = usersStore;
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [chosenMask, setChosenMask] = useState('four_fstore_pl');
  const [survey, setSurvey] = useState();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (startDate && endDate && isMounted) {
      fetchSurveysChartData(startDate, endDate, chosenMask, survey);
      fetchMasksForSurveys({ from: startDate, to: endDate, pageviews: true });
    }
  }, [startDate, endDate, chosenMask, isMounted, survey]);

  useEffect(() => {
    fetchSurveys();
    setIsMounted(true);
  }, []);

  return (
    <>
      <CustomLineChart
        loadingData={isLoadingSurveysData}
        data={surveysData}
        xAxisKey={xAxis}
        dataKeys={keys}
        keysTranslation={keysTranslation}
        keysColors={colors}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          margin: 'auto',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: 200, marginRight: 20 }}>
          <Select
            isLoading={isLoadingSurveys}
            value={[survey]}
            placeholder="Wybierz ankietę..."
            name="surveys"
            options={surveys}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            onChange={(option) => {
              setSurvey(option);
            }}
          />
        </div>
        <div style={{ width: 600, marginRight: 20 }}>
          <MaskSelectDateRange
            chosenMask={chosenMask}
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            masks={surveyPageviewsMasks}
            loadingMasks={isLoadingMasksForSurveysPageviews}
            setMask={setChosenMask}
          />
        </div>
      </div>
    </>
  );
};
export default inject('usersStore')(observer(SurveysViewsChart));

SurveysViewsChart.propTypes = {
  usersStore: PropTypes.shape({
    isLoadingSurveysData: PropTypes.bool,
    surveysData: PropTypes.array,
    isLoadingMasksForSurveysPageviews: PropTypes.bool,
    surveyPageviewsMasks: PropTypes.array,
    fetchMasksForSurveys: PropTypes.func,
    fetchSurveysChartData: PropTypes.func,
    fetchSurveys: PropTypes.func,
    isLoadingSurveys: PropTypes.bool,
    surveys: PropTypes.array,
  }),
};
