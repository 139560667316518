import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import {
  BaseOption,
  customDropdownStyles,
  FilterBox,
} from 'components/helpers/chartHelpers';
import Select from 'react-select';
import moment from 'moment';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import TableWithJson from 'components/TableWithJson/TableWithJson';
import { Filters, FlexRow } from '../styles';
import { currencyFormatter } from '../../../components/helpers/utils';

const masks = [{ label: 'four_fstore_pl', value: 'four_fstore_pl' }];

const weatherHintsColumns = [
  { id: 'position', label: 'Pozycja', minWidth: 40 },
  { id: 'analyticsId', label: 'ID podpowiedzi', minWidth: 40 },
  { id: 'hintMessage', label: 'Podpowiedź', minWidth: 100 },
  { id: 'categoryName', label: 'Kategoria', minWidth: 100 },
  { id: 'categoryPath', label: 'Ścieżka kategorii', minWidth: 100 },
  { id: 'value', label: 'Ilość', minWidth: 100 },
  {
    id: 'conversion',
    label: 'Wartość',
    minWidth: 100,
    format: currencyFormatter.format,
  },
];

function WeatherHintsTable({
  usersStore: {
    fetchMasks,
    fetchWeatherHints,
    weatherHints,
    isLoadingWeatherHints,
    availableMasks,
  },
}) {
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());
  const [mask, setMask] = useState('four_fstore_pl');

  const fetchData = () => {
    fetchWeatherHints({
      from: startDate,
      to: endDate,
      mask,
    });
  };

  useEffect(() => {
    fetchData();
    fetchMasks({ from: startDate, to: endDate });
  }, [startDate, endDate, mask]);

  return (
    <>
      <Filters className="no-horizontal-margin">
        <FlexRow>
          <div
            style={{
              marginRight: 'auto',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <DateRangePicker
              startDate={startDate}
              onStartDateChange={(date) => setStartDate(date)}
              endDate={endDate}
              onEndDateChange={(date) => setEndDate(date)}
            />
          </div>
          <FilterBox style={{ maxWidth: 300 }}>
            Maska:
            <Select
              placeholder="Maska..."
              options={availableMasks || masks}
              value={(availableMasks || masks).filter(
                (option) => option.value === mask,
              )}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              styles={customDropdownStyles}
              components={{ Option: BaseOption }}
              onChange={(option) => setMask(option ? option.value : null)}
            />
          </FilterBox>
        </FlexRow>
      </Filters>
      <TableWithJson
        columns={weatherHintsColumns}
        data={weatherHints}
        loadingData={isLoadingWeatherHints}
        withPagination={false}
        showJson={false}
      />
    </>
  );
}

export default inject('usersStore')(observer(WeatherHintsTable));

WeatherHintsTable.propTypes = {
  usersStore: PropTypes.shape({
    fetchMasks: PropTypes.func,
    fetchWeatherHints: PropTypes.func,
    weatherHints: PropTypes.array,
    isLoadingWeatherHints: PropTypes.bool,
    availableMasks: PropTypes.array,
    loadingAvailableMasks: PropTypes.bool,
  }),
};
