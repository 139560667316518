import { action, observable } from 'mobx';
import config from '../config';
import moment from 'moment';
import 'moment/locale/pl';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import qs from 'qs';
import aggregationsStore from './aggregationsStore';
import { orderBy, sortBy } from 'lodash';
import { getProperValueBasedOnSortedCollections } from '../components/utils/collectionMergingUtils';

const ELEMENTS_TYPES = {
  banner: config.statIds.bannersConversion,
  element: config.statIds.appStoryConversion,
  landingPage: config.statIds.landingPageConversion,
};

export class BannersStore {
  @observable loadingTopBannersGlobal = false;
  @observable topBannersGlobal = [];
  @observable topBannersIds = false;
  @observable shadowDateFrom = moment()
    .subtract(1, 'month')
    .startOf('month')
    .toISOString();
  @observable shadowDateTo = moment()
    .subtract(1, 'month')
    .endOf('month')
    .toISOString();

  @observable availableMasks = [];
  @observable loadingAvailableMasks = true;

  @observable availableMasksDateRange = [];
  @observable loadingAvailableMasksDateRange = false;

  @observable chosenMasks = [];

  @observable loadingTopBannersPerMaskCounter = 0;
  @observable topBannersPerMask = [];

  @observable chosenDateRangeMasks = [];

  @observable loadingTopBannersPerMaskDateRangeCounter = 0;
  @observable topBannersPerMaskDateRange = [];

  @observable isLoadingElementConversion = false;
  @observable conversionLineChartData = [];
  @observable isLoadingBanners = false;
  @observable banners = [];
  @observable conversionSum = 0;
  @observable chosenConversionBanner = '';

  @observable topBannersComparisonData = [];
  @observable loadingTopBannersComparison = false;

  limit = 100;

  @action setChosenMasks = (options) => {
    this.chosenMasks = options;
  };

  @action setConversionChosenBanner = (banner) => {
    const bannerObj = this.banners.find((item) => item.value === banner);
    if (bannerObj) {
      this.chosenConversionBanner = bannerObj;
    } else {
      this.chosenConversionBanner = '';
      this.clearConversion();
    }
  };

  @action setChosenDateRangeMasks = (options) => {
    this.chosenDateRangeMasks = options;
  };

  @action
  fetchMasks = async () => {
    this.loadingAvailableMasks = true;
    this.availableMasks = [];

    const currentMonthQuery = {
      statId: config.statIds.bannerCountPerBannerPerMask,
      step: '1D',
      timeFrom: moment().startOf('month').toISOString(),
      timeTo: moment().toISOString(),
      key: 'store',
    };

    const { data } = await API.get(
      `${API_ROUTES.STATISTIC_DISTINCT_KEY}?${qs.stringify(currentMonthQuery)}`,
    );

    this.availableMasks = data;
    this.loadingAvailableMasks = false;
  };

  @action
  fetchMasksForDateRange = async (fromDate, toDate) => {
    this.loadingAvailableMasksDateRange = true;
    this.availableMasksDateRange = [];

    const currentMonthQuery = {
      statId: config.statIds.bannerCountPerBannerPerMask,
      step: '1D',
      timeFrom: moment(fromDate).toISOString(),
      timeTo: moment(toDate).toISOString(),
      key: 'store',
    };

    const { data } = await API.get(
      `${API_ROUTES.STATISTIC_DISTINCT_KEY}?${qs.stringify(currentMonthQuery)}`,
    );

    this.availableMasksDateRange = data;
    this.loadingAvailableMasksDateRange = false;
  };

  @action
  fetchBannersTop = async ({ mask, genderType, startDate, endDate }) => {
    let statId = null;
    if (mask) {
      statId = config.statIds.bannerCountPerBannerPerMask;
      this.loadingTopBannersPerMaskCounter += 1;
      this.topBannersPerMask[mask] = [];
    } else {
      statId = config.statIds.bannerCountPerBanner;
      this.loadingTopBannersGlobal = true;
      this.topBannersGlobal = [];
    }
    const timeFrom = startDate || moment().startOf('month').startOf('day');
    const timeTo = endDate || moment().endOf('day');

    const diffForShadow = moment(endDate).diff(startDate, 'day');

    this.shadowDateTo = moment(startDate)
      .subtract(1, 'day')
      .endOf('day')
      .toISOString();
    this.shadowDateFrom = moment(this.shadowDateTo)
      .subtract(diffForShadow, 'day')
      .startOf('day')
      .toISOString();

    const monthQuery = {
      statId: statId,
      step: '1D',
      timeFrom: timeFrom.toISOString(),
      timeTo: timeTo.toISOString(),
      key: '$object_name',
      aggregateKey: '$value',
      limit: this.limit,
    };

    if (mask) {
      monthQuery.store = mask;
    }

    if (genderType) {
      monthQuery.genderType = genderType;
    }

    let { data: month } = await API.get(
      `${API_ROUTES.STATISTICS_TOP_BANNERS}?${qs.stringify(monthQuery)}`,
    );
    month.forEach((item, index) => (item.position = index + 1));

    let topBannerIds = month.map((item) => item._id);
    topBannerIds = sortBy(topBannerIds);
    this.topBannerIds = topBannerIds;

    const todayQuery = {
      step: '1H',
      timeFrom: moment().startOf('day').toISOString(),
      timeTo: moment().toISOString(),
      key: '$object_name',
    };

    const yesterdayQuery = {
      step: '1H',
      timeFrom: moment().subtract(1, 'days').startOf('day').toISOString(),
      timeTo: moment().startOf('day').toISOString(),
      key: '$object_name',
    };

    const weekQuery = {
      step: '1H',
      timeFrom: moment().subtract(7, 'days').startOf('day').toISOString(),
      timeTo: moment().startOf('day').toISOString(),
      key: '$object_name',
    };

    const shadowQuery = {
      step: '1D',
      key: '$object_name',
      timeFrom: this.shadowDateFrom,
      timeTo: this.shadowDateTo,
    };

    const params = {
      object_name: topBannerIds.toString(),
    };

    let {
      data: { sum: todayValue },
    } = await aggregationsStore.fetchStatisticsSum(
      todayQuery.timeFrom,
      todayQuery.timeTo,
      todayQuery.step,
      statId,
      todayQuery.key,
      { params },
    );
    let {
      data: { sum: yesterdayValue },
    } = await aggregationsStore.fetchStatisticsSum(
      yesterdayQuery.timeFrom,
      yesterdayQuery.timeTo,
      yesterdayQuery.step,
      statId,
      yesterdayQuery.key,
      { params },
    );
    let {
      data: { sum: weekValue },
    } = await aggregationsStore.fetchStatisticsSum(
      weekQuery.timeFrom,
      weekQuery.timeTo,
      weekQuery.step,
      statId,
      weekQuery.key,
      { params },
    );

    let {
      data: { sum: shadowValue },
    } = await aggregationsStore.fetchStatisticsSum(
      shadowQuery.timeFrom,
      shadowQuery.timeTo,
      shadowQuery.step,
      statId,
      shadowQuery.key,
      { params },
    );

    const monthValue = orderBy(month, '_id', 'asc');
    this.orderedTopGlobalMonthValues = monthValue;
    const collectionsToMerge = {
      todayValue,
      yesterdayValue,
      weekValue,
      monthValue,
      shadowValue,
    };

    const indexes = {
      todayValue: 0,
      yesterdayValue: 0,
      weekValue: 0,
      monthValue: 0,
      shadowValue: 0,
    };

    const result = [];
    for (let i = 0; i < topBannerIds.length; i++) {
      const row = {};

      Object.keys(collectionsToMerge).forEach((collectionName) => {
        const { value, index } = getProperValueBasedOnSortedCollections(
          collectionsToMerge[collectionName],
          indexes[collectionName],
          topBannerIds,
          i,
          '_id',
        );
        row[collectionName] = value;
        indexes[collectionName] = index;
      });

      const title = collectionsToMerge.monthValue[indexes.monthValue - 1].title;

      row.position =
        collectionsToMerge.monthValue[indexes.monthValue - 1].position;
      row.analyticsId =
        collectionsToMerge.monthValue[indexes.monthValue - 1]._id;
      row.image = collectionsToMerge.monthValue[indexes.monthValue - 1].image;
      row.video = collectionsToMerge.monthValue[indexes.monthValue - 1].video;
      row.mainImage =
        collectionsToMerge.monthValue[indexes.monthValue - 1].mainImage;
      row.store =
        collectionsToMerge.monthValue[indexes.monthValue - 1].store || '-';
      row.title = title && title.trim() !== '' ? title : '-';
      result.push(row);
    }

    if (mask) {
      this.topBannersPerMask[mask] = orderBy(result, 'monthValue', 'desc');
      this.loadingTopBannersPerMaskCounter -= 1;
    } else {
      this.topBannersGlobal = orderBy(result, 'monthValue', 'desc');
      this.loadingTopBannersGlobal = false;
    }
  };

  fetchBannersTopComparison = async ({
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
    mask,
    genderType,
  }) => {
    let statId = null;
    if (mask) {
      statId = config.statIds.bannerCountPerBannerPerMask;
    } else {
      statId = config.statIds.bannerCountPerBanner;
    }

    this.topBannersComparisonData = [];
    this.loadingTopBannersComparison = true;

    const query = {
      statId: statId,
      step: '1D',
      timeFrom: moment(startDate).startOf('day').toISOString(),
      timeTo: moment(endDate).endOf('day').toISOString(),
      key: '$object_name',
      aggregateKey: '$value',
      limit: this.limit,
    };

    if (mask) {
      query.store = mask;
    }

    if (genderType) {
      query.genderType = genderType;
    }

    let { data: topBanners } = await API.get(
      `${API_ROUTES.STATISTICS_TOP_BANNERS}?${qs.stringify(query)}`,
    );
    topBanners.forEach((item, index) => (item.position = index + 1));

    let topBannerIds = topBanners.map((item) => item._id);
    topBannerIds = sortBy(topBannerIds);

    const secondQuery = {
      step: '1D',
      timeFrom: moment(compareStartDate).startOf('day').toISOString(),
      timeTo: moment(compareEndDate).endOf('day').toISOString(),
      key: '$object_name',
    };

    const params = {
      object_name: topBannerIds.toString(),
    };

    let {
      data: { sum: secondDateRangeValue },
    } = await aggregationsStore.fetchStatisticsSum(
      secondQuery.timeFrom,
      secondQuery.timeTo,
      secondQuery.step,
      statId,
      secondQuery.key,
      { params },
    );

    const secondTopQuery = {
      statId: statId,
      step: '1D',
      timeFrom: secondQuery.timeFrom,
      timeTo: secondQuery.timeTo,
      key: '$object_name',
      aggregateKey: '$value',
      limit: this.limit,
    };

    let { data: secondTopQueryData } = await API.get(
      `${API_ROUTES.STATISTICS_TOP_BANNERS}?${qs.stringify(secondTopQuery)}`,
    );
    const secondQueryPositions = {};
    secondTopQueryData.forEach(({ _id }, index) => {
      secondQueryPositions[_id] = index + 1;
    });

    let {
      data: { sum: allBannersSumForQuery },
    } = await aggregationsStore.fetchStatisticsSum(
      query.timeFrom,
      query.timeTo,
      query.step,
      statId,
      null,
    );

    let {
      data: { sum: allBannersSumForSecondQuery },
    } = await aggregationsStore.fetchStatisticsSum(
      secondQuery.timeFrom,
      secondQuery.timeTo,
      secondQuery.step,
      statId,
      null,
    );

    const value = orderBy(topBanners, '_id', 'asc');

    const collectionsSum = {
      value: allBannersSumForQuery,
      secondDateRangeValue: allBannersSumForSecondQuery,
    };

    const collectionsToMerge = {
      value,
      secondDateRangeValue,
    };

    const indexes = {
      secondDateRangeValue: 0,
      value: 0,
    };

    const result = [];
    for (let i = 0; i < topBannerIds.length; i++) {
      const row = {};

      Object.keys(collectionsToMerge).forEach((collectionName) => {
        const { value, index } = getProperValueBasedOnSortedCollections(
          collectionsToMerge[collectionName],
          indexes[collectionName],
          topBannerIds,
          i,
          '_id',
        );
        row[collectionName] = value;
        row[`${collectionName}Percent`] =
          value / collectionsSum[collectionName];
        indexes[collectionName] = index;
      });

      const title = collectionsToMerge.value[indexes.value - 1].title;
      row.valuePosition = collectionsToMerge.value[indexes.value - 1].position;
      row.analyticsId = collectionsToMerge.value[indexes.value - 1]._id;
      row.image = collectionsToMerge.value[indexes.value - 1].image;
      row.video = collectionsToMerge.value[indexes.value - 1].video;
      row.mainImage = collectionsToMerge.value[indexes.value - 1].mainImage;
      row.store = collectionsToMerge.value[indexes.value - 1].store || '-';
      row.title = title && title.trim() !== '' ? title : '-';

      row.secondDateRangeValuePosition =
        secondQueryPositions[row.analyticsId] || '+100';

      result.push(row);
    }

    this.topBannersComparisonData = orderBy(result, 'value', 'desc');
    this.loadingTopBannersComparison = false;
  };

  @action
  fetchBannersTopDateRange = async (mask, dateFrom, dateTo) => {
    const statId = config.statIds.bannerCountPerBannerPerMask;
    this.loadingTopBannersPerMaskDateRangeCounter += 1;
    this.topBannersPerMaskDateRange[mask] = [];

    const monthQuery = {
      statId: statId,
      step: '1D',
      timeFrom: moment(dateFrom).startOf('month').toISOString(),
      timeTo: moment(dateTo).toISOString(),
      key: '$object_name',
      aggregateKey: '$value',
      limit: this.limit,
      store: mask,
    };

    let { data: month } = await API.get(
      `${API_ROUTES.STATISTICS_TOP_BANNERS}?${qs.stringify(monthQuery)}`,
    );
    month.forEach((item, index) => (item.position = index + 1));
    this.topBannersPerMaskDateRange[mask] = orderBy(month, 'value', 'desc');
    this.loadingTopBannersPerMaskDateRangeCounter -= 1;
  };

  fetchElementConversion = async ({ elementId, timeFrom, timeTo }, type) => {
    this.isLoadingElementConversion = true;
    const { data } = await aggregationsStore.fetchStatistics({
      timeFrom: moment(timeFrom).toISOString(),
      timeTo: moment(timeTo).toISOString(),
      step: '1D',
      statId: ELEMENTS_TYPES[type],
      banner: elementId,
    });

    if (data.length === 0) {
      this.conversionLineChartData = [];
      this.isLoadingElementConversion = false;
      return;
    }

    const dataObj = {};
    let sum = 0;
    data.forEach((item) => {
      sum += item.value;
      dataObj[item.fromTs] = item.value;
    });
    this.conversionSum = sum;
    const dateDiff = moment(data[data.length - 1].fromTs).diff(
      moment(data[0].fromTs),
      'days',
    );
    const dataObjWithZero = new Map();
    for (let i = 0; i <= dateDiff; i++) {
      const date = moment(data[0].fromTs).add(i, 'days').toISOString();
      if (dataObj[date]) {
        dataObjWithZero.set(date, dataObj[date]);
      } else {
        dataObjWithZero.set(date, 0);
      }
    }

    this.conversionLineChartData = Array.from(dataObjWithZero).map(
      ([key, value]) => {
        let date = moment(key);
        date = date.locale('pl').format('lll').toString();
        return { value, fromTs: date };
      },
    );
    this.isLoadingElementConversion = false;
  };

  fetchBanners = async (type) => {
    const url =
      type === 'landingPage' ? API_ROUTES.LANDING_PAGES : API_ROUTES.BANNERS;
    this.isLoadingBanners = true;
    try {
      const { data } = await API.get(url);
      this.banners = data.map(({ title, analyticsId, image, video }) => ({
        value: analyticsId,
        label: `${title} (${analyticsId})`,
        image,
        video,
      }));
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingBanners = false;
    }
  };

  loadBanners = async (inputValue) =>
    inputValue.length > 1
      ? this.banners
          .filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase()),
          )
          .slice(0, 1000)
      : [];

  clearConversion = () => {
    this.conversionLineChartData = [];
    this.conversionSum = 0;
  };
}

export default new BannersStore();
