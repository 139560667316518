import { action, observable } from 'mobx';
import config from '../config';
import moment from 'moment';
import 'moment/locale/pl';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import qs from 'qs';
import { orderBy, sortBy } from 'lodash';
import {
  generateColumnsForMergedCollectionPLBased,
  getProperValueBasedOnSortedCollections,
} from '../components/utils/collectionMergingUtils';

export class WishlistStore {
  @observable globalTopWishlist = [];
  @observable loadingGlobalTopWishlist = true;
  @observable topSkuIds = [];
  @observable orderedTopGlobalMonthValues = [];

  @observable columnsPerMask = [];
  @observable topProductsPerMask = [];
  @observable loadingTopProductsPerMask = true;

  @observable startDate = moment().startOf('month').toDate();
  @observable endDate = moment().toDate();

  @observable availableMasks = [];
  @observable topProductsForMask = [];
  @observable loadingTopProductsForMask = true;

  limit = 100;

  constructor() {}

  @action fetchWishListGlobalTop = async (fromDate, toDate) => {
    this.globalTopWishlist = [];
    this.loadingGlobalTopWishlist = true;

    const currentMonthQuery = {
      statId: config.statIds.wishlistSum,
      step: '1D',
      timeFrom: fromDate
        ? moment(fromDate).toString()
        : moment().startOf('month').toISOString(),
      timeTo: toDate ? moment(toDate).toString() : moment().toISOString(),
      limit: this.limit,
      key: '$object_id',
      aggregateKey: '$value',
    };

    const { data } = await API.get(
      `${API_ROUTES.STATISTICS_TOP_PRODUCTS}?${qs.stringify(
        currentMonthQuery,
      )}`,
    );

    const topSkuIds = [];

    this.globalTopWishlist = data.map((item, index) => {
      topSkuIds.push(item._id);
      item.position = index + 1;
      item.name = item.productName;
      if (item.price) {
        item.regularPrice = item.price.regularPrice.amount.value;
        item.currency = item.price.regularPrice.amount.currency;
      }
      return item;
    });
    this.topSkuIds = sortBy(topSkuIds);
    const monthValue = orderBy(this.globalTopWishlist, '_id', 'asc');
    this.orderedTopGlobalMonthValues = monthValue;
    this.loadingGlobalTopWishlist = false;
  };

  @action fetchTopProductsForMask = async (fromDate, toDate, mask) => {
    this.topProductsForMask = [];
    this.loadingTopProductsForMask = true;

    const currentMonthQuery = {
      statId: config.statIds.wishlistSumPerMask,
      step: '1D',
      timeFrom: fromDate
        ? moment(fromDate).toString()
        : moment().startOf('month').toISOString(),
      timeTo: toDate ? moment(toDate).toString() : moment().toISOString(),
      limit: this.limit,
      key: '$object_id',
      aggregateKey: '$value',
      store: mask,
    };

    const { data } = await API.get(
      `${API_ROUTES.STATISTICS_TOP_PRODUCTS}?${qs.stringify(
        currentMonthQuery,
      )}`,
    );

    const topForMask = data.map((item, index) => {
      item.position = index + 1;
      item.name = item.productName;
      if (item.price) {
        item.regularPrice = item.price.regularPrice.amount.value;
        item.currency = item.price.regularPrice.amount.currency;
      }
      return item;
    });

    this.topProductsForMask = topForMask;
    this.loadingTopProductsForMask = false;
  };

  async getMonthValuesForMask({ topSkuId, limit, fromDate, toDate }) {
    const monthQuery = {
      statId: config.statIds.wishlistSumPerMask,
      step: '1D',
      timeFrom: fromDate
        ? moment(fromDate).toString()
        : moment().startOf('month').toISOString(),
      timeTo: toDate ? moment(toDate).toString() : moment().toISOString(),
      skuIds: topSkuId.toString(),
      skuKey: '$object_id',
      limit,
    };

    const { data: topByMask } = await API.get(
      `${API_ROUTES.TOP_PRODUCTS_BY_MASK}?${qs.stringify(monthQuery)}`,
    );
    return topByMask;
  }

  @action fetchMasksData = async (fromDate, toDate) => {
    this.loadingTopProductsPerMask = true;
    this.topProductsPerMask = [];
    const topSkuIds = this.topSkuIds;

    const allMasksData = {};
    const maskIndexes = {};
    const availableMasks = [];

    const rawMaskData = await this.getMonthValuesForMask({
      topSkuId: topSkuIds,
      limit: this.limit,
      fromDate,
      toDate,
    });
    let mask = null;
    for (mask of rawMaskData) {
      availableMasks.push(mask._id);
      allMasksData[mask._id] = orderBy(mask.skuVal, 'sku', 'asc');
      maskIndexes[mask._id] = 0;
    }

    const result = [];

    for (let i = 0; i < topSkuIds.length; i++) {
      const row = {};
      availableMasks.forEach((mask) => {
        const { value, index, position } =
          getProperValueBasedOnSortedCollections(
            allMasksData[mask],
            maskIndexes[mask],
            topSkuIds,
            i,
            'sku',
          );
        row[`${mask}Value`] = value;
        row[`${mask}Position`] = position + 1;
        maskIndexes[mask] = index;
      });
      row.productName = this.orderedTopGlobalMonthValues[i].name;
      row.sku = this.orderedTopGlobalMonthValues[i]._id;
      result.push(row);
    }

    if (availableMasks.indexOf('pl' >= 0)) {
      this.topProductsPerMask = orderBy(
        result,
        'four_fstore_plPosition',
        'asc',
      );
    } else {
      this.topProductsPerMask = result;
    }

    this.availableMasks = availableMasks;
    this.columnsPerMask = generateColumnsForMergedCollectionPLBased(
      availableMasks,
      'Ilość',
      this.limit,
    );
    this.loadingTopProductsPerMask = false;
  };

  @observable setEndDate = (date) => {
    this.endDate = date;
  };

  @observable setStartDate = (date) => {
    this.startDate = date;
  };

  @action fetchAll = async (startDate, endDate) => {
    await this.fetchWishListGlobalTop(startDate, endDate);
    await this.fetchMasksData(startDate, endDate);
  };
}

export default new WishlistStore();
