import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import VerticalBarChart from './VerticalBarChart';
import { Filters } from '../../../components/helpers/chartHelpers';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import moment from 'moment';
import { Label, LabelsContainer } from './VerticalBarChartLabel';
import GreenCheckbox from '../../../components/GreenCheckbox';

const keyNames = ['sum', 'shadow'];
const keyTranslations = ['Ilość transakcji na maskę', 'W zeszłym miesiącu'];
const keyColors = ['#272727', '#868686'];

const PurchasesPerMaskChart = inject('purchaseStore')(
  observer(({ purchaseStore }) => {
    const {
      loadingPurchasesPerMask,
      purchasesPerMask,
      fetchPurchasesPerMask,
      shortcutFrom,
      shortcutTo,
      setButtonDate,
    } = purchaseStore;
    const [startDate, setStartDate] = useState(
      moment().startOf('month').toDate(),
    );
    const [endDate, setEndDate] = useState(moment().toDate());
    const [onlyLoggedIn, setOnlyLoggedIn] = useState(false);

    useEffect(() => {
      fetchPurchasesPerMask(startDate, endDate, onlyLoggedIn);
    }, [startDate, endDate, onlyLoggedIn]);

    useEffect(() => {
      if (shortcutFrom && shortcutTo) {
        setStartDate(shortcutFrom);
        setEndDate(shortcutTo);
      }
    }, [shortcutFrom, shortcutTo]);

    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '100%' }}>
            <VerticalBarChart
              loadingData={loadingPurchasesPerMask}
              chartData={purchasesPerMask}
              keyNames={keyNames}
              dataKey="_id"
              keyTranslations={keyTranslations}
              keyColors={keyColors}
            />
          </div>
          <LabelsContainer width={120}>
            {purchasesPerMask.length > 0 &&
              purchasesPerMask.map((item, index) => (
                <Label key={index}>
                  {item.sum}&nbsp;
                  <span style={{ color: '#868686' }}>({item.shadow})</span>
                </Label>
              ))}
          </LabelsContainer>
        </div>
        <div style={{ width: '55%', margin: 'auto' }}>
          <Filters>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onEndDateChange={(value) => {
                setEndDate(value);
                setButtonDate(null);
              }}
              onStartDateChange={(value) => {
                setStartDate(value);
                setButtonDate(null);
              }}
              showTime={false}
            />
          </Filters>
          <>
            Pokaż tylko dla zalogowanych użytkowników:
            <GreenCheckbox
              checked={onlyLoggedIn}
              onChange={(event) => setOnlyLoggedIn(event.target.checked)}
            />
          </>
        </div>
      </>
    );
  }),
);

export default PurchasesPerMaskChart;

PurchasesPerMaskChart.propTypes = {};
