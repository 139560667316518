import React from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import TableWithJson from '../../../components/TableWithJson/TableWithJson';
import { currencyFormatter } from '../../../components/helpers/utils';

const columns = [
  { id: 'position', label: 'Pozycja', minWidth: 100 },
  { id: 'sku', label: 'SKU', minWidth: 100 },
  { id: 'productName', label: 'Nazwa', minWidth: 100 },
  { id: 'todayAmount', label: 'Dzisiaj (ilość)', minWidth: 100 },
  {
    id: 'todayValue',
    label: 'Dzisiaj (wartość)',
    minWidth: 100,
    format: currencyFormatter.format,
  },
  { id: 'yesterdayAmount', label: 'Wczoraj (ilość)', minWidth: 100 },
  {
    id: 'yesterdayValue',
    label: 'Wczoraj (wartość)',
    minWidth: 100,
    format: currencyFormatter.format,
  },
  { id: 'weekAmount', label: 'Tydzień (ilość)', minWidth: 100 },
  {
    id: 'weekValue',
    label: 'Tydzień (wartość)',
    minWidth: 100,
    format: currencyFormatter.format,
  },
  { id: 'monthAmount', label: 'Miesiąc (ilość)', minWidth: 100 },
  {
    id: 'monthValue',
    label: 'Miesiąc (wartość)',
    minWidth: 100,
    format: currencyFormatter.format,
  },
];

function TopProductsGlobalTable({ productsStore }) {
  const { topProductsGlobal, loadingTopProductsGlobal } = productsStore;

  return (
    <TableWithJson
      columns={columns}
      data={topProductsGlobal}
      loadingData={loadingTopProductsGlobal}
      withPagination={false}
      showJson={false}
    />
  );
}

export default inject('productsStore')(observer(TopProductsGlobalTable));

TopProductsGlobalTable.propTypes = {
  productsStore: PropTypes.shape({
    topProductsGlobal: PropTypes.array,
    loadingTopProductsGlobal: PropTypes.bool,
  }),
};
