import { action, observable } from 'mobx';
import config from '../config';
import moment from 'moment';
import 'moment/locale/pl';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import qs from 'qs';

export class DashboardElementsStore {
  @observable tableData = [];
  @observable isLoadingTableData = false;
  @observable skuOptions = [];
  @observable isLoadingSkuOptions = false;
  @observable appStoriesOptions = [];
  @observable isLoadingAppStoriesOptions = false;

  limit = 100;

  @action
  fetchDistinct = async ({ timeFrom, timeTo, collectionKey, distinctKey }) => {
    if (distinctKey === 'sku') {
      this.isLoadingSkuOptions = true;
    } else {
      this.isLoadingAppStoriesOptions = true;
    }
    this[collectionKey] = [];

    const currentYearQuery = {
      statId: config.statIds.appStoryClicksPerProduct,
      step: '1D',
      timeFrom: moment(timeFrom).startOf('day').toISOString(),
      timeTo: moment(timeTo).endOf('day').toISOString(),
      key: distinctKey,
    };

    try {
      const { data } = await API.get(
        `${API_ROUTES.STATISTIC_DISTINCT_KEY}?${qs.stringify(
          currentYearQuery,
        )}`,
      );

      this[collectionKey] = data;
    } catch (e) {
      console.log(e);
    } finally {
      if (distinctKey === 'sku') {
        this.isLoadingSkuOptions = false;
      } else {
        this.isLoadingAppStoriesOptions = false;
      }
    }
  };

  @action
  fetchTopByKey = async ({ timeFrom, timeTo, sku, appStoryId }) => {
    this.isLoadingTableData = true;

    const query = {
      statId: config.statIds.appStoryClicksPerProduct,
      step: '1D',
      timeFrom: moment(timeFrom).startOf('day').toISOString(),
      timeTo: moment(timeTo).endOf('day').toISOString(),
      groupingKey: 'sku,referer_id',
      aggregateKey: '$value',
      limit: this.limit,
      params: {},
    };

    if (sku) {
      query.params.sku = sku;
    }

    if (appStoryId) {
      query.params.referer_id = appStoryId;
    }

    try {
      let { data: data } = await API.get(
        `${API_ROUTES.STATISTICS_TOP}?${qs.stringify(query)}`,
      );

      const appStoriesIds = data.map(
        ({ _id: { referer_id: appStoryId } }) => appStoryId,
      );

      const { data: banners } = await API.get(
        `${API_ROUTES.BANNERS}?${qs.stringify({ bannerIds: appStoriesIds })}`,
      );

      const bannersObj = {};
      banners.forEach(({ title, analyticsId, image }) => {
        bannersObj[analyticsId] = { title, image };
      });

      this.tableData = data.map(
        ({ _id: { sku, referer_id: appStoryId }, value }) => {
          let item = {
            sku,
            appStoryId,
            value,
          };

          if (bannersObj[appStoryId]) {
            item.title = bannersObj[appStoryId].title || '-';
            item.image = bannersObj[appStoryId].image || '-';
          }
          return item;
        },
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingTableData = false;
    }
  };
}

export default new DashboardElementsStore();
