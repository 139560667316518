import { BannerButton } from './styles';
import { ImageButton } from './Components/ImagePopup/ImageButton';
import React from 'react';
import moment from 'moment';
import { percentFormatter } from '../../components/helpers/utils';

export const genderOptions = [
  { label: 'Kobieta', value: 'female' },
  { label: 'Mężczyzna', value: 'male' },
  { label: 'Dziewczyna', value: 'girls' },
  { label: 'Chłopak', value: 'boys' },
  { label: 'Dla każdego', value: 'all' },
];

export const timeRangeOptions = [
  { label: 'Bieżący miesiąc', value: 'month' },
  { label: 'Bieżący tydzień', value: 'week' },
  { label: 'Zakres użytkownika', value: 'custom' },
];

export const timeRanges = {
  month: {
    startDate: moment().startOf('month').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  },
  week: {
    startDate: moment().startOf('week').startOf('day').toDate(),
    endDate: moment().endOf('week').toDate(),
  },
};

export const columnsFunc = ({
  setConversionChosenBanner,
  setImageOpen,
  setImageUrl,
  setVideoUrl,
}) => [
  { id: 'position', label: 'Pozycja', minWidth: 100 },
  {
    id: 'analyticsId',
    label: 'ID',
    minWidth: 100,
    format: (value) => (
      <BannerButton
        onClick={() => {
          setConversionChosenBanner(value);
          document
            .getElementById('conversion-chart-select')
            .scrollIntoView({ behavior: 'smooth' });
        }}
      >
        {value}
      </BannerButton>
    ),
  },
  { id: 'title', label: 'Nazwa', minWidth: 100 },
  {
    id: 'store',
    label: 'Maska',
    minWidth: 100,
    format: (value) => (value !== '-' ? value.name : value),
  },
  {
    id: 'mainImage',
    label: 'Obrazek',
    minWidth: 100,
    format: (value) =>
      value && value !== '-' ? (
        <ImageButton
          setImageOpen={setImageOpen}
          setImageUrl={setImageUrl}
          image={value}
        />
      ) : (
        '(Brak obrazka)'
      ),
  },
  {
    id: 'video',
    label: 'Wideo',
    minWidth: 100,
    format: (value) =>
      value && value !== '-' ? (
        <ImageButton
          setImageOpen={setImageOpen}
          setImageUrl={setImageUrl}
          setVideoUrl={setVideoUrl}
          image={value.thumb}
          video={value.url}
        />
      ) : (
        '(Brak wideo)'
      ),
  },
  { id: 'todayValue', label: 'Dzisiaj', minWidth: 100 },
  { id: 'yesterdayValue', label: 'Wczoraj', minWidth: 100 },
  { id: 'weekValue', label: 'Ostatnie 7 dni', minWidth: 100 },
  { id: 'monthValue', label: 'Zakres', minWidth: 100 },
  { id: 'shadowValue', label: 'Poprzedni okres', minWidth: 100 },
];

export const compareColumnsFunc = ({
  setConversionChosenBanner,
  setImageOpen,
  setImageUrl,
  setVideoUrl,
}) => [
  { id: 'valuePosition', label: 'Pozycja zakres I', minWidth: 100 },
  {
    id: 'secondDateRangeValuePosition',
    label: 'Pozycja zakres II',
    minWidth: 100,
  },
  {
    id: 'analyticsId',
    label: 'ID',
    minWidth: 100,
    format: (value) => (
      <BannerButton
        onClick={() => {
          setConversionChosenBanner(value);
          document
            .getElementById('conversion-chart-select')
            .scrollIntoView({ behavior: 'smooth' });
        }}
      >
        {value}
      </BannerButton>
    ),
  },
  { id: 'title', label: 'Nazwa', minWidth: 100 },
  {
    id: 'store',
    label: 'Maska',
    minWidth: 100,
    format: (value) => (value !== '-' ? value.name : value),
  },
  {
    id: 'mainImage',
    label: 'Obrazek',
    minWidth: 100,
    format: (value) =>
      value && value !== '-' ? (
        <ImageButton
          setImageOpen={setImageOpen}
          setImageUrl={setImageUrl}
          setVideoUrl={() => {}}
          image={value}
        />
      ) : (
        '(Brak obrazka)'
      ),
  },
  {
    id: 'video',
    label: 'Wideo',
    minWidth: 100,
    format: (value) =>
      value && value !== '-' ? (
        <ImageButton
          setImageOpen={setImageOpen}
          setImageUrl={setImageUrl}
          setVideoUrl={setVideoUrl}
          image={value.thumb}
          video={value.url}
        />
      ) : (
        '(Brak wideo)'
      ),
  },
  { id: 'value', label: 'Ilość zakres I', minWidth: 100 },
  { id: 'secondDateRangeValue', label: 'Ilość zakres II', minWidth: 100 },
  {
    id: 'valuePercent',
    label: 'Udział zakres I',
    minWidth: 100,
    format: (value) => percentFormatter.format(value),
  },
  {
    id: 'secondDateRangeValuePercent',
    label: 'Udział zakres II',
    minWidth: 100,
    format: (value) => percentFormatter.format(value),
  },
];
