export default {
  statIds: {
    usersCount: '60803a0a6996f3d8e281dd94',
    usersUniquePageViewCount: '6102833fe821372b5def2fa0',
    usersByMediumByPageViewCount: '60803a0b6996f3d8e281dd98',
    usersByGenderByPageViewCount: '608bef5d6a0d422ce50122c6',
    activePushCount: '608039bd6996f3d8e281dd93',
    purchasesCount: '60803a0a6996f3d8e281dd95',
    purchasesWithVoucherCount: '60803a0a6996f3d8e281dd96',
    bannerCount: '61b746e93b303542e985aaec',
    bannerCountPerBanner: '61b746e93b303542e985aaf8',
    bannerCountPerBannerPerMask: '61b746e93b303542e985ab02',
    purchasesTotalPriceSum: '60cc5e9b146b11d780222b10',
    purchasesTotalPriceSumPerMask: '60cc5e9b146b11d780222b13',
    purchasesCountPerMask: '60cc5e9b146b11d780222b16',
    purchasesAvgTotalPricePerMask: '60cc9b54146b11d7802e406b',
    discountsSum: '60e44c9fc6ac832fdf451604',
    discountsSumPerMask: '60e44c9fc6ac832fdf45160f',
    productsCountPerMaskPerSku: '60eedccaaa2dd4fccd605c97',
    productsValuesPerMaskPerSku: '60eedccaaa2dd4fccd605ca2',
    wishlistSum: '60e5ad0966bd536377b3ef66',
    wishlistSumPerMask: '60e5ad0966bd536377b3ef71',
    churnCount: '611e79754d8e60a75b2ff26b',
    monthDevicesDistinct: '611f86784d8e60a75b61cfd2',
    purchasesPerPaymentType: '61ee8f5cbde689ed7677a570',
    purchasesPerPaymentProvider: '61ee8f5cbde689ed7677a579',
    purchasesPerDeliveryType: '61ee8f5cbde689ed7677a583',
    purchasesPerMaskPaymentTypePaymentProvider: '622f08b3db50261c791113df',
    activePPGCount: '6321ed0a95b103fc22430f08',
    purchasesValuePerMaskPaymentTypePaymentProvider: '63b2fd00b6ba0b4f65d51c8e',
    bannersConversion: 'no-value',
    appStoryConversion: 'no-value',
    searchPhrasesCount: 'no-value',
    searchPhrasesCountPerStore: 'no-value',
    searchPhrasesWithNextStep: 'no-value',
    appStoryClicksPerProduct: '64d27e18c137ce01fa07578d',
    inboxClicks: '65671b886ab842a4d933207c',
    weatherHints: '658043bf67bcf03ca9daf07a',
    landingPageConversion: '660ed2e89d6cdbb3b42a26e3',
    landingPagesClicks: '660ed2e89d6cdbb3b42a26e4',
    couponPoolsCount: '662b9b46cc3fb7cc18f04163',
    activationCodesCount: '66449c696c22ff7f26ad3ff9',
    consentScreenDisplayedCount: '664c96b95160588fa2ebbcb4',
    consentSelectAllCount: '664c96b95160588fa2ebbcb5',
    purchasesWithRecommendationOriginAll: '668fc069bbdbae2e4427baad',
    purchasesValueWithRecommendationOriginAll: '668fc069bbdbae2e4427baae',
    recommendationsPerOrigin: '668fc069bbdbae2e4427baaf',
    recommendationsPerOriginAndSKU: '668fc069bbdbae2e4427bab0',
    purchasesPerRecommendationOriginGrouped: '668fc069bbdbae2e4427bab1',
    purchasesValuePerRecommendationOriginGrouped: '668fc069bbdbae2e4427bab2',
    questionnairePageViews: '66a20f740f41dfc760a696f0',
    questionnaireAnswers: '66a20f740f41dfc760a696f1',
    uniqueUsersDaily: '66acb8762d66622c45dada90',
    reallyUniqueUsersDaily: '66d1a7dae876830df582127e',
    uniqueUsersAtLeast3events: '66e01e2767ac1401ea4a6e8d',
    uniqueUsersAtLeast3eventsByMedium: '66e01e2767ac1401ea4a6e8e',
    churnAtLeast3events: '66e01e2767ac1401ea4a6e8f',
    monthDevicesDistinct3Events: '66e01e2767ac1401ea4a6e90',
    cancelledOrders: '66fe848a9aadf6c51edc893d',
    purchasesCountPerMaskLogged: '6729fe18865aff7621fb8da2',
    purchasesAvgTotalPricePerMaskLogged: '6729fe29865aff7621fb8da3',
    usersWithPurchases: '6772ac846617ff48d9fb8da2',
    abandonedCarts: '6772ac846617ff48d9fb8da3',
  },
};
