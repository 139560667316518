import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import CustomLineChart from '../../../components/CustomLineChart/CustomLineChart';
import { currencyFormatter } from '../../../components/helpers/utils';
import moment from 'moment';
import MaskSelectDateRange from './MaskSelectDateRange';

const colors = ['#272727', '#868686'];
const keys = ['value', 'shadow'];
const keysTranslation = ['sprzedaż', 'miesiąc temu'];
const xAxis = 'fromTs';

const PurchaseDailyLineChart = inject('purchaseStore')(
  observer(({ purchaseStore }) => {
    const {
      loadingPurchaseDailyData,
      purchaseDaily,
      fetchPurchaseDaily,
      fetchMasksForDateRange,
      loadingAvailableMasksDateRange,
      availableMasksDateRange,
    } = purchaseStore;
    const [startDate, setStartDate] = useState(
      moment().startOf('month').toDate(),
    );
    const [endDate, setEndDate] = useState(moment().toDate());
    const [chosenMask, setChosenMask] = useState('Wszystkie');
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
      if (startDate && endDate && isMounted) {
        fetchPurchaseDaily(startDate, endDate, chosenMask);
        fetchMasksForDateRange(startDate, endDate);
      }
    }, [startDate, endDate, chosenMask, isMounted]);

    useEffect(() => {
      setIsMounted(true);
    }, []);

    return (
      <>
        <CustomLineChart
          loadingData={loadingPurchaseDailyData}
          data={purchaseDaily}
          xAxisKey={xAxis}
          dataKeys={keys}
          keysTranslation={keysTranslation}
          keysColors={colors}
          valueFormatter={(value) => currencyFormatter.format(value)}
        />
        <div style={{ width: '50%', margin: 'auto' }}>
          <MaskSelectDateRange
            chosenMask={chosenMask}
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            masks={availableMasksDateRange}
            loadingMasks={loadingAvailableMasksDateRange}
            setMask={setChosenMask}
          />
        </div>
      </>
    );
  }),
);

export default PurchaseDailyLineChart;

PurchaseDailyLineChart.propTypes = {
  purchaseStore: PropTypes.shape({
    purchaseDaily: PropTypes.array,
    loadingPurchaseDailyData: PropTypes.bool,
    fetchPurchaseDaily: PropTypes.func,
    fetchMasksForDateRange: PropTypes.func,
  }),
};
