import React from 'react';
import * as PropTypes from 'prop-types';
import { FilterBox, StyledInput } from '../helpers/chartHelpers';
import PolishDatePicker from '../PolishDatePicker/PolishDatePicker';

export default function DateRangePicker({
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
  showTime = true,
  containerClassName,
  minDateFrom,
  maxDateTo,
}) {
  return (
    <>
      <FilterBox className={containerClassName}>
        Od:
        <PolishDatePicker
          showTimeSelect={showTime}
          dateFormat={showTime ? 'dd/MM/yyyy h:mm aa' : 'dd/MM/yyyy'}
          selected={startDate}
          onChange={onStartDateChange}
          customInput={<StyledInput />}
          minDate={minDateFrom}
          maxDate={endDate}
        />
      </FilterBox>
      <FilterBox className={containerClassName}>
        Do:
        <PolishDatePicker
          showTimeSelect={showTime}
          dateFormat={showTime ? 'dd/MM/yyyy h:mm aa' : 'dd/MM/yyyy'}
          selected={endDate}
          onChange={onEndDateChange}
          customInput={<StyledInput />}
          minDate={startDate}
          maxDate={maxDateTo}
        />
      </FilterBox>
    </>
  );
}

DateRangePicker.propTypes = {
  startDate: PropTypes.any,
  onStartDateChange: PropTypes.func,
  endDate: PropTypes.any,
  onEndDateChange: PropTypes.func,
  showTime: PropTypes.bool,
  containerClassName: PropTypes.string,
  minDateFrom: PropTypes.any,
  maxDateTo: PropTypes.any,
};
