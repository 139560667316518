import React from 'react';
import PropTypes from 'prop-types';
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  LineChart,
  ResponsiveContainer,
} from 'recharts';
import { showLoaderOrPlaceHolder } from '../helpers/chartHelpers';

export default function CustomLineChart({
  loadingData,
  data,
  xAxisKey,
  dataKeys,
  keysTranslation,
  keysColors,
  valueFormatter,
  withBrush = true,
  domain = [0, 'auto'],
  yTickFormatter,
  labelFormatter,
}) {
  if (loadingData || data.length === 0) {
    return (
      <ResponsiveContainer width="100%" height={300}>
        {showLoaderOrPlaceHolder(loadingData)}
      </ResponsiveContainer>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="4 3" stroke="rgba(0,0,0,.1)" />
        <XAxis dataKey={xAxisKey} stroke="#aaaaaa" />
        <YAxis
          tickFormatter={yTickFormatter}
          stroke="#aaaaaa"
          domain={domain}
        />
        <Tooltip formatter={valueFormatter} labelFormatter={labelFormatter} />
        {dataKeys.map((key, index) => (
          <Line
            key={`${key}-${index}`}
            dataKey={key}
            name={keysTranslation[index]}
            stroke={keysColors[index]}
          />
        ))}
        {withBrush && <Brush dataKey={xAxisKey} stroke="#212121" />}
        <Legend wrapperStyle={{ paddingTop: '10px' }} />
      </LineChart>
    </ResponsiveContainer>
  );
}

CustomLineChart.propTypes = {
  data: PropTypes.array,
  xAxisKey: PropTypes.string,
  dataKeys: PropTypes.array,
  keysTranslation: PropTypes.array,
  keysColors: PropTypes.array,
  loadingData: PropTypes.bool,
  withBrush: PropTypes.bool,
  valueFormatter: PropTypes.func,
  yTickFormatter: PropTypes.func,
  domain: PropTypes.array,
  labelFormatter: PropTypes.func,
};
