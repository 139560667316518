import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/logos/logo_4f.png';
import { Link, useLocation } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { APP_ROUTES } from '_app/routes';

const newAppUrl = process.env.REACT_APP_NEW_APP_URL;

const TABS = [
  { path: APP_ROUTES.USERS, label: 'Użytkownicy' },
  { path: APP_ROUTES.SALES, label: 'Sprzedaż' },
  {
    path: `${newAppUrl}${APP_ROUTES.SALES}`,
    label: 'Sprzedaż V2',
    external: true,
  },
  { path: APP_ROUTES.PRODUCTS, label: 'Produkty' },
  { path: APP_ROUTES.DISCOUNTS, label: 'Rabaty' },
  { path: APP_ROUTES.PERSON, label: 'Persona' },
  { path: APP_ROUTES.BANNERS, label: 'Banery' },
  { path: APP_ROUTES.ELEMENTS, label: 'Elementy' },
  { path: APP_ROUTES.STORES, label: 'Sklepy' },
  { path: APP_ROUTES.LANDING_PAGES, label: 'Landing Pages' },
  { path: APP_ROUTES.WISHLIST, label: 'Ulubione' },
  { path: APP_ROUTES.PURCHASE, label: 'Zakupy' },
  { path: APP_ROUTES.NOTIFICATIONS, label: 'Notyfikacje' },
  { path: APP_ROUTES.RECOMMENDATIONS, label: 'Rekomendacje' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#212121',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    margin: '20px 10px',
    fontSize: '12px',
    color: 'grey',
  },
  pickedLink: {
    color: 'white',
  },
  appBar: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: '#212121',
  },
  navBox: {
    width: '100%',
    paddingRight: 0,
  },
  authBox: {
    paddingLeft: 0,
  },
}));

const NavBar = ({ authStore: { isAuthenticated, logout } }) => {
  const classes = useStyles();

  const { pathname } = useLocation();

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar className={classes.navBox}>
          <Typography variant="subtitle2" className={classes.title}>
            <Link to={APP_ROUTES.HOME}>
              <img src={logo} alt="4f Analytics" />
            </Link>
          </Typography>
          {isAuthenticated &&
            TABS.map((item) => (
              <Typography
                key={`tab-item-${item.path}`}
                variant="subtitle2"
                className={`${classes.title} ${
                  pathname.startsWith(item.path) ? classes.pickedLink : ''
                }`}
              >
                {item.external ? (
                  <a style={{ color: 'inherit' }} href={item.path}>
                    {item.label}
                  </a>
                ) : (
                  <Link style={{ color: 'inherit' }} to={item.path}>
                    {item.label}
                  </Link>
                )}
              </Typography>
            ))}
        </Toolbar>
        <Toolbar className={classes.authBox}>
          {isAuthenticated && (
            <Typography variant="subtitle2" className={classes.title}>
              <Link
                onClick={logout}
                to={APP_ROUTES.LOGIN}
                style={{ color: 'inherit' }}
              >
                Wyloguj
              </Link>
            </Typography>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

NavBar.propTypes = {
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    logout: PropTypes.func,
  }),
};

export default inject('authStore')(observer(NavBar));
