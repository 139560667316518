import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import CustomLineChart from '../../../components/CustomLineChart/CustomLineChart';
import moment from 'moment';
import { StyledInput } from '../../../components/helpers/chartHelpers';
import { percentFormatter } from '../../../components/helpers/utils';
import GridItem from '../../../components/Grid/GridItem';
import PolishDatePicker from '../../../components/PolishDatePicker/PolishDatePicker';

const colors = ['#272727'];
const keys = ['churnPercent'];
const keysTranslation = ['churn'];
const xAxis = 'fromTs';

const ChurnLineChart = inject('usersStore')(
  observer(({ usersStore }) => {
    const { loadingChurnData, churnData, fetchChurnData } = usersStore;
    const [startDate, setStartDate] = useState(
      moment().startOf('month').toDate(),
    );
    const [endDate, setEndDate] = useState(moment().toDate());

    useEffect(() => {
      if (startDate && endDate) {
        fetchChurnData(startDate, endDate);
      }
    }, [startDate, endDate]);
    return (
      <>
        <CustomLineChart
          loadingData={loadingChurnData}
          data={churnData}
          xAxisKey={xAxis}
          dataKeys={keys}
          keysTranslation={keysTranslation}
          keysColors={colors}
          valueFormatter={(value, name, props) =>
            `${percentFormatter.format(value / 100)} (${props.payload.lost} z ${
              props.payload.value
            })`
          }
        />
        <GridItem
          xs={6}
          style={{
            textAlign: 'right',
            paddingRight: '75px',
            marginTop: '30px',
          }}
        >
          <div>
            Od:
            <PolishDatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<StyledInput />}
              maxDate={endDate}
            />
          </div>
        </GridItem>
        <GridItem
          xs={6}
          style={{ textAlign: 'left', paddingLeft: 0, marginTop: '30px' }}
        >
          <div>
            Do:
            <PolishDatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              customInput={<StyledInput />}
              minDate={startDate}
            />
          </div>
        </GridItem>
      </>
    );
  }),
);

export default ChurnLineChart;

ChurnLineChart.propTypes = {};
