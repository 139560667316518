import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import * as stores from './stores';
import { Provider } from 'mobx-react';
import history from './_app/history';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { plPL } from '@material-ui/core/locale';

import 'assets/scss/material-kit-react.scss?v=1.8.0';

// pages for this product
import Footer from './components/Footer/Footer';
import NavBar from './components/NavBar/NavBar';
import UsersPageContainer from './views/UsersPage';
import LoginPageContainer from './views/LoginPage';
import PrivateRoute from './components/PrivateRoute';
import SalesPageContainer from './views/SalesPage';
import DiscountsPageContainer from './views/DiscountsPage';
import PersonPageContainer from './views/PersonPage';
import StoresPageContainer from './views/StoresPage';
import WishlistsPageContainer from './views/WishlistsPage';
import './assets/css/index.css';
import RollbarError from './components/RollbarError/RollbarError';
import ErrorPageContainer from './views/ErrorPage';
import BannersPageContainer from './views/BannersPage';
import ProductsPageContainer from './views/ProductsPage';
import { APP_ROUTES } from '_app/routes';
import DashboardElementsPage from './views/DashboardElementsPage';
import PurchasePageContainer from './views/PurchasePage';
import NotificationsPage from './views/NotificationsPage';
import LandingPagesPage from './views/LandingPagesPage';
import RecommendationsPageContainer from './views/RecommendationsPage';
const theme = createMuiTheme({}, plPL);

ReactDOM.render(
  <RollbarError history={history}>
    <Provider {...stores}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <div
            style={{
              backgroundColor: '#e0e0e0',
              minHeight: 'calc(100vh - 70px)',
              display: 'block',
              position: 'relative',
            }}
          >
            <NavBar />
            <Switch>
              <Route path={APP_ROUTES.LOGIN} component={LoginPageContainer} />
              <PrivateRoute
                exact
                path={APP_ROUTES.HOME}
                component={() => <Redirect to={APP_ROUTES.USERS} />}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.USERS}
                component={UsersPageContainer}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.SALES}
                component={SalesPageContainer}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.DISCOUNTS}
                component={DiscountsPageContainer}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.PERSON}
                component={PersonPageContainer}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.STORES}
                component={StoresPageContainer}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.WISHLIST}
                component={WishlistsPageContainer}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.BANNERS}
                component={BannersPageContainer}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.ERROR}
                component={ErrorPageContainer}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.PRODUCTS}
                component={ProductsPageContainer}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.ELEMENTS}
                component={DashboardElementsPage}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.PURCHASE}
                component={PurchasePageContainer}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.NOTIFICATIONS}
                component={NotificationsPage}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.LANDING_PAGES}
                component={LandingPagesPage}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.RECOMMENDATIONS}
                component={RecommendationsPageContainer}
              />
            </Switch>
          </div>
          <Footer />
        </ThemeProvider>
      </Router>
    </Provider>
  </RollbarError>,
  document.getElementById('root'),
);
