import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import TableWithJson from 'components/TableWithJson/TableWithJson';
import { Filters, FlexRow } from '../styles';

const couponPoolsColumns = [
  { id: 'couponPool', label: 'Pula kuponów', minWidth: 100 },
  { id: 'value', label: 'Liczba wykorzystanych kuponów', minWidth: 100 },
];

function CouponPoolsCountTable({
  couponsStore: {
    fetchCouponPoolsCount,
    isLoadingCouponPoolsCount,
    couponPoolsCount,
  },
  shortcutFrom,
  shortcutTo,
}) {
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());

  useEffect(() => {
    if (shortcutFrom) {
      setStartDate(shortcutFrom);
    }
    if (shortcutTo) {
      setEndDate(shortcutTo);
    }
  }, [shortcutFrom, shortcutTo]);

  useEffect(() => {
    fetchCouponPoolsCount({
      from: startDate,
      to: endDate,
    });
  }, [startDate, endDate]);

  return (
    <>
      <Filters className="no-horizontal-margin">
        <FlexRow>
          <DateRangePicker
            startDate={startDate}
            onStartDateChange={(date) => setStartDate(date)}
            endDate={endDate}
            onEndDateChange={(date) => setEndDate(date)}
          />
        </FlexRow>
      </Filters>
      <TableWithJson
        columns={couponPoolsColumns}
        data={couponPoolsCount}
        loadingData={isLoadingCouponPoolsCount}
        withPagination={false}
        showJson={false}
      />
    </>
  );
}

export default inject('couponsStore')(observer(CouponPoolsCountTable));

CouponPoolsCountTable.propTypes = {
  couponsStore: PropTypes.shape({
    fetchCouponPoolsCount: PropTypes.func,
    couponPoolsCount: PropTypes.array,
    isLoadingCouponPoolsCount: PropTypes.bool,
  }),
  shortcutFrom: PropTypes.any,
  shortcutTo: PropTypes.any,
};
