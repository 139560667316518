import React, { useEffect, useState } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import {
  showLoaderOrPlaceHolder,
  Filters,
  FilterBox,
  StyledInput,
} from '../../../components/helpers/chartHelpers';
import PolishDatePicker from '../../../components/PolishDatePicker/PolishDatePicker';
import { percentFormatter } from '../../../components/helpers/utils';
import InfoIcon from '@material-ui/icons/Info';
import TooltipUI from '@material-ui/core/Tooltip';

const COLORS = ['#0088FE', '#00C49F', '#b2862e', '#c26032'];

const GenderPieChart = inject('personaStore')(
  observer(({ personaStore }) => {
    const [startDate, setStartDate] = useState(
      moment().subtract(1, 'day').startOf('day').toDate(),
    );
    const [endDate, setEndDate] = useState(moment().endOf('day').toDate());
    const {
      genderPieChartData,
      loadingGenderPieChartData,
      fetchGenderPieChartData,
    } = personaStore;

    useEffect(() => {
      fetchGenderPieChartData(startDate.toISOString(), endDate.toISOString());
    }, [startDate, endDate]);

    const tooltipFormatter = (value, name, props) => {
      const percent = percentFormatter.format(props.payload.payload.percent);
      const tooltipValue = `${value} (${percent})`;
      return [tooltipValue, name];
    };

    const renderLabel = ({ tooltipPayload }) => {
      const { label, value, percent } = tooltipPayload[0].payload;
      const percentFormatted = percentFormatter.format(percent);
      return `${label}: ${value} (${percentFormatted})`;
    };

    return (
      <>
        <ResponsiveContainer width="100%" height={300}>
          {loadingGenderPieChartData || genderPieChartData.length === 0 ? (
            showLoaderOrPlaceHolder(loadingGenderPieChartData)
          ) : (
            <PieChart width={400} height={400}>
              <Tooltip formatter={tooltipFormatter} />
              <Legend wrapperStyle={{ paddingBottom: '10px' }} />
              <Pie
                data={genderPieChartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                label={renderLabel}
                fill="#8884d8"
                dataKey="value"
                nameKey="label"
              >
                {genderPieChartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          )}
        </ResponsiveContainer>
        <Filters>
          <FilterBox>
            Od:
            <PolishDatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<StyledInput />}
              maxDate={endDate}
            />
          </FilterBox>
          <FilterBox>
            Do:
            <PolishDatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              customInput={<StyledInput />}
              minDate={startDate}
            />
            <TooltipUI
              style={{ paddingTop: 3, marginLeft: 20 }}
              title="Podział użytkowników ze względu na płeć, na podstawie zdarzeń typu pageview (stosunek występowania danej płci do użytkowników z wypełnioną płcią)"
            >
              <InfoIcon htmlColor="#212121" />
            </TooltipUI>
          </FilterBox>
        </Filters>
      </>
    );
  }),
);

export default GenderPieChart;

GenderPieChart.propTypes = {};
