import React, { useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import TableWithJson from '../../../components/TableWithJson/TableWithJson';
import { MediaPopup } from './ImagePopup/MediaPopup';
import { SelectContainer, Filters, ShadowDatesContainer } from '../styles';
import Select from 'react-select';
import {
  columnsFunc,
  genderOptions,
  timeRangeOptions,
  timeRanges,
} from '../constants';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';

function TopCategoriesGlobalTable({ bannersStore }) {
  const [startDate, setStartDate] = useState(
    moment().startOf('month').startOf('day').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());
  const [imageOpen, setImageOpen] = useState(false);
  const [genderType, setGenderType] = useState('');
  const [timeRange, setTimeRange] = useState(timeRangeOptions[0]);
  const [imageUrl, setImageUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const {
    loadingTopBannersGlobal,
    topBannersGlobal,
    setConversionChosenBanner,
    fetchBannersTop,
    shadowDateFrom,
    shadowDateTo,
  } = bannersStore;

  useEffect(() => {
    fetchBannersTop({
      genderType: genderType ? genderType.value : undefined,
      endDate,
      startDate,
    });
  }, [genderType, startDate, endDate]);

  const columns = useMemo(() => {
    return columnsFunc({
      setConversionChosenBanner,
      setImageOpen,
      setImageUrl,
      setVideoUrl,
    });
  }, []);

  useEffect(() => {
    if (timeRange.value !== 'custom') {
      setStartDate(timeRanges[timeRange.value].startDate);
      setEndDate(timeRanges[timeRange.value].endDate);
    }
  }, [timeRange]);

  return (
    <>
      <Filters>
        <SelectContainer width={220} margin="0">
          <Select
            isClearable
            placeholder="Wybierz typ"
            value={genderType}
            name="type"
            options={genderOptions}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            onChange={(option) => {
              setGenderType(option);
            }}
          />
        </SelectContainer>
        <SelectContainer width={250} margin="0">
          <Select
            placeholder="Wybierz domyślny zakres"
            value={timeRange}
            name="type"
            options={timeRangeOptions}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            onChange={(option) => {
              setTimeRange(option);
            }}
          />
        </SelectContainer>
        <DateRangePicker
          containerClassName="small"
          startDate={startDate}
          onStartDateChange={(date) => {
            setStartDate(date);
            setTimeRange(timeRangeOptions[2]);
          }}
          endDate={endDate}
          onEndDateChange={(date) => {
            setEndDate(date);
            setTimeRange(timeRangeOptions[2]);
          }}
          showTime={false}
        />
        <ShadowDatesContainer>
          ( Poprzedni okres: {moment(shadowDateFrom).format('DD/MM/YYYY')}
          {' - '}
          {moment(shadowDateTo).format('DD/MM/YYYY')} )
        </ShadowDatesContainer>
      </Filters>
      <MediaPopup
        open={imageOpen}
        imageUrl={imageUrl}
        videoUrl={videoUrl}
        handleClose={() => {
          setImageOpen(false);
          setImageUrl('');
          setVideoUrl('');
        }}
      />
      <TableWithJson
        maxHeight={600}
        columns={columns}
        data={topBannersGlobal}
        loadingData={loadingTopBannersGlobal}
        withPagination={false}
        showJson={false}
      />
    </>
  );
}

export default inject('bannersStore')(observer(TopCategoriesGlobalTable));

TopCategoriesGlobalTable.propTypes = {
  bannersStore: PropTypes.shape({
    topBannersGlobal: PropTypes.array,
    loadingTopBannersGlobal: PropTypes.bool,
    setConversionChosenBanner: PropTypes.func,
    fetchBannersTop: PropTypes.func,
    shadowDateFrom: PropTypes.string,
    shadowDateTo: PropTypes.string,
  }),
};
