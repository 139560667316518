import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import TableWithJson from '../../../components/TableWithJson/TableWithJson';
import {
  showLoaderOrPlaceHolder,
  Subtitle,
} from '../../../components/helpers/chartHelpers';
import { MediaPopup } from './ImagePopup/MediaPopup';
import { columnsFunc } from '../constants';

function TopBannersPerMaskTable({ bannersStore }) {
  const [imageOpen, setImageOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const {
    loadingTopBannersPerMaskCounter,
    topBannersPerMask,
    chosenMasks,
    setConversionChosenBanner,
  } = bannersStore;

  const columns = columnsFunc({
    setConversionChosenBanner,
    setImageOpen,
    setImageUrl,
    setVideoUrl,
  });

  return (
    <div>
      <MediaPopup
        open={imageOpen}
        imageUrl={imageUrl}
        videoUrl={videoUrl}
        handleClose={() => {
          setImageOpen(false);
          setImageUrl('');
          setVideoUrl('');
        }}
      />
      <div>
        {chosenMasks.map((mask, index) => (
          <div key={index}>
            <Subtitle>{mask.toUpperCase()}</Subtitle>
            {topBannersPerMask[mask] && topBannersPerMask[mask].length === 0 ? (
              showLoaderOrPlaceHolder(
                loadingTopBannersPerMaskCounter !== 0,
                false,
              )
            ) : (
              <TableWithJson
                columns={columns}
                data={topBannersPerMask[mask] || []}
                loadingData={loadingTopBannersPerMaskCounter !== 0}
                withPagination={false}
                showJson={false}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default inject('bannersStore')(observer(TopBannersPerMaskTable));

TopBannersPerMaskTable.propTypes = {
  bannersStore: PropTypes.shape({
    topBannersPerMask: PropTypes.array,
    loadingTopBannersPerMaskCounter: PropTypes.number,
    chosenMasks: PropTypes.array,
    setConversionChosenBanner: PropTypes.func,
  }),
};
