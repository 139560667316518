import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import {
  BaseOption,
  customDropdownStyles,
  FilterBox,
} from 'components/helpers/chartHelpers';
import Select from 'react-select';
import moment from 'moment';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import TableWithJson from 'components/TableWithJson/TableWithJson';
import { Filters, FlexRow } from '../styles';

const types = [
  { label: 'Kliknięcie w wiadomość', value: 'notification_opened' },
  { label: 'Usunięcie wiadomości', value: 'notification_deleted' },
  { label: 'Udostępnienie wiadomości', value: 'notification_shared' },
  { label: 'Wiadomość przeczytana', value: 'notification_read' },
];

const masks = [{ label: 'four_fstore_pl', value: 'four_fstore_pl' }];

const notificationsColumns = [
  { id: 'position', label: 'Pozycja', minWidth: 40 },
  { id: 'analyticsId', label: 'ID', minWidth: 40 },
  { id: 'title', label: 'Tytuł', minWidth: 100 },
  { id: 'content', label: 'Notyfikacja', minWidth: 100 },
  { id: 'value', label: 'Wartość', minWidth: 100 },
];

function NotificationsTable({
  notificationsStore: {
    fetchMasks,
    fetchNotifications,
    notifications,
    isLoadingNotifications,
    availableMasks,
  },
}) {
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());
  const [type, setType] = useState(types[0].value);
  const [mask, setMask] = useState('four_fstore_pl');

  const fetchData = () => {
    fetchNotifications({
      from: startDate,
      to: endDate,
      mask,
      type,
    });
  };

  useEffect(() => {
    fetchData();
    fetchMasks({ from: startDate, to: endDate, type });
  }, [startDate, endDate, mask, type]);

  return (
    <>
      <Filters className="no-horizontal-margin">
        <FlexRow>
          <DateRangePicker
            startDate={startDate}
            onStartDateChange={(date) => setStartDate(date)}
            endDate={endDate}
            onEndDateChange={(date) => setEndDate(date)}
          />
          <FilterBox>
            Typ:
            <Select
              placeholder="Typ..."
              options={types}
              value={types.filter((option) => option.value === type)}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              styles={customDropdownStyles}
              components={{ Option: BaseOption }}
              onChange={(option) => setType(option ? option.value : null)}
            />
          </FilterBox>
          <FilterBox>
            Maska:
            <Select
              placeholder="Maska..."
              options={availableMasks || masks}
              value={(availableMasks || masks).filter(
                (option) => option.value === mask,
              )}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              styles={customDropdownStyles}
              components={{ Option: BaseOption }}
              onChange={(option) => setMask(option ? option.value : null)}
            />
          </FilterBox>
        </FlexRow>
      </Filters>
      <TableWithJson
        columns={notificationsColumns}
        data={notifications}
        loadingData={isLoadingNotifications}
        withPagination={false}
        showJson={false}
      />
    </>
  );
}

export default inject('notificationsStore')(observer(NotificationsTable));

NotificationsTable.propTypes = {
  notificationsStore: PropTypes.shape({
    fetchMasks: PropTypes.func,
    fetchNotifications: PropTypes.func,
    notifications: PropTypes.array,
    isLoadingNotifications: PropTypes.bool,
    availableMasks: PropTypes.array,
    loadingAvailableMasks: PropTypes.bool,
  }),
};
