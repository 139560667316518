import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import TableWithJson from '../../components/TableWithJson/TableWithJson';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import Select from 'react-select';
import moment from 'moment';
import { ImageButton } from '../BannersPage/Components/ImagePopup/ImageButton';
import { MediaPopup } from '../BannersPage/Components/ImagePopup/MediaPopup';

const DatePickerContainer = styled(Flex)`
  margin-bottom: 20px;
  flex-direction: row;
`;

const SelectContainer = styled.div`
  width: 300px;

  &.left-margin {
    margin-left: 20px;
  }
`;

function LandingPagesClicks({
  landingPagesStore: {
    fetchDistinct,
    fetchTopByKey,
    tableData,
    landingPagesOptions,
    isLoadingTableData,
    isLoadingLandingPagesOptions,
  },
}) {
  const [startDate, setStartDate] = useState(
    moment().subtract(30, 'days').startOf('day').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().startOf('day').toDate());
  const [landingPageId, setLandingPageId] = useState(null);
  const [imageOpen, setImageOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    fetchDistinct({
      timeFrom: startDate,
      timeTo: endDate,
      distinctKey: 'object_name',
    });

    fetchTopByKey({ timeFrom: startDate, timeTo: endDate });
  }, [startDate, endDate]);

  useEffect(() => {
    fetchTopByKey({ timeFrom: startDate, timeTo: endDate, landingPageId });
  }, [landingPageId, startDate, endDate]);

  const columns = columnsFunc({ setImageOpen, setImageUrl });

  return (
    <div>
      <MediaPopup
        open={imageOpen}
        imageUrl={imageUrl}
        handleClose={() => {
          setImageOpen(false);
          setImageUrl('');
        }}
      />
      <DatePickerContainer>
        <Flex>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            onStartDateChange={setStartDate}
            showTime={false}
          />
          <SelectContainer className="left-margin">
            <Select
              isClearable
              isLoading={isLoadingLandingPagesOptions}
              placeholder="Wyszukaj id..."
              getOptionLabel={(option) => option}
              getOptionValue={(option) => option}
              value={landingPagesOptions.filter(
                (option) => option === landingPageId,
              )}
              options={landingPagesOptions}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              onChange={setLandingPageId}
            />
          </SelectContainer>
        </Flex>
      </DatePickerContainer>
      <TableWithJson
        columns={columns}
        data={tableData}
        loadingData={isLoadingTableData}
        withPagination={false}
        showJson={false}
      />
    </div>
  );
}

const columnsFunc = ({ setImageOpen, setImageUrl }) => [
  { id: 'position', label: 'Pozycja', minWidth: 50 },
  { id: 'landingPageId', label: 'ID', minWidth: 100 },
  { id: 'title', label: 'Tytuł' },
  { id: 'subtitle', label: 'Podtytuł' },
  { id: 'type', label: 'Rodzaj', minWidth: 100 },
  { id: 'value', label: 'Liczba kliknięć', minWidth: 100 },
  {
    id: 'image',
    label: 'Obrazek',
    format: (value) =>
      value && value !== '-' ? (
        <ImageButton
          setImageOpen={setImageOpen}
          setImageUrl={setImageUrl}
          setVideoUrl={() => {}}
          image={value}
        />
      ) : (
        '(Brak obrazka)'
      ),
  },
];

export default inject('landingPagesStore')(observer(LandingPagesClicks));

LandingPagesClicks.propTypes = {
  landingPagesStore: PropTypes.shape({
    fetchDistinct: PropTypes.func,
    fetchTopByKey: PropTypes.func,
    tableData: PropTypes.array,
    landingPagesOptions: PropTypes.array,
    isLoadingTableData: PropTypes.bool,
    isLoadingLandingPagesOptions: PropTypes.bool,
  }),
};
