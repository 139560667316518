import Button from '@material-ui/core/Button';
import * as PropTypes from 'prop-types';
import React from 'react';

export const ImageButton = ({
  setImageOpen,
  setImageUrl,
  setVideoUrl,
  image,
  video,
  maxHeight = 100,
  noPadding = false,
}) => (
  <Button
    onClick={() => {
      setImageOpen(true);
      setImageUrl(image);
      setVideoUrl(video);
    }}
    style={noPadding ? { padding: 0 } : {}}
  >
    <img style={{ maxWidth: 100, maxHeight: maxHeight }} src={image} />
  </Button>
);

ImageButton.propTypes = {
  setImageOpen: PropTypes.func,
  setImageUrl: PropTypes.func,
  image: PropTypes.string,
  video: PropTypes.string,
  setVideoUrl: PropTypes.string,
  maxHeight: PropTypes.number,
  noPadding: PropTypes.bool,
};
