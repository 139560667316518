const footerStyle = {
  container: {
    color: '#FFFFFF',
    position: 'relative',
    backgroundColor: '#212121',
    width: '100%',
    height: '70px',
  },
  left: {
    float: 'left!important',
    display: 'block',
    margin: '25px',
  },
  right: {
    margin: '40px',
    float: 'right!important',
  },
  footer: {
    textAlign: 'center',
    display: 'flex',
    zIndex: '2',
    position: 'relative',
  },
  a: {
    marginTop: '30px',
    color: '#00d0a8',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      color: '#00ffc4 !important',
    },
    '&:visited': {
      color: '#00d0a8',
    },
  },
  footerWhiteFont: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
    },
  },
};
export default footerStyle;
