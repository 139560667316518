import React, { Component } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { Subtitle, Title } from '../../components/helpers/chartHelpers';
import HoursTable from './Components/HoursTable';
import DiscountsLineChart from './Components/DiscountsLineChart';
import DiscountPerMaskTable from './Components/DiscountPerMaskTable';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import TooltipUI from '@material-ui/core/Tooltip';
import DiscountVsPurchasePerMaskTable from './Components/DiscountVsPurchasesPerMaskTable';

@observer
@inject('discountStore')
class DiscountsPageContainer extends Component {
  componentDidMount() {
    this.props.discountStore.fetchDiscountsHourly();
    this.props.discountStore.fetchDiscountsPerMask();
  }

  render() {
    return (
      <div>
        <GridContainer spacing={3} style={{ padding: 20, paddingBottom: 100 }}>
          <GridItem xs={12}>
            <Title color="#212121">Rabaty</Title>
          </GridItem>
          <GridItem xs={12}>
            <Subtitle color="#212121">Rabaty dziś</Subtitle>
          </GridItem>
          <GridItem xs={12}>
            <DiscountsLineChart />
          </GridItem>
          <GridItem xs={12}>
            <Subtitle color="#212121">Godzinowo</Subtitle>
          </GridItem>
          <GridItem xs={12}>
            <HoursTable />
          </GridItem>
          <GridItem xs={12}>
            <Subtitle color="#212121">
              W podziale na maski
              <TooltipUI
                style={{ paddingTop: 3, marginLeft: 9 }}
                title="Udział % to stosunek wartości rabatów dla danej maski do wartości wszystkich rabatów (z dnia lub miesiąca)"
              >
                <InfoIcon htmlColor="#212121" />
              </TooltipUI>
            </Subtitle>
          </GridItem>
          <GridItem xs={12}>
            <DiscountPerMaskTable />
          </GridItem>
          <GridItem xs={12}>
            <Subtitle color="#212121">
              W podziale na maski (w porównaniu ze sprzedażą)
            </Subtitle>
          </GridItem>
          <GridItem xs={12}>
            <DiscountVsPurchasePerMaskTable />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default DiscountsPageContainer;

DiscountsPageContainer.propTypes = {
  discountStore: PropTypes.shape({
    fetchDiscountsHourly: PropTypes.func,
    fetchDiscountsPerMask: PropTypes.func,
  }),
};
