import React from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import { showLoaderOrPlaceHolder } from '../../../components/helpers/chartHelpers';
import {
  currencyFormatter,
  percentFormatter,
} from '../../../components/helpers/utils';

const useStyles = makeStyles({
  root: {
    minHeight: 300,
    width: 315,
    backgroundColor: '#212121',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
    color: 'white',
    fontWeight: 500,
  },
  typoTitle: {
    marginTop: 10,
    fontSize: 14,
    color: 'white',
    fontWeight: 500,
  },
  typo: {
    marginLeft: 10,
    fontSize: 14,
    color: 'white',
    fontWeight: 500,
  },
});

function HourlyComparison({ purchaseStore }) {
  const classes = useStyles();
  const { purchaseHourly, loadingPurchaseHourlyData } = purchaseStore;
  if (loadingPurchaseHourlyData) {
    return showLoaderOrPlaceHolder(loadingPurchaseHourlyData);
  }
  let currentHour = moment().hour();
  if (currentHour > 0) {
    currentHour = currentHour - 1;
  }
  const hourStats = purchaseHourly.filter(
    (stat) => stat.hour === currentHour,
  )[0];

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
      <Card className={classes.root}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Przychody dla bieżącej pory dnia
          </Typography>
          <Typography className={classes.typoTitle} color="textSecondary">
            Wybrany dzień:
          </Typography>
          <Typography className={classes.typo} color="textSecondary">
            {isNaN(hourStats.today)
              ? '-'
              : currencyFormatter.format(hourStats.today)}
          </Typography>
          <Typography className={classes.typoTitle} color="textSecondary">
            Poprzedni dzień:
          </Typography>
          <Typography className={classes.typo} color="textSecondary">
            {currencyFormatter.format(hourStats.yesterday)}
          </Typography>
          <Typography className={classes.typoTitle} color="textSecondary">
            W poprzednim tygodniu:
          </Typography>
          <Typography className={classes.typo} color="textSecondary">
            {currencyFormatter.format(hourStats.week)}
          </Typography>
          <Typography className={classes.typoTitle} color="textSecondary">
            Wybrany dzień vs Poprzedni dzień:
          </Typography>
          <Typography className={classes.typo} color="textSecondary">
            {hourStats.yesterday > 0
              ? percentFormatter.format(
                  hourStats.today / hourStats.yesterday - 1,
                )
              : '-'}
          </Typography>
          <Typography className={classes.typoTitle} color="textSecondary">
            Wybrany dzień vs W poprzednim tygodniu:
          </Typography>
          <Typography className={classes.typo} color="textSecondary">
            {hourStats.week > 0
              ? percentFormatter.format(hourStats.today / hourStats.week - 1)
              : '-'}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

export default inject('purchaseStore')(observer(HourlyComparison));

HourlyComparison.propTypes = {
  purchaseStore: PropTypes.shape({
    purchaseHourly: PropTypes.array,
    purchaseHourlyCount: PropTypes.number,
    loadingPurchaseHourlyData: PropTypes.bool,
  }),
};
