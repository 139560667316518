import React from 'react';

import Select from 'react-select';
import { inject, observer } from 'mobx-react';
import * as PropTypes from 'prop-types';

function CategoriesMaskSelect({ productsStore }) {
  const {
    loadingTopCategoriesByMask,
    availableMasksForCategories,
    setChosenCategoriesMasks,
  } = productsStore;

  return (
    <>
      <Select
        isMulti
        isLoading={loadingTopCategoriesByMask}
        placeholder="Wybierz maski..."
        getOptionLabel={(option) => option}
        getOptionValue={(option) => option}
        name="colors"
        options={availableMasksForCategories}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        onChange={(option) => {
          setChosenCategoriesMasks(option);
        }}
      />
    </>
  );
}

export default inject('productsStore')(observer(CategoriesMaskSelect));

CategoriesMaskSelect.propTypes = {
  productsStore: PropTypes.shape({
    topCategoriesByMask: PropTypes.array,
    availableMasksForCategories: PropTypes.array,
    loadingTopCategoriesByMask: PropTypes.bool,
    setChosenCategoriesMasks: PropTypes.func,
  }),
};
