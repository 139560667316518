import { action, observable } from 'mobx';
import 'moment/locale/pl';
import moment from 'moment';
import config from '../config';
import aggregationsStore from './aggregationsStore';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import qs from 'qs';

export class RecommendationsStore {
  limit = 100;

  recommendationOrigins = [
    { value: 'by_product', label: 'Produkt' },
    { value: 'recently_deleted', label: 'Usunięte z koszyka' },
    { value: 'product_bundle', label: 'Paczka produktów' },
  ];

  productBundlesTypesLabels = {
    other: 'Inny',
    photo_session: 'Sesja fotograficzna',
    lookbook: 'Lookbook',
    stylist: 'Opinia stylistki',
    set: 'Zestaw ze zdjęcia',
    similarity: 'Produkty podobne wg filtrów',
    global_hero_looks: 'Global Hero Looks',
    key_looks: 'Key Looks',
  };

  @observable recommendationsPerOrigin = [];
  @observable isLoadingRecommendationsPerOrigin = false;
  @observable recommendationClicksSum = 0;

  @observable purchasesValuePerOrigin = [];
  @observable isLoadingPurchasesValue = false;
  @observable purchasesValueSum = 0;

  @observable purchasesAmountPerOrigin = [];
  @observable isLoadingPurchasesAmount = false;
  @observable purchasesAmountSum = 0;

  @observable recommendationsTopProducts = [];
  @observable isLoadingRecommendationsTopProducts = false;

  @observable productBundlesTypes = [];
  @observable isLoadingProductBundlesTypes = false;

  @observable productBundlesTop = [];
  @observable isLoadingProductBundlesTop = false;

  constructor() {}

  fieldsByType = (type, recommendationOrigin, productBundleType) => {
    let useAllStats = false;
    if (!recommendationOrigin) {
      useAllStats = true;
    }
    switch (type) {
      case 'value':
        return {
          statId: useAllStats
            ? config.statIds.purchasesValueWithRecommendationOriginAll
            : productBundleType
            ? config.statIds.purchasesValuePerProductBundleType
            : config.statIds.purchasesValuePerRecommendationOriginGrouped,
          data: 'purchasesValuePerOrigin',
          sum: 'purchasesValueSum',
          loading: 'isLoadingPurchasesValue',
        };
      case 'amount':
        return {
          statId: useAllStats
            ? config.statIds.purchasesWithRecommendationOriginAll
            : config.statIds.purchasesPerRecommendationOriginGrouped,
          data: 'purchasesAmountPerOrigin',
          sum: 'purchasesAmountSum',
          loading: 'isLoadingPurchasesAmount',
        };
      case 'clicks':
        return {
          statId: config.statIds.recommendationsPerOrigin,
          data: 'recommendationsPerOrigin',
          sum: 'recommendationClicksSum',
          loading: 'isLoadingRecommendationsPerOrigin',
        };
      default:
        return undefined;
    }
  };

  fetchRecommendationStatsForCharts = async (
    { recommendationOrigin, timeFrom, timeTo, productBundleType },
    type,
  ) => {
    const varNames = this.fieldsByType(
      type,
      recommendationOrigin,
      productBundleType,
    );

    if (varNames === undefined) {
      return;
    }

    this[varNames.loading] = true;

    let result = [];
    if (type === 'clicks' && !recommendationOrigin) {
      const {
        data: { sum: data },
      } = await aggregationsStore.fetchStatisticsSum(
        moment(timeFrom).toISOString(),
        moment(timeTo).toISOString(),
        '1D',
        varNames.statId,
        '$fromTs',
      );
      data.forEach((item) => {
        item.fromTs = item._id;
        item.value = item.sum;
      });
      result = data;
    } else {
      const { data } = await aggregationsStore.fetchStatistics({
        timeFrom: moment(timeFrom).toISOString(),
        timeTo: moment(timeTo).toISOString(),
        step: '1D',
        statId: varNames.statId,
        recommendationOrigin,
        productBundleType,
      });
      result = data;
    }

    if (result.length === 0) {
      this[varNames.data] = [];
      this[varNames.loading] = false;
      this[varNames.sum] = 0;
      return;
    }

    const dataObj = {};
    let sum = 0;
    result.forEach((item) => {
      sum += item.value;

      if (dataObj[item.fromTs]) {
        dataObj[item.fromTs] += item.value;
      } else {
        dataObj[item.fromTs] = item.value;
      }
    });

    const dateDiff = moment(timeTo).diff(moment(timeFrom), 'days');

    const dataObjWithZero = new Map();
    for (let i = 0; i <= dateDiff; i++) {
      const date = moment(timeFrom).add(i, 'days').toISOString();
      if (dataObj[date]) {
        dataObjWithZero.set(date, dataObj[date]);
      } else {
        dataObjWithZero.set(date, 0);
      }
    }

    const tableData = Array.from(dataObjWithZero).map(([key, value]) => {
      let date = moment(key);
      date = date.locale('pl').format('lll').toString();
      return { value, fromTs: date };
    });

    this[varNames.data] = tableData;
    this[varNames.sum] = sum;
    this[varNames.loading] = false;
  };

  @action.bound fetchProductsTop = async ({
    timeFrom,
    timeTo,
    recommendationOrigin,
  }) => {
    this.topProductsGlobal = true;

    const monthQuery = {
      statId: config.statIds.recommendationsPerOriginAndSKU,
      step: '1D',
      timeFrom: moment(timeFrom).toISOString(),
      timeTo: moment(timeTo).toISOString(),
      key: '$object_id',
      aggregateKey: '$value',
      limit: this.limit,
    };

    if (recommendationOrigin) {
      monthQuery.params = {
        recommendation_origin: recommendationOrigin.value,
      };
    }

    let { data: month } = await API.get(
      `${API_ROUTES.STATISTICS_TOP_PRODUCTS}?${qs.stringify(monthQuery)}`,
    );
    month.forEach((item, index) => (item.position = index + 1));
    this.recommendationsTopProducts = month;
    this.isLoadingRecommendationsTopProducts = false;
  };

  @action.bound fetchProductBundlesTypes = async () => {
    this.isLoadingProductBundlesTypes = true;
    try {
      let { data } = await API.get(API_ROUTES.PRODUCT_BUNDLES_TYPES);
      data = data.filter((item) => item !== null);
      this.productBundlesTypes = data.map((item) => ({
        value: item,
        label: this.productBundlesTypesLabels[item] || item,
      }));
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingProductBundlesTypes = false;
    }
  };

  @action.bound fetchProductBundlesTop = async ({
    timeFrom,
    timeTo,
    sortBy,
  }) => {
    this.isLoadingProductBundlesTop = true;
    const query = {
      timeFrom: moment(timeFrom).toISOString(),
      timeTo: moment(timeTo).toISOString(),
      sortBy,
      statIdValue: config.statIds.purchasesValuePerProductBundleId,
      statIdAmount: config.statIds.recommendationClicksPerProductBundleId,
      step: '1D',
      limit: this.limit,
    };
    try {
      let {
        data: { result: data },
      } = await API.get(
        `${API_ROUTES.PRODUCT_BUNDLES_TOP}?${qs.stringify(query)}`,
      );
      data.forEach((item, index) => {
        item.type = this.productBundlesTypesLabels[item.type] || item.type;
        item.position = index + 1;
      });
      this.productBundlesTop = data;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingProductBundlesTop = false;
    }
  };
}

export default new RecommendationsStore();
