import React from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { APP_ROUTES } from '_app/routes';

const PrivateRoute = inject('authStore')(
  observer(({ authStore, component: RouteComponent, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(routeProps) =>
          authStore.isAuthenticated ? (
            <RouteComponent {...routeProps} />
          ) : (
            <Redirect to={APP_ROUTES.LOGIN} />
          )
        }
      />
    );
  }),
);

export default PrivateRoute;

PrivateRoute.propTypes = {
  component: PropTypes.object,
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }),
};
