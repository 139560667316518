import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import TableWithJson from '../../../components/TableWithJson/TableWithJson';
import {
  showLoaderOrPlaceHolder,
  Subtitle,
} from '../../../components/helpers/chartHelpers';
import { ImageButton } from './ImagePopup/ImageButton';
import { MediaPopup } from './ImagePopup/MediaPopup';
import { BannerButton } from '../styles';

function TopBannersPerMaskTable({ bannersStore }) {
  const [imageOpen, setImageOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');

  const {
    loadingTopBannersPerMaskDateRangeCounter,
    topBannersPerMaskDateRange,
    chosenDateRangeMasks,
    setConversionChosenBanner,
  } = bannersStore;

  const columns = [
    { id: 'position', label: 'Pozycja', minWidth: 100 },
    {
      id: '_id',
      label: 'ID',
      minWidth: 100,
      format: (value) => (
        <BannerButton
          onClick={() => {
            setConversionChosenBanner(value);
            document
              .getElementById('conversion-chart-select')
              .scrollIntoView({ behavior: 'smooth' });
          }}
        >
          {value}
        </BannerButton>
      ),
    },
    { id: 'title', label: 'Nazwa', minWidth: 100 },
    {
      id: 'store',
      label: 'Maska',
      minWidth: 100,
      format: (value) => (value && value.name) || '-',
    },
    {
      id: 'mainImage',
      label: 'Obrazek',
      minWidth: 100,
      format: (value) =>
        value && value !== '-' ? (
          <ImageButton
            setImageOpen={setImageOpen}
            setImageUrl={setImageUrl}
            image={value}
          />
        ) : (
          '(Brak obrazka)'
        ),
    },
    {
      id: 'video',
      label: 'Wideo',
      minWidth: 100,
      format: (value) =>
        value && value !== '-' ? (
          <ImageButton
            setImageOpen={setImageOpen}
            setImageUrl={setImageUrl}
            setVideoUrl={setVideoUrl}
            image={value.thumb}
            video={value.url}
          />
        ) : (
          '(Brak wideo)'
        ),
    },
    { id: 'value', label: 'Ilość', minWidth: 100 },
  ];

  return (
    <div>
      <MediaPopup
        open={imageOpen}
        imageUrl={imageUrl}
        videoUrl={videoUrl}
        handleClose={() => {
          setImageOpen(false);
          setImageUrl('');
          setVideoUrl('');
        }}
      />
      <div>
        {chosenDateRangeMasks.map((mask, index) => (
          <div key={index}>
            <Subtitle>{mask.toUpperCase()}</Subtitle>
            {topBannersPerMaskDateRange[mask] &&
            topBannersPerMaskDateRange[mask].length === 0 ? (
              showLoaderOrPlaceHolder(
                loadingTopBannersPerMaskDateRangeCounter !== 0,
                false,
              )
            ) : (
              <TableWithJson
                columns={columns}
                data={topBannersPerMaskDateRange[mask] || []}
                loadingData={loadingTopBannersPerMaskDateRangeCounter !== 0}
                withPagination={false}
                showJson={false}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default inject('bannersStore')(observer(TopBannersPerMaskTable));

TopBannersPerMaskTable.propTypes = {
  bannersStore: PropTypes.shape({
    topBannersPerMaskDateRange: PropTypes.array,
    loadingTopBannersPerMaskDateRangeCounter: PropTypes.number,
    chosenDateRangeMasks: PropTypes.array,
    setConversionChosenBanner: PropTypes.func,
  }),
};
