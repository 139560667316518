import React from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import { currencyFormatter } from '../helpers/utils';

const useStyles = makeStyles({
  root: {
    marginLeft: 10,
    // minWidth: 275,
    maxHeight: 80,
    maxWidth: 500,
    backgroundColor: '#212121',
  },
  title: {
    fontSize: 14,
    color: 'white',
    fontWeight: 500,
  },
  typo: {
    textAlign: 'right',
    marginLeft: 10,
    fontSize: 14,
    color: 'white',
    fontWeight: 500,
  },
});

function BasicCard({ label, value, useFormatter = true }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {label}
        </Typography>
        <Typography className={classes.typo} color="textSecondary">
          {useFormatter ? currencyFormatter.format(value) : value}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default inject('purchaseStore')(observer(BasicCard));

BasicCard.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  useFormatter: PropTypes.bool,
};
