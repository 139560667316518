import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import {
  customDropdownStyles,
  FilterBox,
  Filters,
} from '../../../components/helpers/chartHelpers';
import GreenCheckbox from '../../../components/GreenCheckbox';
import TableWithJson from '../../../components/TableWithJson/TableWithJson';
import Select from 'react-select';

const columns = [
  { id: 'StoreExternalId', label: 'External Id', minWidth: 100 },
  { id: 'StoreNumber', label: 'Numer sklepu', minWidth: 100 },
  { id: 'Name', label: 'Nazwa', minWidth: 100 },
  { id: 'Address', label: 'Adres', minWidth: 100 },
];

function StoresTable({ aggregationsStore }) {
  const [country, setCountry] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const {
    fetchStores,
    stores,
    storesCount,
    loadingStores,
    countries,
    loadingCountries,
    fetchCountries,
  } = aggregationsStore;

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await fetchStores({
      limit: rowsPerPage,
      page: newPage,
      country,
      isActive,
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    await fetchStores({
      limit: event.target.value,
      page: 0,
      country,
      isActive,
    });
  };

  useEffect(() => {
    fetchStores({ limit: rowsPerPage, page: 0, isActive, country });
  }, [country, isActive]);

  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <>
      <Filters>
        <FilterBox>
          Kraj:
          <Select
            options={countries}
            value={countries.filter((option) => option.value === country)}
            isLoading={loadingCountries}
            getOptionLabel={(option) => option.value}
            getOptionValue={(option) => option.value}
            onChange={(option) => setCountry(option.value)}
            styles={customDropdownStyles}
          />
        </FilterBox>
        <FilterBox>
          Aktywny:
          <GreenCheckbox
            checked={isActive}
            onChange={(event) => setIsActive(event.target.checked)}
          />
        </FilterBox>
      </Filters>
      <TableWithJson
        columns={columns}
        data={stores}
        loadingData={loadingStores}
        dataCount={storesCount}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </>
  );
}

export default inject('aggregationsStore')(observer(StoresTable));

StoresTable.propTypes = {
  aggregationsStore: PropTypes.shape({
    fetchStores: PropTypes.func,
    fetchCountries: PropTypes.func,
    stores: PropTypes.array,
    storesCount: PropTypes.number,
    loadingStores: PropTypes.bool,
    countries: PropTypes.array,
    loadingCountries: PropTypes.bool,
  }),
};
