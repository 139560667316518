import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { pl } from 'date-fns/locale';
import './date-picker.css';
registerLocale('pl', pl);

const PolishDatePicker = (props) => (
  <DatePicker
    locale="pl"
    timeCaption="Godzina"
    popperModifiers={{
      offset: {
        enabled: true,
        offset: '5px, 10px',
      },
      preventOverflow: {
        enabled: true,
        escapeWithReference: false,
        boundariesElement: 'viewport',
      },
    }}
    {...props}
  />
);

export default PolishDatePicker;
