import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import CustomLineChart from '../../../components/CustomLineChart/CustomLineChart';
import { currencyFormatter } from '../../../components/helpers/utils';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import { default as Select } from 'react-select/async';
import {
  CardContainer,
  FlexRowWithMargin,
  DatePickerContainer,
} from '../styles';
import { HalfContainer } from '../../../globalStyles';
import BasicCard from '../../../components/BasicCard/BasicCard';
import { ImageButton } from './ImagePopup/ImageButton';
import { MediaPopup } from './ImagePopup/MediaPopup';
import GreenCheckbox from '../../../components/GreenCheckbox';

function DashboardElementConversion({
  bannersStore: {
    fetchBanners,
    fetchElementConversion,
    isLoadingBanners,
    isLoadingElementConversion,
    conversionLineChartData,
    loadBanners,
    clearConversion,
    conversionSum,
    chosenConversionBanner: chosenBanner,
    setConversionChosenBanner: setChosenBanner,
    banners,
  },
  type,
  label,
  showSelectCheckbox = false,
}) {
  const [imageOpen, setImageOpen] = useState(false);
  const [showAllOptionsInScroll, setShowAllOptionsInScroll] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [startDate, setStartDate] = useState(
    moment().subtract(30, 'days').startOf('day').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());

  useEffect(() => {
    fetchBanners(type);
    return () => {
      clearConversion();
      setImageUrl('');
      setVideoUrl('');
      setChosenBanner('');
    };
  }, []);

  useEffect(() => {
    if (chosenBanner && chosenBanner.image) {
      if (chosenBanner.video && chosenBanner.video.url) {
        setImageUrl(chosenBanner.video.thumb);
        setVideoUrl(chosenBanner.video.url);
      } else {
        setImageUrl(chosenBanner.image);
      }
    } else {
      if (chosenBanner.video && chosenBanner.video.url) {
        setImageUrl(chosenBanner.video.thumb);
        setVideoUrl(chosenBanner.video.url);
      } else {
        setImageUrl('');
        setVideoUrl('');
      }
    }
  }, [chosenBanner]);

  useEffect(() => {
    if (chosenBanner && chosenBanner.value) {
      fetchElementConversion(
        {
          elementId: chosenBanner.value,
          timeFrom: startDate,
          timeTo: endDate,
        },
        type,
      );
    }
  }, [chosenBanner, startDate, endDate]);

  return (
    <>
      <CardContainer>
        <MediaPopup
          open={imageOpen}
          imageUrl={imageUrl}
          videoUrl={videoUrl}
          handleClose={() => {
            setImageOpen(false);
          }}
        />
        {imageUrl ? (
          <ImageButton
            noPadding
            maxHeight={78}
            setImageOpen={setImageOpen}
            image={imageUrl}
            video={videoUrl}
            setImageUrl={setImageUrl}
            setVideoUrl={setVideoUrl}
          />
        ) : null}
        <BasicCard
          label="Suma dla wybranego zakresu dat"
          value={conversionSum}
        />
      </CardContainer>
      <CustomLineChart
        loadingData={isLoadingElementConversion}
        data={conversionLineChartData}
        xAxisKey="fromTs"
        dataKeys={['value']}
        keysTranslation={['konwersja']}
        keysColors={['#272727']}
        valueFormatter={(value) => currencyFormatter.format(value)}
      />
      <FlexRowWithMargin>
        <HalfContainer>
          <Select
            id="conversion-chart-select"
            isLoading={isLoadingBanners}
            isClearable
            placeholder={`Wybierz ${label} - zacznij pisać aby zobaczyć dostępne opcje`}
            onChange={(option) =>
              setChosenBanner(option && option.value ? option.value : '')
            }
            menuPlacement="top"
            value={chosenBanner}
            loadOptions={loadBanners}
            defaultOptions={showAllOptionsInScroll ? banners : []}
          />
          {showSelectCheckbox ? (
            <>
              Pokaż wszystkie opcje:
              <GreenCheckbox
                checked={showAllOptionsInScroll}
                onChange={(event) =>
                  setShowAllOptionsInScroll(event.target.checked)
                }
              />
            </>
          ) : null}
        </HalfContainer>
        <HalfContainer>
          <DatePickerContainer
            style={{ justifyContent: 'space-between', margin: 1 }}
          >
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onEndDateChange={setEndDate}
              onStartDateChange={setStartDate}
              showTime={false}
            />
          </DatePickerContainer>
        </HalfContainer>
      </FlexRowWithMargin>
    </>
  );
}

export default inject('bannersStore')(observer(DashboardElementConversion));

DashboardElementConversion.propTypes = {
  bannersStore: PropTypes.shape({
    fetchBanners: PropTypes.func,
    isLoadingBanners: PropTypes.bool,
    fetchElementConversion: PropTypes.func,
    isLoadingElementConversion: PropTypes.bool,
    conversionLineChartData: PropTypes.array,
    loadBanners: PropTypes.func,
    clearConversion: PropTypes.func,
    conversionSum: PropTypes.number,
    chosenConversionBanner: PropTypes.any,
    setConversionChosenBanner: PropTypes.func,
    banners: PropTypes.array,
  }),
  label: PropTypes.string,
  type: PropTypes.string,
  showSelectCheckbox: PropTypes.bool,
};
