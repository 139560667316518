import axios from 'axios';
import authStore from '../stores/authStore';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.data.message === 'Invalid Token') {
      return authStore.logout();
    }
    return Promise.reject(error.response);
  },
);

axiosInstance.interceptors.request.use(async function (config) {
  const token = await authStore.getToken();
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default axiosInstance;
