import React, { useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import {
  SubtitleWithoutBorder,
  Title,
} from '../../components/helpers/chartHelpers';
import UsersBarChart from '../UsersPage/Components/UsersBarChart';
import GenderPieChart from './Components/GenderPieChart';
import PaymentCharts from './Components/PaymentCharts/index';
import PaymentLineChart from './Components/PaymentLineChart';
import TimeShortcuts from 'components/TimeShortcuts';

function PersonPage() {
  const [shortcutFrom, setShortcutFrom] = useState(null);
  const [shortcutTo, setShortcutTo] = useState(null);

  return (
    <div>
      <GridContainer spacing={3} style={{ padding: 20, paddingBottom: 100 }}>
        <GridItem xs={12}>
          <Title color="#212121">Persona</Title>
        </GridItem>
        <GridItem xs={5}>
          <GenderPieChart />
        </GridItem>
        <GridItem xs={7}>
          <UsersBarChart infoTip="Unikalne urządzenia (które wystąpiły w minimum 3 zdarzeniach) dla danego zakresu dat na podstawie wszystkich zdarzeń" />
        </GridItem>
        <GridItem
          xs={12}
          style={{ borderBottom: '1px solid #212121', margin: '10px 10px' }}
        />
        <GridItem xs={2} style={{ borderRight: '1px solid #212121' }}>
          <TimeShortcuts
            setTimeTo={setShortcutTo}
            setTimeFrom={setShortcutFrom}
          />
        </GridItem>
        <GridItem xs={10}>
          <SubtitleWithoutBorder>Płatności</SubtitleWithoutBorder>
          <PaymentCharts shortcutFrom={shortcutFrom} shortcutTo={shortcutTo} />
        </GridItem>
        <GridItem xs={2} style={{ borderRight: '1px solid #212121' }} />
        <GridItem xs={10} style={{ borderTop: '1px solid #212121' }}>
          <PaymentLineChart
            shortcutFrom={shortcutFrom}
            shortcutTo={shortcutTo}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default PersonPage;
