import React from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { Subtitle, Title } from '../../components/helpers/chartHelpers';
import RecommendationsSalesChart from './RecommendationsSalesChart';
import RecommendationsTopProducts from './RecommendationsTopProducts';
import { InfoTip } from './InfoTip';
import TopProductBundles from './TopProductBundles';

const RecommendationsPageContainer = () => (
  <div>
    <GridContainer spacing={3} style={{ padding: 20, paddingBottom: 100 }}>
      <GridItem xs={12}>
        <Title color="#212121">Rekomendacje</Title>
      </GridItem>
      <GridItem xs={12}>
        <Subtitle color="#212121">
          Wartość sprzedaży z rekomendacjami
          <InfoTip />
        </Subtitle>
      </GridItem>
      <GridItem xs={12}>
        <RecommendationsSalesChart type="value" />
      </GridItem>
      <GridItem xs={12}>
        <Subtitle color="#212121">Ilość sprzedaży z rekomendacjami</Subtitle>
      </GridItem>
      <GridItem xs={12}>
        <RecommendationsSalesChart type="amount" />
      </GridItem>
      <GridItem xs={12}>
        <Subtitle color="#212121">Ilość kliknięć w rekomendacje</Subtitle>
      </GridItem>
      <GridItem xs={12}>
        <RecommendationsSalesChart type="clicks" />
      </GridItem>
      <GridItem xs={12}>
        <Subtitle color="#212121">
          Top 100 produktów z rekomendacji (na podstawie kliknięć)
        </Subtitle>
      </GridItem>
      <GridItem xs={12}>
        <RecommendationsTopProducts />
      </GridItem>
      <GridItem xs={12}>
        <Subtitle color="#212121">Top 100 paczek produktów</Subtitle>
      </GridItem>
      <GridItem xs={12}>
        <TopProductBundles />
      </GridItem>
    </GridContainer>
  </div>
);

export default RecommendationsPageContainer;
