import React, { useEffect, useState } from 'react';

import Select from 'react-select';
import { inject, observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import { SelectContainer } from '../styles';
import { Flex } from 'rebass';
import { genderOptions } from '../constants';

function MaskSelect({ bannersStore }) {
  const {
    loadingAvailableMasks,
    availableMasks,
    setChosenMasks,
    fetchBannersTop,
    chosenMasks,
  } = bannersStore;

  const [genderType, setGenderType] = useState('');

  useEffect(() => {
    if (chosenMasks.length > 0) {
      let mask = null;
      for (mask of chosenMasks) {
        fetchBannersTop({
          mask,
          genderType: genderType ? genderType.value : undefined,
        });
      }
    }
  }, [chosenMasks, genderType]);

  return (
    <Flex style={{ gap: 10 }}>
      <SelectContainer width={400}>
        <Select
          isMulti
          isLoading={loadingAvailableMasks}
          placeholder="Wybierz maski..."
          getOptionLabel={(option) => option}
          getOptionValue={(option) => option}
          name="colors"
          options={availableMasks}
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          onChange={(option) => {
            setChosenMasks(option);
          }}
        />
      </SelectContainer>
      <SelectContainer>
        <Select
          isClearable
          placeholder="Wybierz typ"
          value={genderType}
          name="type"
          options={genderOptions}
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          onChange={(option) => {
            setGenderType(option);
          }}
        />
      </SelectContainer>
    </Flex>
  );
}

export default inject('bannersStore')(observer(MaskSelect));

MaskSelect.propTypes = {
  bannersStore: PropTypes.shape({
    availableMasks: PropTypes.array,
    chosenMasks: PropTypes.array,
    loadingAvailableMasks: PropTypes.bool,
    setChosenMasks: PropTypes.func,
    fetchBannersTop: PropTypes.func,
  }),
};
