import React, { useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import CustomLineChart from '../../components/CustomLineChart/CustomLineChart';
import { currencyFormatter } from '../../components/helpers/utils';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import Select from 'react-select';
import {
  CardContainer,
  FlexRowWithMargin,
  DatePickerContainer,
} from '../BannersPage/styles';
import BasicCard from '../../components/BasicCard/BasicCard';
import { SelectContainer } from './styles';

function RecommendationsSalesChart({
  recommendationsStore: {
    fetchRecommendationStatsForCharts,
    isLoadingPurchasesValue,
    isLoadingPurchasesAmount,
    purchasesValuePerOrigin,
    purchasesAmountPerOrigin,
    purchasesValueSum,
    purchasesAmountSum,
    recommendationOrigins,
    recommendationsPerOrigin,
    isLoadingRecommendationsPerOrigin,
    recommendationClicksSum,
    productBundlesTypes,
    isLoadingProductBundlesTypes,
    fetchProductBundlesTypes,
  },
  type,
}) {
  const [startDate, setStartDate] = useState(
    moment().subtract(30, 'days').startOf('day').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());
  const [chosenRecommendationOrigin, setChosenRecommendationOrigin] =
    useState(null);
  const [chosenProductBundleType, setChosenProductBundleType] = useState(null);

  useEffect(() => {
    fetchRecommendationStatsForCharts(
      {
        timeFrom: startDate,
        timeTo: endDate,
        recommendationOrigin: chosenRecommendationOrigin
          ? chosenRecommendationOrigin.value
          : null,
        productBundleType: chosenProductBundleType
          ? chosenProductBundleType.value
          : null,
      },
      type,
    );
  }, [chosenRecommendationOrigin, startDate, endDate, chosenProductBundleType]);

  useEffect(() => {
    if (type === 'value') {
      fetchProductBundlesTypes();
    }
  }, []);

  const data = useMemo(() => {
    const data = {
      chart: [],
      sum: 0,
      loading: false,
    };

    switch (type) {
      case 'value':
        data.chart = purchasesValuePerOrigin;
        data.sum = purchasesValueSum;
        data.loading = isLoadingPurchasesValue;
        break;
      case 'amount':
        data.chart = purchasesAmountPerOrigin;
        data.sum = purchasesAmountSum;
        data.loading = isLoadingPurchasesAmount;
        break;
      case 'clicks':
        data.chart = recommendationsPerOrigin;
        data.sum = recommendationClicksSum;
        data.loading = isLoadingRecommendationsPerOrigin;
        break;
    }

    return data;
  }, [
    type,
    purchasesValuePerOrigin,
    purchasesAmountPerOrigin,
    purchasesValueSum,
    purchasesAmountSum,
    recommendationsPerOrigin,
    recommendationClicksSum,
    isLoadingPurchasesValue,
    isLoadingPurchasesAmount,
    isLoadingRecommendationsPerOrigin,
  ]);

  return (
    <>
      <CardContainer>
        <BasicCard
          label="Suma dla wybranego zakresu dat"
          value={data.sum}
          useFormatter={type === 'value'}
        />
      </CardContainer>
      <CustomLineChart
        loadingData={data.loading}
        data={data.chart}
        xAxisKey="fromTs"
        dataKeys={['value']}
        keysTranslation={['Wartość']}
        keysColors={['#272727']}
        valueFormatter={(value) =>
          type === 'value' ? currencyFormatter.format(value) : value
        }
      />
      <FlexRowWithMargin>
        <DatePickerContainer
          style={{ justifyContent: 'space-between', margin: 1 }}
        >
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            onStartDateChange={setStartDate}
            showTime={false}
          />
        </DatePickerContainer>
        <SelectContainer>
          <Select
            id="recommendations-sales-select"
            isClearable
            placeholder={`Pochodzenie rekomendacji`}
            onChange={(option) => {
              setChosenRecommendationOrigin(option);
              if (
                chosenRecommendationOrigin &&
                chosenRecommendationOrigin.value === 'product_bundle'
              ) {
                setChosenProductBundleType(null);
              }
            }}
            menuPlacement="top"
            value={chosenRecommendationOrigin}
            options={recommendationOrigins}
          />
        </SelectContainer>
        {type === 'value' &&
          chosenRecommendationOrigin &&
          chosenRecommendationOrigin.value === 'product_bundle' && (
            <SelectContainer>
              <Select
                id="product-bundles-types-select"
                isClearable
                placeholder={`Typ paczki produktów`}
                onChange={(option) => setChosenProductBundleType(option)}
                menuPlacement="top"
                value={chosenProductBundleType}
                options={productBundlesTypes}
                loading={isLoadingProductBundlesTypes}
              />
            </SelectContainer>
          )}
      </FlexRowWithMargin>
    </>
  );
}

export default inject('recommendationsStore')(
  observer(RecommendationsSalesChart),
);

RecommendationsSalesChart.propTypes = {
  recommendationsStore: PropTypes.shape({
    fetchRecommendationStatsForCharts: PropTypes.func,
    isLoadingPurchasesValue: PropTypes.bool,
    purchasesValuePerOrigin: PropTypes.array,
    purchasesValueSum: PropTypes.number,
    recommendationOrigins: PropTypes.array,
    isLoadingPurchasesAmount: PropTypes.bool,
    purchasesAmountPerOrigin: PropTypes.array,
    purchasesAmountSum: PropTypes.number,
    recommendationsPerOrigin: PropTypes.array,
    isLoadingRecommendationsPerOrigin: PropTypes.bool,
    recommendationClicksSum: PropTypes.number,
    productBundlesTypes: PropTypes.array,
    isLoadingProductBundlesTypes: PropTypes.bool,
    fetchProductBundlesTypes: PropTypes.func,
  }),
  type: PropTypes.string,
};
