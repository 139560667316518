import React, { useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import TableWithJson from '../../../components/TableWithJson/TableWithJson';
import { MediaPopup } from './ImagePopup/MediaPopup';
import { SelectContainer, Filters, DatePickerAdditionalLabel } from '../styles';
import Select from 'react-select';
import { compareColumnsFunc, genderOptions } from '../constants';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';

function TopBannersComparisonTable({ bannersStore }) {
  const [startDate, setStartDate] = useState(
    moment().startOf('month').subtract(1, 'month').toDate(),
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(1, 'month').endOf('month').toDate(),
  );
  const [compareStartDate, setCompareStartDate] = useState(
    moment().startOf('month').toDate(),
  );
  const [compareEndDate, setCompareEndDate] = useState(
    moment().endOf('month').toDate(),
  );
  const [imageOpen, setImageOpen] = useState(false);
  const [genderType, setGenderType] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const {
    fetchBannersTopComparison,
    topBannersComparisonData,
    loadingTopBannersComparison,
    setConversionChosenBanner,
  } = bannersStore;

  useEffect(() => {
    fetchBannersTopComparison({
      genderType: genderType ? genderType.value : undefined,
      endDate,
      startDate,
      compareStartDate,
      compareEndDate,
    });
  }, [genderType, startDate, endDate, compareStartDate, compareEndDate]);

  const columns = useMemo(() => {
    return compareColumnsFunc({
      setConversionChosenBanner,
      setImageOpen,
      setImageUrl,
      setVideoUrl,
    });
  }, []);

  return (
    <>
      <Filters>
        <SelectContainer width={150} margin="0">
          <Select
            isClearable
            placeholder="Wybierz typ"
            value={genderType}
            name="type"
            options={genderOptions}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            onChange={(option) => {
              setGenderType(option);
            }}
          />
        </SelectContainer>
        <DatePickerAdditionalLabel>Zakres I:</DatePickerAdditionalLabel>
        <DateRangePicker
          containerClassName="small"
          startDate={startDate}
          onStartDateChange={(date) => {
            setStartDate(date);
          }}
          endDate={endDate}
          onEndDateChange={(date) => {
            setEndDate(date);
          }}
          showTime={false}
        />
        <DatePickerAdditionalLabel>Zakres II:</DatePickerAdditionalLabel>
        <DateRangePicker
          containerClassName="small"
          startDate={compareStartDate}
          onStartDateChange={(date) => {
            setCompareStartDate(date);
          }}
          endDate={compareEndDate}
          onEndDateChange={(date) => {
            setCompareEndDate(date);
          }}
          showTime={false}
        />
      </Filters>
      <MediaPopup
        open={imageOpen}
        imageUrl={imageUrl}
        videoUrl={videoUrl}
        handleClose={() => {
          setImageOpen(false);
          setImageUrl('');
          setVideoUrl('');
        }}
      />
      <TableWithJson
        maxHeight={600}
        columns={columns}
        data={topBannersComparisonData}
        loadingData={loadingTopBannersComparison}
        withPagination={false}
        showJson={false}
      />
    </>
  );
}

export default inject('bannersStore')(observer(TopBannersComparisonTable));

TopBannersComparisonTable.propTypes = {
  bannersStore: PropTypes.shape({
    topBannersComparisonData: PropTypes.array,
    loadingTopBannersComparison: PropTypes.bool,
    setConversionChosenBanner: PropTypes.func,
    fetchBannersTopComparison: PropTypes.func,
  }),
};
