import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import TableWithJson from 'components/TableWithJson/TableWithJson';
import { Filters, FlexRow } from '../styles';

const activationCodesColumns = [
  {
    id: 'activationCodeSource',
    label: 'Źródło aktywacji subskrypcji',
    format: (value) => (!value ? '(Źródło bez nazwy)' : value),
    minWidth: 100,
  },
  {
    id: 'value',
    label: 'Liczba wykorzystanych kuponów aktywacji',
    minWidth: 100,
  },
];

function ActivationCodesCountTable({
  couponsStore: {
    fetchActivationCodesCount,
    isLoadingActivationCodesCount,
    activationCodesCount,
  },
  shortcutFrom,
  shortcutTo,
}) {
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());

  useEffect(() => {
    if (shortcutFrom) {
      setStartDate(shortcutFrom);
    }
    if (shortcutTo) {
      setEndDate(shortcutTo);
    }
  }, [shortcutFrom, shortcutTo]);

  useEffect(() => {
    fetchActivationCodesCount({
      from: startDate,
      to: endDate,
    });
  }, [startDate, endDate]);

  return (
    <>
      <Filters className="no-horizontal-margin">
        <FlexRow>
          <DateRangePicker
            startDate={startDate}
            onStartDateChange={(date) => setStartDate(date)}
            endDate={endDate}
            onEndDateChange={(date) => setEndDate(date)}
          />
        </FlexRow>
      </Filters>
      <TableWithJson
        columns={activationCodesColumns}
        data={activationCodesCount}
        loadingData={isLoadingActivationCodesCount}
        withPagination={false}
        showJson={false}
      />
    </>
  );
}

export default inject('couponsStore')(observer(ActivationCodesCountTable));

ActivationCodesCountTable.propTypes = {
  couponsStore: PropTypes.shape({
    fetchActivationCodesCount: PropTypes.func,
    activationCodesCount: PropTypes.array,
    isLoadingActivationCodesCount: PropTypes.bool,
  }),
  shortcutFrom: PropTypes.any,
  shortcutTo: PropTypes.any,
};
