import { action, observable } from 'mobx';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import 'moment/locale/pl';
import qs from 'qs';
import moment from 'moment';
import config from '../config';

export class CouponsStore {
  @observable couponPools = [];
  @observable isLoadingCouponPools = false;

  @observable activationCodePools = [];
  @observable isLoadingActivationCodePools = false;

  @observable couponPoolsCount = [];
  @observable isLoadingCouponPoolsCount = false;

  @observable activationCodesCount = [];
  @observable isLoadingActivationCodesCount = false;

  limit = 100;

  constructor() {}

  @action
  fetchActivationCodePools = async ({ store }) => {
    this.isLoadingActivationCodePools = true;

    try {
      const query = {};

      if (store) {
        query.store;
      }

      const { data } = await API.get(
        `${API_ROUTES.ACTIVATION_CODE_POOLS}?${qs.stringify(query)}`,
      );

      this.activationCodePools = data
        .filter((pool) => pool && pool !== '')
        .map((pool) => ({
          label: pool,
          value: pool,
        }));
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingActivationCodePools = false;
    }
  };

  @action
  fetchCouponPools = async ({ store }) => {
    this.isLoadingCouponPools = true;
    try {
      const query = {};

      if (store) {
        query.store;
      }

      const { data } = await API.get(
        `${API_ROUTES.COUPON_POOLS}?${qs.stringify(query)}`,
      );

      this.couponPools = data
        .filter((pool) => pool && pool !== '')
        .map((pool) => ({
          label: pool,
          value: pool,
        }));
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingCouponPools = false;
    }
  };

  fetchCouponPoolsCount = async ({ from, to }) => {
    this.isLoadingCouponPoolsCount = true;

    try {
      const query = {
        statId: config.statIds.couponPoolsCount,
        step: '1D',
        timeFrom: moment(from).toISOString(),
        timeTo: moment(to).toISOString(),
        key: '$coupon_pool',
        aggregateKey: '$value',
        limit: this.limit,
      };

      const { data } = await API.get(
        `${API_ROUTES.STATISTICS_TOP}?${qs.stringify(query)}`,
      );

      this.couponPoolsCount = data.map((pool) => ({
        couponPool: pool._id,
        value: pool.value,
      }));
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingCouponPoolsCount = false;
    }
  };

  fetchActivationCodesCount = async ({ from, to }) => {
    this.isLoadingActivationCodesCount = true;

    try {
      const query = {
        statId: config.statIds.activationCodesCount,
        step: '1D',
        timeFrom: moment(from).toISOString(),
        timeTo: moment(to).toISOString(),
        key: '$activation_code_source',
        aggregateKey: '$value',
        limit: this.limit,
      };

      const { data } = await API.get(
        `${API_ROUTES.STATISTICS_TOP}?${qs.stringify(query)}`,
      );

      this.activationCodesCount = data.map((pool) => ({
        activationCodeSource: pool._id,
        value: pool.value,
      }));
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingActivationCodesCount = false;
    }
  };
}

export default new CouponsStore();
