import React, { useEffect, useState } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts';
import { inject, observer } from 'mobx-react';
import {
  showLoaderOrPlaceHolder,
  Filters,
  FilterBox,
  StyledInput,
  PieChartSubtitle,
} from '../../../components/helpers/chartHelpers';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import PolishDatePicker from '../../../components/PolishDatePicker/PolishDatePicker';
import PercentTooltip from './PercentTooltip';
import TooltipUI from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

const COLORS = [
  '#008B6F',
  '#339966',
  '#0088FE',
  '#66B2FF',
  '#FFBB28',
  '#FF8042',
];

const StyledButton = styled(Button)`
  background-color: #212121;
  color: white;
  height: 36px;

  &:hover {
    background-color: #757575;
    color: white;
  }

  &:disabled {
    background-color: #999999;
    color: #cccccc;
  }
`;

const ErrorMessage = styled.div`
  color: #b22222;
  text-align: center;
  padding: 10px;
  margin: 10px;
`;

const UsersPieChart = inject('usersStore')(
  observer(({ usersStore }) => {
    const [startDate, setStartDate] = useState(
      moment().subtract(1, 'day').startOf('day').toDate(),
    );
    const [endDate, setEndDate] = useState(
      moment().subtract(1, 'day').endOf('day').toDate(),
    );
    const {
      usersPieChartData,
      usersPieChartDataTotal,
      loadingUsersPieChartData,
      fetchUsersPieChartData,
      pieChartError,
    } = usersStore;

    useEffect(() => {
      fetchUsersPieChartData(startDate.toISOString(), endDate.toISOString());
    }, []);

    return (
      <>
        <ResponsiveContainer width="100%" height={265}>
          {loadingUsersPieChartData || usersPieChartData.length === 0 ? (
            showLoaderOrPlaceHolder(loadingUsersPieChartData)
          ) : (
            <PieChart width={400} height={400}>
              <Tooltip content={<PercentTooltip />} />
              <Legend wrapperStyle={{ paddingBottom: '10px' }} />
              <Pie
                data={usersPieChartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                nameKey="medium"
              >
                {usersPieChartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          )}
        </ResponsiveContainer>
        <PieChartSubtitle>
          {'Suma:'} {usersPieChartDataTotal}
          <TooltipUI
            style={{ marginBottom: 3, width: '18px', marginLeft: '10px' }}
            title="Suma unikalnych urządzeń (które wystąpiły w więcej niż 3 zdarzeniach) dla danego zakresu dat dla danej aplikacji."
          >
            <InfoIcon htmlColor="#212121" />
          </TooltipUI>
        </PieChartSubtitle>
        {pieChartError && <ErrorMessage>{pieChartError}</ErrorMessage>}
        <Filters maxWidth="500px">
          <FilterBox center>
            Od:
            <PolishDatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<StyledInput width="120px" />}
              maxDate={endDate}
              disabled={loadingUsersPieChartData}
            />
          </FilterBox>
          <FilterBox>
            Do:
            <PolishDatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              customInput={<StyledInput width="120px" />}
              minDate={startDate}
              disabled={loadingUsersPieChartData}
            />
          </FilterBox>
          <StyledButton
            disabled={loadingUsersPieChartData}
            onClick={() =>
              fetchUsersPieChartData(
                startDate.toISOString(),
                endDate.toISOString(),
              )
            }
          >
            Filtruj
          </StyledButton>
        </Filters>
      </>
    );
  }),
);

export default UsersPieChart;

UsersPieChart.propTypes = {
  from: PropTypes.any,
  to: PropTypes.any,
};
