import React from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import TableWithJson from '../../../components/TableWithJson/TableWithJson';
import { currencyFormatter } from '../../../components/helpers/utils';
import { hourFormatter } from 'components/utils/hourFormatter';

const columns = [
  { id: 'hour', label: 'Godzina', minWidth: 100, format: hourFormatter },
  {
    id: 'today',
    label: 'dziś',
    minWidth: 100,
    format: currencyFormatter.format,
    align: 'right',
  },
  {
    id: 'yesterday',
    label: 'wczoraj',
    minWidth: 100,
    format: currencyFormatter.format,
    align: 'right',
  },
  {
    id: 'week',
    label: 'WoW',
    minWidth: 100,
    format: currencyFormatter.format,
    align: 'right',
  },
];

function PurchaseTable({ purchaseStore }) {
  const { purchaseHourly, loadingPurchaseHourlyData } = purchaseStore;

  return (
    <TableWithJson
      columns={columns}
      data={purchaseHourly}
      loadingData={loadingPurchaseHourlyData}
      withPagination={false}
      showJson={false}
    />
  );
}

export default inject('purchaseStore')(observer(PurchaseTable));

PurchaseTable.propTypes = {
  purchaseStore: PropTypes.shape({
    purchaseHourly: PropTypes.array,
    purchaseHourlyCount: PropTypes.number,
    loadingPurchaseHourlyData: PropTypes.bool,
  }),
};
