import TooltipUI from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';

export const InfoTip = () => {
  return (
    <TooltipUI
      style={{ paddingTop: 3, marginLeft: 9 }}
      title={
        <div>
          <div>
            Dla każdego koszyka zakupowego każdego użytkownika z danego dnia
            wyszukiwane są elementy kliknięte przez 7 dni poprzedzających zakup.
          </div>
          <div>
            Następnie wartość danego koszyka jest rozkładana na kliknięte
            elementy według zasad:
          </div>
          <ul>
            <li>1 element - 100% kwoty zakupu</li>
            <li>2 elementy - każdy po 50% kwoty zakupu</li>
            <li>
              3 i więcej - pierwszy i ostatni 30% kwoty zakupu, na środkowe
              równo podzielone 40% kwoty zakupu.
            </li>
          </ul>
        </div>
      }
    >
      <InfoIcon htmlColor="#212121" />
    </TooltipUI>
  );
};
