import styled from 'styled-components';

export const Error = styled.div`
  display: block;
  text-align: center;
  font-style: italic;
  color: red;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
