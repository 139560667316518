export function getProperValueBasedOnSortedCollections(
  collectionWithValue,
  collectionWithValueIndex,
  sortingCollection,
  sortingCollectionIndex,
  skuKey,
) {
  if (
    collectionWithValue[collectionWithValueIndex] &&
    collectionWithValue[collectionWithValueIndex][skuKey] ===
      sortingCollection[sortingCollectionIndex]
  ) {
    const { value, sum, position } =
      collectionWithValue[collectionWithValueIndex];
    return {
      value: value || sum || 0,
      index: collectionWithValueIndex + 1,
      position,
    };
  } else {
    return { value: 0, index: collectionWithValueIndex, position: 1000 };
  }
}

export function generateColumnsForMergedCollectionPLBased(
  availableMasks,
  valueLabel,
  limit,
  format,
) {
  const columns = [
    { id: 'productName', label: 'Nazwa', minWidth: 100 },
    { id: 'sku', label: 'SKU', minWidth: 100 },
    {
      id: 'four_fstore_plPosition',
      label: 'Pozycja (four_fstore_pl)',
      minWidth: 100,
      format: (pos) => (pos === 1001 ? `${limit}+` : pos),
    },
    {
      id: 'four_fstore_plValue',
      label: `${valueLabel} (four_fstore_pl)`,
      minWidth: 100,
      format: format,
    },
  ];
  availableMasks.forEach((mask) => {
    if (mask !== 'four_fstore_pl') {
      columns.push({
        id: `${mask}Position`,
        label: `Pozycja (${mask})`,
        minWidth: 100,
        format: (pos) => (pos === 1001 ? `${limit}+` : pos),
      });
      columns.push({
        id: `${mask}Value`,
        label: `${valueLabel} (${mask})`,
        minWidth: 100,
        format: format,
      });
    }
  });
  return columns;
}
