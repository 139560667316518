import React from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Button from '@material-ui/core/Button';

function PurchaseShortcuts({ purchaseStore }) {
  return (
    <div style={{ textAlign: 'center' }}>
      {purchaseStore.shortcuts.map((shortcut) => {
        return (
          <Button
            style={{
              background:
                purchaseStore.buttonDate === shortcut.label
                  ? '#b0b0b0'
                  : 'unset',
            }}
            onClick={() => purchaseStore.setShortcuts(shortcut.label)}
            key={shortcut.label}
          >
            {shortcut.label}
          </Button>
        );
      })}
    </div>
  );
}
export default inject('purchaseStore')(observer(PurchaseShortcuts));

PurchaseShortcuts.propTypes = {
  purchaseStore: PropTypes.shape({
    shortcuts: PropTypes.array,
    buttonDate: PropTypes.string,
    setShortcuts: PropTypes.func,
  }),
};
