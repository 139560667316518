import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import {
  BaseOption,
  customDropdownStyles,
  FilterBox,
  Filters,
  mediums,
  StyledInput,
} from '../../../components/helpers/chartHelpers';
import Select from 'react-select';
import moment from 'moment';
import { Check, Close } from '@material-ui/icons';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import TableWithJson from '../../../components/TableWithJson/TableWithJson';
import GreenCheckbox from '../../../components/GreenCheckbox';
import { currencyFormatter } from '../../../components/helpers/utils';

const columns = [
  { id: 'timestamp', label: 'Czas zdarzenia', minWidth: 100 },
  { id: 'user_email', label: 'Email', minWidth: 100 },
  { id: 'medium', label: 'Medium', minWidth: 100 },
  { id: 'action', label: 'Akcja', minWidth: 100 },
  { id: 'object', label: 'Szczegóły', minWidth: 100 },
  {
    id: 'voucher_code',
    label: 'Voucher',
    minWidth: 100,
    format: (value) => showProperIcon(value),
  },
];

const purchaseColumns = columns.concat([
  {
    id: 'total_price_pln',
    label: 'Wartość (zł)',
    minWidth: 100,
    format: currencyFormatter.format,
  },
  {
    id: 'discount_value_pln',
    label: 'Rabat (zł)',
    minWidth: 100,
    format: (value) =>
      value === null || value === undefined
        ? '-'
        : currencyFormatter.format(value),
  },
  {
    id: 'discount_value_percent',
    label: 'Rabat (%)',
    minWidth: 100,
    format: (value) => (value === null || value === undefined ? '-' : value),
  },
]);

const showProperIcon = (value) => {
  return value ? <Check /> : <Close />;
};

function UsersTable({
  usersStore: {
    fetchUserEvents,
    userEvents,
    userEventsCount,
    actions,
    loadingActions,
    loadingUserEventsData,
    setChosenAction,
    fetchPurchaseTotalPln,
  },
  shortcutFrom,
  shortcutTo,
}) {
  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'day').startOf('day').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());
  const [action, setAction] = useState();
  const [medium, setMedium] = useState();
  const [email, setEmail] = useState();
  const [voucherCode, setVoucherCode] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await fetchUserEvents({
      limit: rowsPerPage,
      page: newPage,
      from: startDate,
      to: endDate,
      email,
      medium,
      action,
      voucherCode,
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    await fetchUserEvents({
      limit: event.target.value,
      page: 0,
      from: startDate,
      to: endDate,
      email,
      medium,
      action,
      voucherCode,
    });
  };

  useEffect(() => {
    if (shortcutFrom) {
      setStartDate(shortcutFrom);
    }
    if (shortcutTo) {
      setEndDate(shortcutTo);
    }
  }, [shortcutFrom, shortcutTo]);

  useEffect(() => {
    fetchUserEvents({
      limit: rowsPerPage,
      page: 0,
      from: startDate,
      to: endDate,
      email,
      medium,
      action,
      voucherCode,
    });
    if (action === 'purchase') {
      fetchPurchaseTotalPln({
        from: startDate,
        to: endDate,
        email,
        medium,
        action,
        voucherCode,
      });
    }
  }, [startDate, endDate, email, medium, action, voucherCode]);

  return (
    <>
      <Filters className="no-horizontal-margin">
        <DateRangePicker
          startDate={startDate}
          onStartDateChange={(date) => setStartDate(date)}
          endDate={endDate}
          onEndDateChange={(date) => setEndDate(date)}
        />
        <FilterBox>
          Email:
          <StyledInput
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </FilterBox>
        <FilterBox>
          Medium:
          <Select
            placeholder="Wybierz..."
            options={mediums}
            value={mediums.filter((option) => option.value === medium)}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            styles={customDropdownStyles}
            components={{ Option: BaseOption }}
            onChange={(option) => setMedium(option.value)}
          />
        </FilterBox>
        <FilterBox>
          Akcja:
          <Select
            placeholder="Wybierz..."
            options={actions}
            isLoading={loadingActions}
            value={actions.filter((option) => option.value === action)}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            styles={customDropdownStyles}
            components={{ Option: BaseOption }}
            onChange={(option) => {
              setAction(option.value);
              setChosenAction(option.value);
            }}
          />
        </FilterBox>
        <FilterBox>
          Tylko z voucherem:
          <GreenCheckbox
            checked={voucherCode}
            onChange={(event) => setVoucherCode(event.target.checked)}
          />
        </FilterBox>
      </Filters>
      <TableWithJson
        columns={action === 'purchase' ? purchaseColumns : columns}
        data={userEvents}
        loadingData={loadingUserEventsData}
        dataCount={userEventsCount}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </>
  );
}

export default inject('usersStore')(observer(UsersTable));

UsersTable.propTypes = {
  usersStore: PropTypes.shape({
    fetchUserEvents: PropTypes.func,
    userEvents: PropTypes.array,
    userEventsCount: PropTypes.number,
    actions: PropTypes.array,
    setChosenAction: PropTypes.func,
    fetchPurchaseTotalPln: PropTypes.func,
    loadingActions: PropTypes.bool,
    loadingUserEventsData: PropTypes.bool,
  }),
  shortcutFrom: PropTypes.any,
  shortcutTo: PropTypes.any,
};
