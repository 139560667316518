import React, { Component } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { Subtitle, Title } from '../../components/helpers/chartHelpers';
import TopBannersGlobalTable from './Components/TopBannersGlobalTable';
import BannersBarChart from './Components/BannersBarChart';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import TopBannersPerMaskTable from './Components/TopBannersPerMaskTable';
import MaskSelect from './Components/MaskSelect';
import MaskSelectDateRange from './Components/MaskSelectDateRange';
import TopBannersPerMaskTableDateRange from './Components/TopBannersPerMaskTableDateRange';
import DashboardElementConversion from './Components/DashboardElementConversion';
import { InfoTip } from './Components/InfoTip';
import TopBannersComparisonTable from './Components/TopBannersComparisonTable';

@observer
@inject('bannersStore')
class BannersPageContainer extends Component {
  componentDidMount() {
    this.props.bannersStore.fetchMasks();
  }

  render() {
    return (
      <div>
        <GridContainer spacing={3} style={{ padding: 20, paddingBottom: 100 }}>
          <GridItem xs={12}>
            <Title color="#212121">Banery</Title>
          </GridItem>
          <GridItem xs={12}>
            <BannersBarChart />
          </GridItem>
          <GridItem xs={12}>
            <Subtitle color="#212121">Top 100 banerów (globalnie)</Subtitle>
          </GridItem>
          <GridItem xs={12}>
            <TopBannersGlobalTable />
          </GridItem>
          <GridItem xs={12}>
            <Subtitle color="#212121">
              Top banerów w bieżącym miesiącu (dla maski)
            </Subtitle>
          </GridItem>
          <GridItem xs={12}>
            <MaskSelect />
          </GridItem>
          <GridItem xs={12}>
            <TopBannersPerMaskTable />
          </GridItem>
          <GridItem xs={12}>
            <Subtitle color="#212121">Top banerów (dla maski)</Subtitle>
          </GridItem>
          <GridItem xs={12}>
            <MaskSelectDateRange />
          </GridItem>
          <GridItem xs={12}>
            <TopBannersPerMaskTableDateRange />
          </GridItem>
          <GridItem xs={12}>
            <Subtitle color="#212121">
              Top banerów (porównanie zakresów)
            </Subtitle>
          </GridItem>
          <GridItem xs={12}>
            <TopBannersComparisonTable />
          </GridItem>
          <GridItem xs={12}>
            <Subtitle color="#212121">
              Konwersja banerów
              <InfoTip />
            </Subtitle>
          </GridItem>
          <GridItem xs={12}>
            <DashboardElementConversion type="banner" label="banner" />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default BannersPageContainer;

BannersPageContainer.propTypes = {
  bannersStore: PropTypes.shape({
    fetchMasks: PropTypes.func,
    chosenMasks: PropTypes.array,
  }),
};
