import React from 'react';
import { isNumber } from 'lodash';
import * as PropTypes from 'prop-types';
import { FilterBox, StyledInput } from 'components/helpers/chartHelpers';
import { FlexRow } from '../styles';

export default function AmountRangeInput({
  minAmount,
  setMinAmount,
  maxAmount,
  setMaxAmount,
}) {
  const handleSetMin = (e) => {
    const newValue = Number(e.target.value);
    if (!e.target.value || !isNumber(newValue)) {
      setMinAmount();
      return;
    }
    if (newValue < 0) {
      return;
    }
    setMinAmount(newValue);
  };

  const handleSetMax = (e) => {
    const newValue = Number(e.target.value);
    if (!e.target.value || !isNumber(newValue)) {
      setMaxAmount();
      return;
    }
    if (newValue < 0) {
      return;
    }
    setMaxAmount(newValue);
  };

  return (
    <FlexRow width="max-content">
      <FilterBox>
        Wartość koszyka (od):
        <StyledInput
          onChange={handleSetMin}
          value={isNumber(minAmount) ? minAmount : ''}
          type="number"
          width={200}
          step="0.01"
        />
      </FilterBox>
      <FilterBox>
        Wartość koszyka (do):
        <StyledInput
          onChange={handleSetMax}
          value={isNumber(maxAmount) ? maxAmount : ''}
          type="number"
          width={200}
          step="0.01"
        />
      </FilterBox>
    </FlexRow>
  );
}

AmountRangeInput.propTypes = {
  minAmount: PropTypes.number,
  setMinAmount: PropTypes.func,
  maxAmount: PropTypes.number,
  setMaxAmount: PropTypes.func,
};
