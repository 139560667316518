import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import TableWithJson from '../../../components/TableWithJson/TableWithJson';
import { StyledInput } from '../../../components/helpers/chartHelpers';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import Select from 'react-select';
import useDebounce from '../../../hooks/useDebounce';

const HalfContainer = styled(Flex)`
  width: 50%;
  justify-content: flex-start;
  line-height: 36px;
`;

const DatePickerContainer = styled(Flex)`
  margin-bottom: 20px;
  flex-direction: row;
`;

function SearchPhrasesNextStepTable({
  productsStore: {
    isLoadingNextStepData,
    nextStepData,
    fetchNextStepsData,
    loadingAvailableSearchPhrasesMasks,
    availableMasksSearchPhrases,
    fetchMasksForSearchPhrases,
    phraseForNextStepData,
    setPhraseForNextStepData,
    startDateNextStep: startDate,
    setStartDateNextStep: setStartDate,
    endDateNextStep: endDate,
    setEndDateNextStep: setEndDate,
  },
}) {
  const [mask, setMask] = useState(null);

  useDebounce(phraseForNextStepData, 300, () =>
    fetchNextStepsData({
      timeFrom: startDate,
      timeTo: endDate,
      store: mask,
      searchPhrase: phraseForNextStepData,
    }),
  );

  useEffect(() => {
    fetchMasksForSearchPhrases({
      previousTimeFrom: startDate,
      timeTo: endDate,
      isNextStep: true,
    });
  }, [startDate, endDate]);
  useEffect(() => {}, []);

  useEffect(() => {
    fetchNextStepsData({
      timeFrom: startDate,
      timeTo: endDate,
      store: mask,
      searchPhrase: phraseForNextStepData,
    });
  }, [startDate, endDate, mask]);

  return (
    <div>
      <DatePickerContainer>
        <HalfContainer style={{ marginRight: 'auto' }}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            onStartDateChange={setStartDate}
            showTime={false}
          />
          <div
            style={{
              width: 250,
              marginLeft: 10,
              marginTop: 1,
              display: 'flex',
            }}
          >
            Fraza:
            <StyledInput
              placeholder="Fraza"
              onChange={(e) => setPhraseForNextStepData(e.target.value)}
              value={phraseForNextStepData}
            />
          </div>
        </HalfContainer>
        <div style={{ width: '30%' }}>
          <Select
            id="next-step-table"
            isLoading={loadingAvailableSearchPhrasesMasks}
            placeholder="Wybierz maski..."
            getOptionLabel={(option) => option}
            getOptionValue={(option) => option}
            value={availableMasksSearchPhrases.filter(
              (maskVal) => maskVal === mask,
            )}
            options={availableMasksSearchPhrases}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            onChange={(option) => {
              setMask(option);
            }}
          />
        </div>
      </DatePickerContainer>
      <TableWithJson
        columns={columns}
        data={nextStepData}
        loadingData={isLoadingNextStepData}
        withPagination={false}
        showJson={false}
      />
    </div>
  );
}

const columns = [
  { id: 'nextStepValue', label: 'Wynik kolejnego kroku', minWidth: 100 },
  { id: 'type', label: 'Typ kolejnego kroku', minWidth: 100 },
  { id: 'value', label: 'Liczba wystąpień', minWidth: 100 },
];

export default inject('productsStore')(observer(SearchPhrasesNextStepTable));

SearchPhrasesNextStepTable.propTypes = {
  productsStore: PropTypes.shape({
    fetchNextStepsData: PropTypes.func,
    nextStepData: PropTypes.array,
    isLoadingNextStepData: PropTypes.bool,
    loadingAvailableSearchPhrasesMasks: PropTypes.bool,
    availableMasksSearchPhrases: PropTypes.array,
    fetchMasksForSearchPhrases: PropTypes.func,
    setPhraseForNextStepData: PropTypes.func,
    phraseForNextStepData: PropTypes.string,
    startDateNextStep: PropTypes.any,
    setStartDateNextStep: PropTypes.any,
    endDateNextStep: PropTypes.any,
    setEndDateNextStep: PropTypes.any,
  }),
};
