import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import CustomLineChart from '../../../components/CustomLineChart/CustomLineChart';
import moment from 'moment';
import MaskSelectDateRange from '../../SalesPage/Components/MaskSelectDateRange';
import { currencyFormatter } from '../../../components/helpers/utils';

const colors = ['#272727', '#868686'];
const keysTranslation = {
  value: ['Wartość'],
  count: ['Ilość'],
};
const xAxis = 'fromTs';

const tooltipString = (value) => currencyFormatter.format(value);

const AbandonedCartsChart = ({ purchaseStore, type }) => {
  const {
    isLoadingAbandonedCarts,
    abandonedCarts,
    fetchAbandonedCarts,
    abandonedCartsMasks,
    getDatesKeyForAbandonedCarts,
    setCurrentDatesKeyForAbandonedCarts,
  } = purchaseStore;
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [chosenMask, setChosenMask] = useState('Wszystkie');
  const [isMounted, setIsMounted] = useState(false);

  const datesKey = useMemo(
    () => getDatesKeyForAbandonedCarts(startDate, endDate),
    [startDate, endDate],
  );

  useEffect(() => {
    if (startDate && endDate && isMounted) {
      if (!abandonedCarts[datesKey]) {
        fetchAbandonedCarts(startDate, endDate);
        setCurrentDatesKeyForAbandonedCarts(datesKey, type, chosenMask);
      }
    }
  }, [startDate, endDate, isMounted]);

  useEffect(() => {
    setCurrentDatesKeyForAbandonedCarts(datesKey, type, chosenMask);
  }, [chosenMask]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <CustomLineChart
        loadingData={isLoadingAbandonedCarts}
        data={
          abandonedCarts[datesKey] &&
          abandonedCarts[datesKey][chosenMask] &&
          abandonedCarts[datesKey][chosenMask].data
            ? abandonedCarts[datesKey][chosenMask].data
            : []
        }
        xAxisKey={xAxis}
        dataKeys={[type]}
        keysTranslation={keysTranslation[type]}
        keysColors={colors}
        valueFormatter={type === 'value' ? tooltipString : null}
      />
      <div style={{ width: '50%', margin: 'auto' }}>
        <MaskSelectDateRange
          chosenMask={chosenMask}
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          masks={abandonedCartsMasks}
          loadingMasks={isLoadingAbandonedCarts}
          setMask={setChosenMask}
        />
      </div>
    </>
  );
};
export default inject('purchaseStore')(observer(AbandonedCartsChart));

AbandonedCartsChart.propTypes = {
  purchaseStore: PropTypes.shape({
    isLoadingAbandonedCarts: PropTypes.bool,
    abandonedCarts: PropTypes.object,
    fetchAbandonedCarts: PropTypes.func,
    abandonedCartsMasks: PropTypes.arrayOf(PropTypes.string),
    getDatesKeyForAbandonedCarts: PropTypes.func,
    setCurrentDatesKeyForAbandonedCarts: PropTypes.func,
  }),
  type: PropTypes.string,
};
