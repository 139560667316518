import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { Subtitle, Title } from '../../components/helpers/chartHelpers';
import NotificationsTable from './Components/NotificationsTable';
import InboxOpenedTable from './Components/InboxOpenedTable';
import NotificationReadTable from './Components/NotificationReadTable';

function NotificationsPage() {
  return (
    <div>
      <GridContainer spacing={3} style={{ padding: 20 }}>
        <GridItem xs={12}>
          <Title color="#212121">Notyfikacje</Title>
        </GridItem>
        <GridItem xs={12}>
          <NotificationsTable />
        </GridItem>
        <GridItem xs={12}>
          <Subtitle color="#212121">Wejścia do skrzynek</Subtitle>
        </GridItem>
        <GridItem xs={12}>
          <InboxOpenedTable />
        </GridItem>
        <GridItem xs={12}>
          <Subtitle color="#212121">Wiadomości przeczytane</Subtitle>
        </GridItem>
        <GridItem xs={12}>
          <NotificationReadTable />
        </GridItem>
      </GridContainer>
    </div>
  );
}
export default NotificationsPage;
