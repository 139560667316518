import { action, observable } from 'mobx';
import config from '../config';
import moment from 'moment';
import 'moment/locale/pl';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import qs from 'qs';
import aggregationsStore from './aggregationsStore';
import { orderBy, sortBy } from 'lodash';
import { currencyFormatter } from '../components/helpers/utils';
import {
  generateColumnsForMergedCollectionPLBased,
  getProperValueBasedOnSortedCollections,
} from '../components/utils/collectionMergingUtils';

export class ProductsStore {
  @observable orderedTopGlobalMonthValues = [];
  @observable topSkuIdsMonth = [];

  @observable topProductsGlobal = [];
  @observable loadingTopProductsGlobal = true;

  @observable topProductsPerMask = [];
  @observable loadingTopProductsPerMask = true;
  @observable columnsPerMask = [];

  @observable availableMasks = [];
  @observable loadingAvailableMasks = true;

  @observable topCategories = [];
  @observable loadingTopCategories = true;

  @observable topCategoriesByMask = [];
  @observable availableMasksForCategories = [];
  @observable loadingTopCategoriesByMask = true;
  @observable chosenCategoriesMasks = [];

  @observable availableMasksForProducts = [];
  @observable loadingTopProductsPerMaskDropdown = true;
  @observable chosenProductsMasks = [];
  @observable topProductsForMask = [];

  @observable isLoadingTopSearchPhrases = false;
  @observable topSearchPhrases = [];

  @observable loadingAvailableSearchPhrasesMasks = false;

  @observable availableMasksSearchPhrases = [];

  @observable phraseForNextStepData = '';

  @observable nextStepData = [];

  @observable isLoadingNextStepData = false;

  @observable startDateNextStep = moment().startOf('week').toDate();
  @observable endDateNextStep = moment()
    .endOf('week')
    .add(1, 'day')
    .startOf('day')
    .toDate();

  @observable model = null;

  @observable productsCount = [];
  @observable isLoadingProductsCount = false;
  @observable productsCountSum = 0;

  limit = 100;

  constructor() {}

  @action.bound fetchProductsTop = async () => {
    this.loadingTopProductsGlobal = true;

    const monthQuery = {
      statId: config.statIds.productsValuesPerMaskPerSku,
      step: '1D',
      timeFrom: moment().startOf('month').toISOString(),
      timeTo: moment().toISOString(),
      key: '$objects_id',
      aggregateKey: '$value',
      limit: this.limit,
    };

    let { data: month } = await API.get(
      `${API_ROUTES.STATISTICS_TOP_PRODUCTS}?${qs.stringify(monthQuery)}`,
    );
    month.forEach((item, index) => (item.position = index + 1));
    let topSkuIds = month.map((item) => item._id);
    topSkuIds = sortBy(topSkuIds);
    this.topSkuIds = topSkuIds;

    const productsAmountStatId = config.statIds.productsCountPerMaskPerSku;
    const productsValueStatId = config.statIds.productsValuesPerMaskPerSku;

    const todayQuery = {
      step: '1H',
      timeFrom: moment().startOf('day').toISOString(),
      timeTo: moment().toISOString(),
      key: '$objects_id',
    };

    const yesterdayQuery = {
      step: '1H',
      timeFrom: moment().subtract(1, 'days').startOf('day').toISOString(),
      timeTo: moment().startOf('day').toISOString(),
      key: '$objects_id',
    };

    const weekQuery = {
      step: '1H',
      timeFrom: moment().subtract(7, 'days').startOf('day').toISOString(),
      timeTo: moment().startOf('day').toISOString(),
      key: '$objects_id',
    };

    const params = {
      objects_id: topSkuIds.toString(),
    };

    let {
      data: { sum: todayAmount },
    } = await aggregationsStore.fetchStatisticsSum(
      todayQuery.timeFrom,
      todayQuery.timeTo,
      todayQuery.step,
      productsAmountStatId,
      todayQuery.key,
      { params },
    );
    let {
      data: { sum: todayValue },
    } = await aggregationsStore.fetchStatisticsSum(
      todayQuery.timeFrom,
      todayQuery.timeTo,
      todayQuery.step,
      productsValueStatId,
      todayQuery.key,
      { params },
    );

    let {
      data: { sum: yesterdayAmount },
    } = await aggregationsStore.fetchStatisticsSum(
      yesterdayQuery.timeFrom,
      yesterdayQuery.timeTo,
      yesterdayQuery.step,
      productsAmountStatId,
      yesterdayQuery.key,
      { params },
    );
    let {
      data: { sum: yesterdayValue },
    } = await aggregationsStore.fetchStatisticsSum(
      yesterdayQuery.timeFrom,
      yesterdayQuery.timeTo,
      yesterdayQuery.step,
      productsValueStatId,
      yesterdayQuery.key,
      { params },
    );

    let {
      data: { sum: weekAmount },
    } = await aggregationsStore.fetchStatisticsSum(
      weekQuery.timeFrom,
      weekQuery.timeTo,
      weekQuery.step,
      productsAmountStatId,
      weekQuery.key,
      { params },
    );
    let {
      data: { sum: weekValue },
    } = await aggregationsStore.fetchStatisticsSum(
      weekQuery.timeFrom,
      weekQuery.timeTo,
      weekQuery.step,
      productsValueStatId,
      weekQuery.key,
      { params },
    );

    let {
      data: { sum: monthAmount },
    } = await aggregationsStore.fetchStatisticsSum(
      monthQuery.timeFrom,
      monthQuery.timeTo,
      monthQuery.step,
      productsAmountStatId,
      monthQuery.key,
      { params },
    );
    const monthValue = orderBy(month, '_id', 'asc');
    this.orderedTopGlobalMonthValues = monthValue;
    const collectionsToMerge = {
      todayAmount,
      todayValue,
      yesterdayAmount,
      yesterdayValue,
      weekAmount,
      weekValue,
      monthAmount,
      monthValue,
    };

    const indexes = {
      todayAmount: 0,
      todayValue: 0,
      yesterdayAmount: 0,
      yesterdayValue: 0,
      weekAmount: 0,
      weekValue: 0,
      monthAmount: 0,
      monthValue: 0,
    };

    const result = [];
    for (let i = 0; i < topSkuIds.length; i++) {
      const row = {};

      Object.keys(collectionsToMerge).forEach((collectionName) => {
        const { value, index } = getProperValueBasedOnSortedCollections(
          collectionsToMerge[collectionName],
          indexes[collectionName],
          topSkuIds,
          i,
          '_id',
        );
        row[collectionName] = value;
        indexes[collectionName] = index;
      });

      row.productName =
        collectionsToMerge.monthValue[indexes.monthValue - 1].productName;
      row.sku = collectionsToMerge.monthValue[indexes.monthValue - 1]._id;
      row.position =
        collectionsToMerge.monthValue[indexes.monthValue - 1].position;
      result.push(row);
    }

    this.topProductsGlobal = orderBy(result, 'monthValue', 'desc');
    this.loadingTopProductsGlobal = false;
  };

  @action fetchMasksData = async () => {
    this.loadingTopProductsPerMask = true;
    const topSkuIds = this.topSkuIds;
    const availableMasks = [];

    const allMasksData = {};
    const maskIndexes = {};

    const rawMaskData = await this.getMonthValuesForMask(topSkuIds, this.limit);
    let mask = null;
    for (mask of rawMaskData) {
      allMasksData[mask._id] = orderBy(mask.skuVal, 'sku', 'asc');
      maskIndexes[mask._id] = 0;
      availableMasks.push(mask._id);
    }

    const result = [];

    for (let i = 0; i < topSkuIds.length; i++) {
      const row = {};
      availableMasks.forEach((mask) => {
        const { value, index, position } =
          getProperValueBasedOnSortedCollections(
            allMasksData[mask],
            maskIndexes[mask],
            topSkuIds,
            i,
            'sku',
          );
        row[`${mask}Value`] = value;
        row[`${mask}Position`] = position + 1;
        maskIndexes[mask] = index;
      });
      row.productName = this.orderedTopGlobalMonthValues[i].productName;
      row.sku = this.orderedTopGlobalMonthValues[i]._id;
      result.push(row);
    }

    if (availableMasks.indexOf('four_fstore_pl' >= 0)) {
      this.topProductsPerMask = orderBy(
        result,
        'four_fstore_plPosition',
        'asc',
      );
    } else {
      this.topProductsPerMask = result;
    }

    this.columnsPerMask = generateColumnsForMergedCollectionPLBased(
      availableMasks,
      'Wartość',
      this.limit,
      currencyFormatter.format,
    );
    this.loadingTopProductsPerMask = false;
  };

  async getMonthValuesForMask(topSkuId, limit) {
    const monthQuery = {
      statId: config.statIds.productsValuesPerMaskPerSku,
      step: '1D',
      timeFrom: moment().startOf('month').toISOString(),
      timeTo: moment().toISOString(),
      skuIds: topSkuId.toString(),
      limit,
      skuKey: '$objects_id',
    };

    const { data: topByMask } = await API.get(
      `${API_ROUTES.TOP_PRODUCTS_BY_MASK}?${qs.stringify(monthQuery)}`,
    );
    return topByMask;
  }

  @action fetchMasks = async () => {
    this.loadingAvailableMasks = true;
    this.availableMasks = [];

    const currentMonthQuery = {
      statId: config.statIds.wishlistSumPerMask,
      step: '1D',
      timeFrom: moment().startOf('month').toISOString(),
      timeTo: moment().toISOString(),
      key: 'store',
    };

    const { data } = await API.get(
      `${API_ROUTES.STATISTIC_DISTINCT_KEY}?${qs.stringify(currentMonthQuery)}`,
    );
    this.availableMasks = data;
    this.loadingAvailableMasks = false;
  };

  @action fetchTopProductsCategories = async () => {
    this.loadingTopCategories = true;
    this.topCategories = [];

    const monthQuery = {
      statId: config.statIds.productsValuesPerMaskPerSku,
      step: '1D',
      timeFrom: moment().subtract(30, 'days').startOf('day').toISOString(),
      timeTo: moment().toISOString(),
      key: '$objects_id',
      aggregateKey: '$value',
      limit: this.limit,
    };

    let { data: categories } = await API.get(
      `${API_ROUTES.TOP_PRODUCTS_CATEGORIES}?${qs.stringify(monthQuery)}`,
    );

    this.topCategories = categories;
    this.loadingTopCategories = false;
  };

  @action fetchTopProductsCategoriesPerMask = async () => {
    this.loadingTopCategoriesByMask = true;
    this.topCategoriesByMask = [];

    const monthQuery = {
      statId: config.statIds.productsValuesPerMaskPerSku,
      step: '1D',
      timeFrom: moment().subtract(30, 'days').startOf('day').toISOString(),
      timeTo: moment().toISOString(),
      limit: this.limit,
      skuKey: '$objects_id',
    };

    const { data: topByMask } = await API.get(
      `${API_ROUTES.TOP_CATEGORIES_BY_MASK}?${qs.stringify(monthQuery)}`,
    );

    this.availableMasksForCategories = topByMask.map((top) => top._id);
    this.topCategoriesByMask = topByMask;
    this.loadingTopCategoriesByMask = false;
  };

  @action setChosenCategoriesMasks = (options) => {
    this.chosenCategoriesMasks = options;
  };

  @action setChosenProductsMasks = (options) => {
    this.chosenProductsMasks = options;
  };

  @action fetchTopProductsPerMask = async (
    fromDate = moment().subtract(1, 'month').toISOString(),
    toDate = moment().toISOString(),
    sortByAmount = false,
    model,
  ) => {
    this.loadingTopProductsPerMaskDropdown = true;
    this.topProductsForMask = [];

    let monthQuery = {
      statId: sortByAmount
        ? config.statIds.productsCountPerMaskPerSku
        : config.statIds.productsValuesPerMaskPerSku,
      step: '1D',
      timeFrom: moment(fromDate).toISOString(),
      timeTo: moment(toDate).toISOString(),
      limit: this.limit,
      skuKey: '$objects_id',
    };

    if (model) {
      monthQuery.model = model;
    }

    const { data: topByMaskValue } = await API.get(
      `${API_ROUTES.TOP_PRODUCTS_BY_MASK}?${qs.stringify(monthQuery)}`,
    );
    monthQuery.statId = sortByAmount
      ? config.statIds.productsValuesPerMaskPerSku
      : config.statIds.productsCountPerMaskPerSku;

    let item = null;

    for (item of topByMaskValue) {
      const mask = item._id;
      const params = { store: mask };
      let {
        data: { sum: monthAmountForMask },
      } = await aggregationsStore.fetchStatisticsSum(
        monthQuery.timeFrom,
        monthQuery.timeTo,
        monthQuery.step,
        monthQuery.statId,
        monthQuery.skuKey,
        { params },
      );
      let obj = {};

      monthAmountForMask.forEach((data) => {
        obj[data._id] = data.sum;
      });
      item.skuVal.forEach((valueObj) => {
        if (sortByAmount) {
          valueObj.amount = valueObj.value;
          valueObj.value = obj[valueObj.sku] || '-';
        } else {
          valueObj.amount = obj[valueObj.sku] || '-';
        }

        valueObj.position += 1;
      });
    }

    this.topProductsForMask = topByMaskValue;

    this.availableMasksForProducts = topByMaskValue.map(({ _id }) => _id);
    this.loadingTopProductsPerMaskDropdown = false;
  };

  @action fetchTopSearchPhrases = async ({
    timeFrom,
    timeTo,
    previousTimeFrom,
    previousTimeTo,
    mask: store,
  }) => {
    this.isLoadingTopSearchPhrases = true;
    this.topSearchPhrases = [];

    const query = {
      statId: store
        ? config.statIds.searchPhrasesWithNextStep
        : config.statIds.searchPhrasesCount,
      step: '1D',
      timeFrom: moment(timeFrom).startOf('day').toISOString(),
      timeTo: moment(timeTo).toISOString(),
      key: '$object_name',
      aggregateKey: '$value',
      limit: this.limit,
      store,
    };

    let { data: searchPhrases } = await API.get(
      `${API_ROUTES.STATISTICS_TOP}?${qs.stringify(query)}`,
    );

    const phrases = searchPhrases.map(({ _id }) => _id);

    const previousQuery = {
      statId: store
        ? config.statIds.searchPhrasesWithNextStep
        : config.statIds.searchPhrasesCount,
      step: '1D',
      timeFrom: moment(previousTimeFrom).startOf('day').toISOString(),
      timeTo: moment(previousTimeTo).toISOString(),
      key: '$object_name',
      aggregateKey: '$value',
      limit: this.limit,
      params: {
        object_name: phrases.toString(),
        store,
      },
    };

    let {
      data: { sum: searchPhrasesShadow },
    } = await aggregationsStore.fetchStatisticsSum(
      previousQuery.timeFrom,
      previousQuery.timeTo,
      previousQuery.step,
      previousQuery.statId,
      previousQuery.key,
      { params: previousQuery.params },
    );

    const shadowObj = {};

    searchPhrasesShadow.map(({ _id, sum }) => {
      shadowObj[_id] = sum;
    });

    this.topSearchPhrases = searchPhrases.map(({ _id, value }) => {
      const shadowVal = shadowObj[_id] || 0;
      const absoluteDelta = value - shadowVal;
      const percentDelta =
        shadowVal > 0 ? (value - shadowVal) / shadowVal : '-';
      return {
        _id,
        value,
        shadowVal,
        absoluteDelta,
        percentDelta,
      };
    });
    this.isLoadingTopSearchPhrases = false;
  };

  @action fetchMasksForSearchPhrases = async ({ previousTimeFrom, timeTo }) => {
    this.loadingAvailableSearchPhrasesMasks = true;
    this.availableMasksSearchPhrases = [];

    const currentMonthQuery = {
      statId: config.statIds.searchPhrasesWithNextStep,
      step: '1D',
      timeFrom: moment(previousTimeFrom).startOf('day').toISOString(),
      timeTo: moment(timeTo).toISOString(),
      key: 'store',
    };

    const { data } = await API.get(
      `${API_ROUTES.STATISTIC_DISTINCT_KEY}?${qs.stringify(currentMonthQuery)}`,
    );
    this.availableMasksSearchPhrases = data;
    this.loadingAvailableSearchPhrasesMasks = false;
  };

  @action setPhraseForNextStepData = async (value) => {
    this.phraseForNextStepData = value;
  };

  @action fetchNextStepsData = async ({
    timeFrom,
    timeTo,
    store,
    searchPhrase,
  }) => {
    this.isLoadingNextStepData = true;
    this.nextStepData = [];

    const query = {
      statId: config.statIds.searchPhrasesWithNextStep,
      step: '1D',
      timeFrom: moment(timeFrom).startOf('day').toISOString(),
      timeTo: moment(timeTo).toISOString(),
      groupingKey: 'next_step_type,next_step_value',
      aggregateKey: '$value',
      limit: this.limit,
      store,
      params: {
        object_name: searchPhrase,
      },
    };

    let { data: searchPhrases } = await API.get(
      `${API_ROUTES.STATISTICS_TOP}?${qs.stringify(query)}`,
    );

    this.nextStepData = searchPhrases.flatMap(
      ({
        _id: { next_step_value: nextStepValue, next_step_type: type },
        value,
      }) => {
        if (type === null) {
          return [];
        }
        return [
          {
            value,
            nextStepValue,
            type,
          },
        ];
      },
    );
    this.isLoadingNextStepData = false;
  };

  @action setStartDateNextStep = (value) => {
    this.startDateNextStep = value;
  };

  @action setEndDateNextStep = (value) => {
    this.endDateNextStep = value;
  };

  @action setModel = (value) => {
    this.model = value.trim();
  };

  @action fetchProductsCount = async ({ fromDate, toDate }) => {
    this.isLoadingProductsCount = true;
    this.productsCount = [];
    this.productsCountSum = 0;

    let query = {
      statId: config.statIds.productsCountPerMaskPerSku,
      step: '1D',
      timeFrom: moment(fromDate).startOf('day').toISOString(),
      timeTo: moment(toDate).add(1, 'day').startOf('day').toISOString(),
      key: '$store',
    };

    try {
      const {
        data: { sum: data },
      } = await aggregationsStore.fetchStatisticsSum(
        query.timeFrom,
        query.timeTo,
        query.step,
        query.statId,
        query.key,
      );

      let sum = 0;
      data.forEach((item) => {
        sum += item.sum;
      });

      data.sort((a, b) => b.sum - a.sum);

      this.productsCountSum = sum;
      this.productsCount = data;
      this.isLoadingProductsCount = false;
    } catch {
      this.productsCount = [];
      this.isLoadingProductsCount = false;
    }
  };
}

export default new ProductsStore();
