import styled from 'styled-components';

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props) => (props.width ? props.width : '100%')};
`;
export const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 15px;

  &.no-horizontal-margin {
    margin: 30px 0;
  }
`;

export const SortContainer = styled.div`
  width: 400px;
`;
