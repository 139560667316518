import TooltipUI from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';

export const InfoTip = () => {
  return (
    <TooltipUI
      style={{ paddingTop: 3, marginLeft: 9 }}
      title="Suma koszyków zakupowych, w których pojawiły się produkty wybrane na podstawie rekomendacji."
    >
      <InfoIcon htmlColor="#212121" />
    </TooltipUI>
  );
};
