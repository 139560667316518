import React from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { Subtitle, Title } from '../../components/helpers/chartHelpers';

import DashboardElementConversion from '../BannersPage/Components/DashboardElementConversion';
import { InfoTip } from '../BannersPage/Components/InfoTip';
import AppStoriesProductsClicks from './LandingPagesClicks';

const DashboardElementsPageContainer = () => (
  <div>
    <GridContainer spacing={3} style={{ padding: 20, paddingBottom: 100 }}>
      <GridItem xs={12}>
        <Title color="#212121">Landing pages i elementy</Title>
      </GridItem>
      <GridItem xs={12}>
        <Subtitle color="#212121">
          Konwersja
          <InfoTip />
        </Subtitle>
      </GridItem>
      <GridItem xs={12}>
        <DashboardElementConversion
          type="landingPage"
          label="landing page lub jego element"
          showSelectCheckbox={true}
        />
      </GridItem>
      <GridItem xs={12}>
        <Subtitle color="#212121">
          Kliknięcia w landing page i elementy
        </Subtitle>
      </GridItem>
      <GridItem xs={12}>
        <AppStoriesProductsClicks />
      </GridItem>
    </GridContainer>
  </div>
);

export default DashboardElementsPageContainer;
