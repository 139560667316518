import { Modal } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';

export const MediaPopup = ({ handleClose, open, imageUrl, videoUrl }) => (
  <Modal open={open} onClose={handleClose}>
    <div
      onClick={handleClose}
      style={{ display: 'flex', justifyContent: 'center', margin: 20 }}
    >
      {imageUrl && !videoUrl && imageUrl !== '' ? (
        <img style={{ maxHeight: '90vh', maxWidth: '90vw' }} src={imageUrl} />
      ) : null}
      {videoUrl && videoUrl !== '' ? (
        <video
          style={{ maxHeight: '90vh', maxWidth: '90vw' }}
          controls
          src={videoUrl}
        />
      ) : null}
    </div>
  </Modal>
);

MediaPopup.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  imageUrl: PropTypes.string,
  videoUrl: PropTypes.string,
};
