import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import {
  BaseOption,
  customDropdownStyles,
  FilterBox,
  mediums,
  StyledInput,
} from 'components/helpers/chartHelpers';
import Select from 'react-select';
import moment from 'moment';
import { Check, Close } from '@material-ui/icons';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import TableWithJson from 'components/TableWithJson/TableWithJson';
import GreenCheckbox from 'components/GreenCheckbox';
import { currencyFormatter } from 'components/helpers/utils';
import AmountRangeInput from './AmountRangeInput';
import { Filters, FlexRow, SortContainer } from '../styles';
import useDebounce from '../../../hooks/useDebounce';

const ascSymbol = '\u2191';
const descSymbol = '\u2193';
const sortOptions = [
  { label: `${ascSymbol} Wartość koszyka`, value: 'total_price_pln+1' },
  { label: `${descSymbol} Wartość koszyka`, value: 'total_price_pln+-1' },
];

const purchaseColumns = [
  { id: 'timestamp', label: 'Czas zdarzenia', minWidth: 100 },
  { id: 'user_email', label: 'Email', minWidth: 100 },
  { id: 'medium', label: 'Medium', minWidth: 100 },
  { id: 'object', label: 'Szczegóły', minWidth: 100 },
  {
    id: 'voucher_code',
    label: 'Voucher',
    minWidth: 100,
    format: (value) => showProperIcon(value),
  },
  {
    id: 'total_price_pln',
    label: 'Wartość (zł)',
    minWidth: 100,
    format: currencyFormatter.format,
  },
  {
    id: 'discount_value_pln',
    label: 'Rabat (zł)',
    minWidth: 100,
    format: (value) =>
      value === null || value === undefined
        ? '-'
        : currencyFormatter.format(value),
  },
  {
    id: 'discount_value_percent',
    label: 'Rabat (%)',
    minWidth: 100,
    format: (value) => (value === null || value === undefined ? '-' : value),
  },
];

const showProperIcon = (value) => {
  return value ? <Check /> : <Close />;
};

function PurchaseTable({
  usersStore: {
    fetchUserEvents,
    userEvents,
    userEventsCount,
    loadingUserEventsData,
    fetchPurchaseTotalPln,
  },
  couponsStore: {
    fetchCouponPools,
    couponPools,
    isLoadingCouponPools,
    fetchActivationCodePools,
    activationCodePools,
    isLoadingActivationCodePools,
  },
  shortcutFrom,
  shortcutTo,
}) {
  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'day').startOf('day').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());
  const [medium, setMedium] = useState();
  const [sort, setSort] = useState();
  const [email, setEmail] = useState();
  const [voucherCode, setVoucherCode] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPriceMin, setTotalPriceMin] = useState();
  const [totalPriceMax, setTotalPriceMax] = useState();
  const [couponPool, setCouponPool] = useState(null);
  const [codeSource, setCodeSource] = useState(null);
  const [cashbackCode, setCashbackCode] = useState(null);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await fetchUserEvents({
      limit: rowsPerPage,
      page: newPage,
      from: startDate,
      to: endDate,
      email,
      medium,
      action: 'purchase',
      voucherCode,
      totalPricePlnGte: totalPriceMin,
      totalPricePlnLte: totalPriceMax,
      sort,
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    await fetchUserEvents({
      limit: event.target.value,
      page: 0,
      from: startDate,
      to: endDate,
      email,
      medium,
      action: 'purchase',
      voucherCode,
      totalPricePlnGte: totalPriceMin,
      totalPricePlnLte: totalPriceMax,
      sort,
      couponPool,
      codeSource,
      cashbackCode,
    });
  };

  useEffect(() => {
    if (shortcutFrom) {
      setStartDate(shortcutFrom);
    }
    if (shortcutTo) {
      setEndDate(shortcutTo);
    }
  }, [shortcutFrom, shortcutTo]);

  const fetchData = () => {
    fetchUserEvents({
      limit: rowsPerPage,
      page: 0,
      from: startDate,
      to: endDate,
      email,
      medium,
      action: 'purchase',
      voucherCode,
      totalPricePlnGte: totalPriceMin,
      totalPricePlnLte: totalPriceMax,
      sort,
      couponPool,
      codeSource,
      cashbackCode,
    });

    fetchPurchaseTotalPln({
      from: startDate,
      to: endDate,
      email,
      medium,
      action: 'purchase',
      voucherCode,
      totalPricePlnGte: totalPriceMin,
      totalPricePlnLte: totalPriceMax,
      couponPool,
      codeSource,
      cashbackCode,
    });
  };

  useEffect(() => {
    fetchData();
  }, [
    startDate,
    endDate,
    email,
    medium,
    voucherCode,
    sort,
    codeSource,
    couponPool,
    cashbackCode,
  ]);

  useEffect(() => {
    fetchCouponPools({});
    fetchActivationCodePools({});
  }, []);

  useDebounce(totalPriceMax, 300, () => fetchData());
  useDebounce(totalPriceMin, 300, () => fetchData());

  return (
    <>
      <Filters className="no-horizontal-margin">
        <FlexRow>
          <DateRangePicker
            startDate={startDate}
            minDateFrom={moment(endDate).subtract(31, 'days').toDate()}
            maxDateTo={moment(startDate).add(31, 'days').toDate()}
            onStartDateChange={(date) => setStartDate(date)}
            endDate={endDate}
            onEndDateChange={(date) => setEndDate(date)}
          />
          <FilterBox>
            Email:
            <StyledInput
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </FilterBox>
          <FilterBox>
            Medium:
            <Select
              isClearable
              placeholder="Wybierz..."
              options={mediums}
              value={mediums.filter((option) => option.value === medium)}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              styles={customDropdownStyles}
              components={{ Option: BaseOption }}
              onChange={(option) => setMedium(option ? option.value : null)}
            />
          </FilterBox>
          <FilterBox>
            Tylko z voucherem:
            <GreenCheckbox
              checked={voucherCode}
              onChange={(event) => setVoucherCode(event.target.checked)}
            />
          </FilterBox>
          <FilterBox>
            Tylko z cashback:
            <GreenCheckbox
              checked={cashbackCode}
              onChange={(event) => setCashbackCode(event.target.checked)}
            />
          </FilterBox>
        </FlexRow>
        <AmountRangeInput
          minAmount={totalPriceMin}
          setMinAmount={setTotalPriceMin}
          maxAmount={totalPriceMax}
          setMaxAmount={setTotalPriceMax}
        />
        <SortContainer>
          <FilterBox>
            Pule kodów:
            <Select
              isClearable
              placeholder="Wybierz..."
              options={couponPools}
              isLoading={isLoadingCouponPools}
              styles={customDropdownStyles}
              components={{ Option: BaseOption }}
              onChange={(option) => setCouponPool(option ? option.value : null)}
            />
          </FilterBox>
        </SortContainer>
        <SortContainer>
          <FilterBox>
            Źródła subskrypcji:
            <Select
              isClearable
              placeholder="Wybierz..."
              options={activationCodePools}
              styles={customDropdownStyles}
              components={{ Option: BaseOption }}
              isLoading={isLoadingActivationCodePools}
              onChange={(option) => setCodeSource(option ? option.value : null)}
            />
          </FilterBox>
        </SortContainer>
        <SortContainer>
          <FilterBox>
            Sortuj:
            <Select
              isClearable
              placeholder="Wybierz..."
              options={sortOptions}
              value={sortOptions.filter((option) => option.value === sort)}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              styles={customDropdownStyles}
              components={{ Option: BaseOption }}
              onChange={(option) => setSort(option ? option.value : null)}
            />
          </FilterBox>
        </SortContainer>
      </Filters>
      <TableWithJson
        columns={purchaseColumns}
        data={userEvents}
        loadingData={loadingUserEventsData}
        dataCount={userEventsCount}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </>
  );
}

export default inject('usersStore', 'couponsStore')(observer(PurchaseTable));

PurchaseTable.propTypes = {
  usersStore: PropTypes.shape({
    fetchUserEvents: PropTypes.func,
    userEvents: PropTypes.array,
    userEventsCount: PropTypes.number,
    fetchPurchaseTotalPln: PropTypes.func,
    loadingUserEventsData: PropTypes.bool,
  }),
  couponsStore: PropTypes.shape({
    fetchCouponPools: PropTypes.func,
    couponPools: PropTypes.array,
    isLoadingCouponPools: PropTypes.bool,
    fetchActivationCodePools: PropTypes.func,
    activationCodePools: PropTypes.array,
    isLoadingActivationCodePools: PropTypes.bool,
  }),
  shortcutFrom: PropTypes.any,
  shortcutTo: PropTypes.any,
};
