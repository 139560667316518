import React from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import TableWithJson from '../../../components/TableWithJson/TableWithJson';
import {
  currencyFormatter,
  percentFormatter,
} from '../../../components/helpers/utils';

const columns = [
  { id: 'mask', label: 'Maska', minWidth: 100 },
  {
    id: 'today',
    label: 'Rabaty dziś',
    minWidth: 100,
    format: currencyFormatter.format,
  },
  {
    id: 'todayShare',
    label: 'Udział %',
    minWidth: 100,
    format: percentFormatter.format,
  },
  {
    id: 'month',
    label: 'Bieżący miesiąc',
    minWidth: 100,
    format: currencyFormatter.format,
  },
  {
    id: 'monthShare',
    label: 'Udział %',
    minWidth: 100,
    format: percentFormatter.format,
  },
];

function DiscountPerMaskTable({ discountStore }) {
  const { discountsPerMask, loadingDiscountsPerMask } = discountStore;

  return (
    <TableWithJson
      columns={columns}
      data={discountsPerMask}
      loadingData={loadingDiscountsPerMask}
      withPagination={false}
      showJson={false}
    />
  );
}

export default inject('discountStore')(observer(DiscountPerMaskTable));

DiscountPerMaskTable.propTypes = {
  discountStore: PropTypes.shape({
    discountsPerMask: PropTypes.array,
    loadingDiscountsPerMask: PropTypes.bool,
  }),
};
