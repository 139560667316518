import styled from 'styled-components';

export const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${(props) => props.width}px;
  height: 452px;
  padding-right: 10px;
`;

export const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 0 10px;
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

export const labelFormatter = (value) => `${value.toFixed(2)} zł`;
