import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import VerticalBarChart from './VerticalBarChart';
import { Filters } from '../../../components/helpers/chartHelpers';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import moment from 'moment';
import {
  Label,
  labelFormatter,
  LabelsContainer,
} from './VerticalBarChartLabel';
import GreenCheckbox from '../../../components/GreenCheckbox';

const keyNames = ['value', 'shadow'];
const keyTranslations = ['Średnia wartość koszyka', 'W zeszłym miesiącu'];
const keyColors = ['#272727', '#868686'];

const AvgTotalPricePerMaskChart = inject('purchaseStore')(
  observer(({ purchaseStore }) => {
    const {
      loadingAvgTotalPricePerMask,
      avgTotalPricePerMask,
      fetchAvgTotalPricePerMask,
      shortcutFrom,
      shortcutTo,
    } = purchaseStore;

    const [startDate, setStartDate] = useState(
      moment().startOf('month').toDate(),
    );
    const [endDate, setEndDate] = useState(moment().toDate());
    const [onlyLoggedIn, setOnlyLoggedIn] = useState(false);

    useEffect(() => {
      if (startDate && endDate) {
        fetchAvgTotalPricePerMask(startDate, endDate, onlyLoggedIn);
      }
    }, [startDate, endDate, onlyLoggedIn]);

    useEffect(() => {
      if (shortcutFrom && shortcutTo) {
        setStartDate(shortcutFrom);
        setEndDate(shortcutTo);
      }
    }, [shortcutFrom, shortcutTo]);

    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '100%' }}>
            <VerticalBarChart
              loadingData={loadingAvgTotalPricePerMask}
              chartData={avgTotalPricePerMask}
              keyNames={keyNames}
              dataKey="_id"
              keyTranslations={keyTranslations}
              keyColors={keyColors}
              labelFormatter={labelFormatter}
            />
          </div>
          <LabelsContainer width={250}>
            {avgTotalPricePerMask.length > 0 &&
              avgTotalPricePerMask.map((item, index) => (
                <Label key={index}>
                  {labelFormatter(item.value)}&nbsp;
                  <span style={{ color: '#868686' }}>
                    ({labelFormatter(item.shadow)})
                  </span>
                </Label>
              ))}
          </LabelsContainer>
        </div>

        <div style={{ width: '55%', margin: 'auto' }}>
          <Filters>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onEndDateChange={setEndDate}
              onStartDateChange={setStartDate}
              showTime={false}
            />
          </Filters>
          <>
            Pokaż tylko dla zalogowanych użytkowników:
            <GreenCheckbox
              checked={onlyLoggedIn}
              onChange={(event) => setOnlyLoggedIn(event.target.checked)}
            />
          </>
        </div>
      </>
    );
  }),
);

export default AvgTotalPricePerMaskChart;

AvgTotalPricePerMaskChart.propTypes = {};
