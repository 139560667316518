import { ResponsiveContainer } from 'recharts';
import {
  BaseOption,
  customDropdownStyles,
  FilterBox,
  Filters,
  showLoaderOrPlaceHolder,
  StyledInput,
  mediums,
  showLineChart,
  formatDate,
} from '../../../components/helpers/chartHelpers';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Select from 'react-select';
import { inject, observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import PolishDatePicker from '../../../components/PolishDatePicker/PolishDatePicker';
import InfoIcon from '@material-ui/icons/Info';
import TooltipUI from '@material-ui/core/Tooltip';

const UsersByMediumCharts = inject('usersStore')(
  observer(({ usersStore }) => {
    const [startDate, setStartDate] = useState(
      moment().subtract(1, 'month').startOf('day').toDate(),
    );
    const [endDate, setEndDate] = useState(moment().endOf('day').toDate());
    const [medium, setMedium] = useState('all');
    const {
      loadingUsersByMediumBarChartData,
      usersByMediumBarChartData,
      fetchUsersByMediumBarChartData,
    } = usersStore;

    const mediumsWithAll = mediums.concat([
      { value: 'all', label: 'Wszystkie' },
    ]);

    useEffect(() => {
      if (medium) {
        fetchUsersByMediumBarChartData(
          startDate.toISOString(),
          endDate.toISOString(),
          '1D',
          medium,
        );
      }
    }, [startDate, endDate, medium]);

    return (
      <>
        <Filters>
          <FilterBox>
            Od:
            <PolishDatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<StyledInput width="400px" />}
              maxDate={endDate}
            />
          </FilterBox>
          <FilterBox>
            Do:
            <PolishDatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              customInput={<StyledInput width="400px" />}
              minDate={startDate}
            />
          </FilterBox>
          <FilterBox>
            Medium:
            <Select
              placeholder="Wybierz..."
              options={mediumsWithAll}
              value={mediumsWithAll.filter((option) => option.value === medium)}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              styles={customDropdownStyles}
              components={{ Option: BaseOption }}
              onChange={(option) => setMedium(option.value)}
            />
            <TooltipUI
              style={{ paddingTop: 3, marginLeft: 20 }}
              title="Unikalne urządzenia (które wystąpiły w minimum 3 zdarzeniach) dla danego zakresu dat na podstawie wszystkich zdarzeń"
            >
              <InfoIcon htmlColor="#212121" />
            </TooltipUI>
          </FilterBox>
        </Filters>
        <ResponsiveContainer width="100%" height={300}>
          {loadingUsersByMediumBarChartData ||
          usersByMediumBarChartData.length === 0
            ? showLoaderOrPlaceHolder(loadingUsersByMediumBarChartData)
            : showLineChart(
                usersByMediumBarChartData,
                'value',
                'fromTs',
                true,
                'użytkownicy',
                '#212121',
                formatDate,
                false,
                null,
              )}
        </ResponsiveContainer>
        <ResponsiveContainer width="100%" height={300}>
          {loadingUsersByMediumBarChartData ||
          usersByMediumBarChartData.length === 0
            ? showLoaderOrPlaceHolder(loadingUsersByMediumBarChartData)
            : showLineChart(
                usersByMediumBarChartData,
                'rsi',
                'fromTs',
                true,
                'RSI',
                '#820BBB',
                formatDate,
                true,
              )}
        </ResponsiveContainer>
        <ResponsiveContainer width="100%" height={300}>
          {loadingUsersByMediumBarChartData ||
          usersByMediumBarChartData.length === 0
            ? showLoaderOrPlaceHolder(loadingUsersByMediumBarChartData)
            : showLineChart(
                usersByMediumBarChartData,
                'macd',
                'fromTs',
                true,
                'MACD',
                '#FB9101',
                formatDate,
                true,
              )}
        </ResponsiveContainer>
      </>
    );
  }),
);

export default UsersByMediumCharts;

UsersByMediumCharts.propTypes = {
  from: PropTypes.any,
  to: PropTypes.any,
};
