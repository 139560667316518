import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import CustomLineChart from '../../../components/CustomLineChart/CustomLineChart';
import moment from 'moment';
import MaskSelectDateRange from '../../SalesPage/Components/MaskSelectDateRange';

const colors = ['#272727', '#868686'];
const keys = ['sum'];
const keysTranslation = ['Ilość użytkowników'];
const xAxis = 'fromTs';

const UsersWithPurchasesChart = ({ purchaseStore }) => {
  const {
    isLoadingUsersWithPurchases,
    usersWithPurchases,
    fetchUsersWithPurchasesPerMask,
    fetchMasksForUsersWithPurchases,
    isLoadingMasksForUsersWithPurchases,
    availableMasksForUsersWithPurchases,
  } = purchaseStore;
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [chosenMask, setChosenMask] = useState('Wszystkie');
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (startDate && endDate && isMounted) {
      fetchUsersWithPurchasesPerMask(startDate, endDate, chosenMask);
      fetchMasksForUsersWithPurchases(startDate, endDate);
    }
  }, [startDate, endDate, chosenMask, isMounted]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <CustomLineChart
        loadingData={isLoadingUsersWithPurchases}
        data={usersWithPurchases}
        xAxisKey={xAxis}
        dataKeys={keys}
        keysTranslation={keysTranslation}
        keysColors={colors}
      />
      <div style={{ width: '50%', margin: 'auto' }}>
        <MaskSelectDateRange
          chosenMask={chosenMask}
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          masks={availableMasksForUsersWithPurchases}
          loadingMasks={isLoadingMasksForUsersWithPurchases}
          setMask={setChosenMask}
        />
      </div>
    </>
  );
};
export default inject('purchaseStore')(observer(UsersWithPurchasesChart));

UsersWithPurchasesChart.propTypes = {
  purchaseStore: PropTypes.shape({
    isLoadingUsersWithPurchases: PropTypes.bool,
    usersWithPurchases: PropTypes.arrayOf(PropTypes.object),
    fetchUsersWithPurchasesPerMask: PropTypes.func,
    fetchMasksForUsersWithPurchases: PropTypes.func,
    isLoadingMasksForUsersWithPurchases: PropTypes.bool,
    availableMasksForUsersWithPurchases: PropTypes.arrayOf(PropTypes.object),
  }),
  payload: PropTypes.any,
};
