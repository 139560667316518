import React from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import BasicCard from 'components/BasicCard/BasicCard';

function PurchaseSumCard({ usersStore }) {
  const { purchaseTotalPlnSum, couponPoolUsage } = usersStore;

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {couponPoolUsage ? (
        <BasicCard
          value={couponPoolUsage}
          label="Ilość wykorzystanych kuponów"
          useFormatter={false}
        />
      ) : null}
      <BasicCard label="Suma wartości koszyków" value={purchaseTotalPlnSum} />
    </div>
  );
}

export default inject('usersStore')(observer(PurchaseSumCard));

PurchaseSumCard.propTypes = {
  usersStore: PropTypes.shape({
    purchaseTotalPlnSum: PropTypes.number,
    couponPoolUsage: PropTypes.number,
  }),
};
