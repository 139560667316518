import React, { Component } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { Title } from '../../components/helpers/chartHelpers';
import StoresTable from './Components/StoresTable';

class StoresPageContainer extends Component {
  render() {
    return (
      <div>
        <GridContainer spacing={3} style={{ padding: 20, paddingBottom: 100 }}>
          <GridItem xs={12}>
            <Title color="#212121">Sklepy stacjonarne</Title>
          </GridItem>
          <GridItem xs={12}>
            <StoresTable />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default StoresPageContainer;
