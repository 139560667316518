import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import CustomLineChart from '../../../components/CustomLineChart/CustomLineChart';
import moment from 'moment';
import MaskSelectDateRange from '../../SalesPage/Components/MaskSelectDateRange';

const colors = ['#272727'];
const keys = ['value'];
const keysTranslation = ['anulowane zamówienia'];
const xAxis = 'fromTs';

function CancelledOrdersLineChart({ purchaseStore }) {
  const {
    fetchCancelledOrders,
    cancelledOrders,
    isLoadingCancelledOrders,
    fetchMasksForCancelledOrders,
    availableMasksForCancelledOrders,
    isLoadingMasksForCancelledOrders,
  } = purchaseStore;
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [chosenMask, setChosenMask] = useState('Wszystkie');
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (startDate && endDate && isMounted) {
      fetchCancelledOrders(startDate, endDate, chosenMask);
      fetchMasksForCancelledOrders(startDate, endDate);
    }
  }, [startDate, endDate, chosenMask, isMounted]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <CustomLineChart
        loadingData={isLoadingCancelledOrders}
        data={cancelledOrders}
        xAxisKey={xAxis}
        dataKeys={keys}
        keysTranslation={keysTranslation}
        keysColors={colors}
      />
      <div style={{ width: '50%', margin: 'auto' }}>
        <MaskSelectDateRange
          chosenMask={chosenMask}
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          masks={availableMasksForCancelledOrders}
          loadingMasks={isLoadingMasksForCancelledOrders}
          setMask={setChosenMask}
        />
      </div>
    </>
  );
}

export default inject('purchaseStore')(observer(CancelledOrdersLineChart));

CancelledOrdersLineChart.propTypes = {
  purchaseStore: PropTypes.shape({
    fetchCancelledOrders: PropTypes.func,
    cancelledOrders: PropTypes.array,
    isLoadingCancelledOrders: PropTypes.bool,
    fetchMasksForCancelledOrders: PropTypes.func,
    availableMasksForCancelledOrders: PropTypes.array,
    isLoadingMasksForCancelledOrders: PropTypes.bool,
  }),
};
