import React from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import BasicCard from '../../../components/BasicCard/BasicCard';

function PurchaseSumCard({ usersStore }) {
  const { chosenAction, purchaseTotalPlnSum } = usersStore;

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {chosenAction === 'purchase' && (
        <BasicCard
          label={'Suma wartości koszyków'}
          value={purchaseTotalPlnSum}
        />
      )}
    </div>
  );
}

export default inject('usersStore')(observer(PurchaseSumCard));

PurchaseSumCard.propTypes = {
  usersStore: PropTypes.shape({
    chosenAction: PropTypes.string,
    purchaseTotalPlnSum: PropTypes.number,
  }),
};
